import React from "react";

export default function VimeoIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.1616 20.7513H10.1185C9.11639 20.4552 8.4384 19.7652 8.00272 18.8633C7.60957 18.0498 7.25332 17.2049 7.00138 16.3383C6.47343 14.5178 6.05862 12.6652 5.53307 10.8438C5.17843 9.61542 4.76041 8.39986 4.28381 7.21317C3.9741 6.44211 3.49349 6.29127 2.68472 6.49828C2.24583 6.61061 1.80935 6.73738 1.36485 6.82082C0.929972 6.90266 0.578541 6.74621 0.348266 6.35065C0.274449 6.22387 0.198226 6.0955 0.101141 5.98718C-0.0681555 5.79782 -0.010386 5.67907 0.166934 5.52823C0.879424 4.92165 1.56062 4.27817 2.28755 3.69084C3.27365 2.89411 4.26776 2.10459 5.30601 1.37846C5.74329 1.07276 6.28328 0.879396 6.80561 0.738182C7.93131 0.434892 8.68873 0.725344 9.32099 1.70662C9.62107 2.17199 9.9059 2.6887 10.0238 3.22066C10.3472 4.67854 10.5879 6.15487 10.8431 7.62799C11.0436 8.78418 11.1776 9.95321 11.4159 11.1014C11.5668 11.8267 11.9423 12.4726 12.5152 12.9741C13.1057 13.4916 13.6858 13.4964 14.2707 12.9693C14.497 12.7655 14.7297 12.5392 14.8709 12.276C15.4333 11.2322 16.0335 10.1987 16.4724 9.10192C16.7211 8.48089 16.762 7.74594 16.758 7.06313C16.7532 6.12679 16.2349 5.61408 15.3081 5.49935C14.8219 5.43917 14.3277 5.44719 13.7933 5.42232C13.8358 5.29876 13.8743 5.1768 13.9201 5.05725C14.3036 4.05832 14.8588 3.16289 15.6146 2.40788C17.1969 0.827243 18.9966 -0.230259 21.3587 0.0433433C22.6585 0.193383 23.5756 0.82644 23.8765 1.88314C23.9158 2.02195 23.9583 2.15915 23.9992 2.29796V3.42125C23.9062 3.80718 23.8372 4.20114 23.7152 4.57744C22.2934 8.98477 20.1969 13.0302 17.1455 16.5357C15.5047 18.4213 13.6962 20.1142 11.1608 20.7521L11.1616 20.7513Z" fill="#171717" fill-opacity="0.9" />
    </svg>

  );
}
