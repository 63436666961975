import { Avatar, Button, List } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import ListItemUser from "../../../../../components/ListItemUser/ListItemUser";

import styles from "./ConnectionsRequests.module.css";
import ListItemWrap from "../../../../../components/ListItemWrap/ListItemWrap";
import { useMediaQuery } from "react-responsive";
import { useCurrentUserContext } from "../../../../../store";
import AcceptRequestButton from "../../../../../components/buttons/AcceptRequestButton/AcceptRequestButton";
import DeclineConnectionButton from "../../../../../components/buttons/DeclineConnectionButton/DeclineConnectionButton";
import commonConnections from '../../../../../utils/commonConnections';

export default function ConnectionsRequests() {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { currentUser, loadUser } = useCurrentUserContext();

  return (
    <div>
      <div>
        <List>
          {currentUser.connectors.items &&
            currentUser.connectors.items.map((connection) => {
              if(connection.status !== "REQUESTED") return null;
              const mutualsConnections = commonConnections(
                connection.sendingUser.connectors?.items?.filter(connector => connector.status === "ACTIVE"),
                currentUser.connectors?.items?.filter(connector => connector.status === "ACTIVE"),
              )
              const mutualConnectionsCount = mutualsConnections.length || 0;
              return (
                <List.Item>
                  <ListItemWrap>
                    <div className={styles.userWrap}>
                      <ListItemUser user={connection.sendingUser} mutualConnections={mutualConnectionsCount} />
                    </div>
                    <div className={styles.buttonsColumn}>
                      {isDesktopOrLaptop && (
                        <DeclineConnectionButton
                          connection={connection}
                          onSuccess={() => loadUser()}
                        />
                      )}
                      <AcceptRequestButton
                        connection={connection}
                        onSuccess={() => loadUser()}
                      />
                    </div>
                  </ListItemWrap>
                </List.Item>
              );
            })}
        </List>
      </div>
    </div>
  );
}
