import React, { useEffect } from "react";
import { Header as AntHeader } from "antd/es/layout/layout";
import Link from "antd/es/typography/Link";
import { Avatar, Button, Dropdown, Image, Menu, MenuProps } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuthUserContext, useCurrentUserContext } from "../../store";

import styles from "./Header.module.css";
import {
  DownOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import MenuItem from "antd/es/menu/MenuItem";
import { useMediaQuery } from "react-responsive";
import { Storage } from "aws-amplify";
import HeaderAvatar from "./HeaderAvatar/HeaderAvatar";

interface HeaderProps {
  signIn?: boolean;
  signUp?: boolean;
  signOut?: boolean;
  avatar?: boolean;
}
const redirectMap = {
  "https://app-dev.timetoceli.com": "https://dev-onboarding.timetoceli.com",
  "https://app-test.timetoceli.com": "https://test-onboarding.timetoceli.com",
  "https://app.timetoceli.com": "https://onboarding.timetoceli.com",
  "http://localhost:3001": "http://localhost:3000",
}
export default function Header({
  signIn = false,
  signUp = false,
  signOut = false,
  avatar = false,
}: HeaderProps = {}) {
  const navigate = useNavigate();
  const userSignOut = useAuthUserContext().signOut;
  const location = useLocation();
  const { currentUser } = useCurrentUserContext();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Add dates",
      onClick: () => navigate("/@/" + currentUser?.username + "/events")
    },
    {
      key: "2",
      label: "Edit Profile",
      onClick: () => navigate("/@/" + currentUser?.username + "/edit"),
    },
    {
      key: "3",
      label: "View Profile",
      onClick: () => navigate("/@/" + currentUser?.username),
    },
    {
      key: "4",
      label: "How Celi Works",
      onClick: () => window.open("https://www.loom.com/share/folder/3339285c9cf1474a97e2fab8324bdc5b", "_blank"),
    },
    {
      key: "5",
      label: "Message Founder",
      onClick: () => window.open("mailto:ricki@timetoceli.com", "_blank"),
    },
    {
      key: "6",
      label: "Sign Out",
      onClick: () => userSignOut(),
    },
  ];

  return (
    <AntHeader className={styles.header}>
      <div
        onClick={() => navigate("/")}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          paddingBottom: "32px",
        }}
      >
        <Image preview={false} src="celi-logo.png" height={36} width={73} />
      </div>
      {signIn && (
        <div className={styles.actions}>
          <Link
            className={styles.link}
            onClick={() => {
              sessionStorage.setItem("intendedLocation", location.pathname);
              navigate("signin");
            }}
          >
            Sign In
          </Link>
        </div>
      )}
      {signUp && (
        <div className={styles.actions}>
          <Link
            className={styles.link}
            href={redirectMap[window.location.origin] || 'https://onboarding.timetoceli.com'}
          >
            Create Account
          </Link>
        </div>
      )}
      {signOut && (
        <div className={styles.actions}>
          <Link className={styles.link} onClick={() => userSignOut()}>
            {" "}
            Sign Out
          </Link>
        </div>
      )}
      {(avatar && signIn === false && signUp === false && signOut === false) && (
        <div className={styles.actions}>
          <Dropdown menu={{ items }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <HeaderAvatar />

              <Button
                icon={<DownOutlined />}
                style={{
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </div>
          </Dropdown>
        </div>
      )}
    </AntHeader>
  );
}
