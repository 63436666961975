import { Button } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { createConnection } from "../../../api/api";
import styles from "./AddConnectionButton.module.css";
import { useNavigate } from 'react-router-dom';

export default function AddConnectionButton({ user, onSuccess, mode }: any) {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const handleAddConnection = async () => {
    setSaving(true);
    try {
      await createConnection(user);
      onSuccess && onSuccess();
    } catch(e) {
    } finally {
      setSaving(false);
    }
  };

  return (
    <Button
      type="primary"
      className={styles.addButtom}
      onClick={() => mode ? navigate("/@/" + user?.username) : handleAddConnection()}
      loading={saving}
    >
      Add{isDesktopOrLaptop && " to Celi"}
    </Button>
  );
}
