import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { API, Storage } from "aws-amplify";
import React, { useState } from "react";
import { useCurrentUserContext } from "../../../../store";
import { v4 as uuidv4 } from "uuid";
import styles from "./Uploader.module.css"

export default function Uploader() {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState<RcFile>();
  const { currentUser, loadUser } = useCurrentUserContext();

  const save = async (filename) => {
    // check that all fields are filled out and valid
    setLoading(true);
    // send actual event here
    try {
      const apiName = "api";
      const path = "/api/users/currentUser";
      const init = {
        body: {
          avatarUrl: filename,
          _version: currentUser._version,
        },
      };

      const res = await API.put(apiName, path, init);
      const data = await res.data;
    } catch(error) {
    } finally {
      await loadUser();
      setLoading(false);
    }
  };

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if(!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if(!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    if(isJpgOrPng && isLt2M) {
      setFile(file);

      const filename = uuidv4() + ".png";

      let response;
      try {
        response = await Storage.put(filename, file, {
          contentType: "image/png",
        });
        save(filename);
      } catch(error) {}
    }

    return false;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if(info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if(info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleUpload = () => {
    const formData = new FormData();
    Storage.put("profilePicture.png", file, {
      contentType: "image/png",
    });
  };

  return (
    <div>
      <Upload
        name="avatar"
        listType="picture-circle"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl !== "" ? (
          <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
        ) : currentUser.avatarUrl ? null : (
          // uploadButton
          <div className={styles.avatar} style={{ backgroundColor: '#BCBAED' }}>
            {currentUser.firstName?.substr(0, 1)?.toUpperCase()}{currentUser.lastName?.substr(0, 1)?.toUpperCase()}
          </div>
        )
        }
        <div className={styles.edit}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <circle cx="12" cy="12" r="12" fill="#7C78FF" />
            <path
              d="M5.59961 15.7337V18.4H8.26591L16.1297 10.5362L13.4634 7.86988L5.59961 15.7337ZM18.1916 8.47424C18.4689 8.19695 18.4689 7.74901 18.1916 7.47172L16.5279 5.80795C16.2506 5.53065 15.8026 5.53065 15.5253 5.80795L14.2242 7.1091L16.8905 9.7754L18.1916 8.47424Z"
              fill="#FEFDFB"
            />
          </svg>
        </div>
      </Upload >
    </div >
  );
}
