import { Button, Input } from "antd";
import { API, Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthUserContext, useCurrentUserContext } from "../../../../store";
import { Typography } from "antd";

import styles from "./ForgotPasswordForm.module.css";
import { useMediaQuery } from 'react-responsive';

enum ForgotPasswordState {
  ForgotPassword,
  NewPassword,
}

export default function ForgotPasswordForm() {
  const [username, setUsername] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { signIn } = useAuthUserContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<ForgotPasswordState>(ForgotPasswordState.ForgotPassword);
  const [time, setTime] = useState(40);
  const [isFinished, setIsFinished] = useState(false);

  const validatePassword = (password: string) => {
    if(password === '') {
      return true;
    }

    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);

    if(
      password.length < 8 ||
      !hasNumber ||
      !hasSymbol ||
      !hasUppercase ||
      !hasLowercase
    ) {
      return false;
    }
    return true;
  };

  const confirmNewPassword = async () => {
    setLoading(true);
    setError("");

    if(!password) {
      setError("Password is required");
      setLoading(false);
      return;
    }

    try {
      const response = await Auth.forgotPasswordSubmit(
        username,
        code,
        password
      );
      navigate("/signin");
    } catch(error) {
      setError("Incorrect code");
    }

    setLoading(false);
  };

  const resetPassword = async () => {
    setLoading(true);
    setError("");

    if(!username) {
      setError("Username is required");
      setLoading(false);
      return;
    }

    try {
      const response = await Auth.forgotPassword(username);
      resetTimer()
      setState(ForgotPasswordState.NewPassword);
    } catch(error) {
      setError("Incorrect username");
    }

    setLoading(false);
  };

  const resetTimer = () => {
    setTime(40);
    setIsFinished(false);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => prevTime - 1);
    }, 1000);
    if(time === 0) {
      clearInterval(timer);
      setIsFinished(true);
    }
    return () => clearInterval(timer);
  }, [time]);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

  return (
    <div className={styles.shadowWrap}>
      {state === ForgotPasswordState.ForgotPassword && (
        <div className={styles.main}>
          <div className={styles.header}>Reset my password</div>
          <div className={styles.field}>
            <div className={styles.labelActionWrap}>
              <div className={styles.label}>Username</div>
              <div
                className={styles.action}
                onClick={() => navigate("/signin")}
              >
                I remember my password
              </div>
            </div>
            <Input
              placeholder="Username"
              value={username}
              allowClear
              size="large"
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              autoFocus={true}
            />
            <div className={styles.description}>
              <div>
                Enter your Celi username and we’ll send you a one-time code
              </div>
            </div>
          </div>

          <div className={styles.error}>{error}</div>
          <div className={styles.buttonWrapper}>
            <Button
              size="large"
              type="primary"
              onClick={async () => {
                resetPassword();
              }}
              disabled={!username}
              loading={loading}
            >
              Send Code
            </Button>
          </div>
        </div>
      )}
      {state === ForgotPasswordState.NewPassword && (
        <div className={styles.main}>
          <div className={styles.header}>Create {isDesktopOrLaptop && "new"} password</div>
          <div className={styles.field}>
            <div className={styles.labelActionWrap}>
              <div className={styles.label}>Code</div>
              <div className={styles.resendLabel}>
                <Button
                  onClick={() => {
                    resetTimer()
                    resetPassword()
                  }}
                  className={styles.resendButton}
                  disabled={!isFinished}>
                  {isDesktopOrLaptop ? "Resend Code" : "Resend"}
                </Button>
                {!isFinished && <>in {formatTime(time)}</>}
              </div>
            </div>
            <Input
              placeholder="Enter Code"
              value={code}
              allowClear
              size="large"
              onChange={(event) => {
                setCode(event.target.value);
              }}
              autoFocus={true}
            />
            <div className={styles.description}>
              <div>
                We emailed you a one-time code from no-reply@verificationemail.com
              </div>
            </div>
          </div>
          <div className={styles.field}>
            <div className={styles.labelActionWrap}>
              <div className={styles.label}>Password</div>
            </div>
            <Input.Password
              placeholder="Enter Password"
              value={password}
              size="large"
              maxLength={24}
              disabled={code.length < 6}
              status={validatePassword(password) ? '' : 'error'}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <div className={styles.description}>
              <div>
                Must include a number, symbol, uppercase, and lowercase letter
              </div>
              <div>
                <span>{password.length}</span> / 24
              </div>
            </div>
          </div>

          <div className={styles.error}>{error}</div>
          <div className={styles.buttonWrapper}>
            <Button
              size="large"
              type="primary"
              onClick={async () => {
                confirmNewPassword();
              }}
              disabled={!password || !validatePassword(password)}
              loading={loading}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
