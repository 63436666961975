import React, { useEffect, useState } from "react";

import styles from "./ProfileInformation.module.css";
import { Button, Input, Select, Space, Switch, Tooltip } from "antd";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { Autocomplete } from '@react-google-maps/api';
import "react-phone-input-2/lib/bootstrap.css";
import "./phoneInput.css";
import { API } from "aws-amplify";
import { useCurrentUserContext } from "../../../../store";
import EditIcon from '../../../../components/icons/EditIcon/Editicon';
import PlusIcon from '../../../../components/icons/PlusIcon/PlusIcon';
import { MinusCircleOutlined } from '@ant-design/icons';
import SnapchatIcon from '../../../../components/icons/SnapchatIcon/SnapchatIcon';
import ActsOfServiceIcon from '../../../../components/icons/ActsOfServiceIcon/ActsOfServiceIcon';
import PhysicalTouchIcon from '../../../../components/icons/PhysicalTouchIcon/PhysicalTouchIcon';
import QualityTimeIcon from '../../../../components/icons/QualityTimeIcon/QualityTimeIcon';
import ReceivingGiftsIcon from '../../../../components/icons/ReceivingGiftsIcon/ReceivingGiftsIcon';
import WordsOfAffirmationIcon from '../../../../components/icons/WordsOfAffirmationIcon/WordsOfAffirmationIcon';
import EmailIcon from '../../../../components/icons/EmailICon/EmailIcon';
import PhoneCallIcon from '../../../../components/icons/PhoneCallIcon/PhoneCallIcon';
import SignalIcon from '../../../../components/icons/SignalIcon/SignalIcon';
import CellphoneIcon from '../../../../components/icons/CellphoneIcon/CellphoneIcon';
import TelegramIcon from '../../../../components/icons/TelegramIcon/TelegramIcon';
import TextIcon from '../../../../components/icons/TextIcon/TextIcon';
import VideoCallIcon from '../../../../components/icons/VideoCallIcon/VideoCallIcon';
import WeChatIcon from '../../../../components/icons/WeChatIcon/WeChatIcon';
import WhatsAppIcon from '../../../../components/icons/WhatsAppIcon/WhatsAppIcon';
import AppleMusicIcon from '../../../../components/icons/creator/AppleMusicIcon/AppleMusicIcon';
import BehanceIcon from '../../../../components/icons/creator/BehanceIcon/BehanceIcon';
import BlogIcon from '../../../../components/icons/creator/BlogIcon/BlogIcon';
import DiscordIcon from '../../../../components/icons/creator/DiscordIcon/DiscordIcon';
import EtsyIcon from '../../../../components/icons/creator/EtsyIcon/EtsyIcon';
import FlickrIcon from '../../../../components/icons/creator/FlickrIcon/FlickrIcon';
import GitHubIcon from '../../../../components/icons/creator/GitHubIcon/GitHubIcon';
import GoodreadsIcon from '../../../../components/icons/creator/GoodreadsIcon/GoodreadsIcon';
import NewsletterIcon from '../../../../components/icons/creator/NewsletterIcon/NewsletterIcon';
import PinterestIcon from '../../../../components/icons/creator/PinterestIcon/PinterestIcon';
import SoundcloudIcon from '../../../../components/icons/creator/SoundcloudIcon/SoundcloudIcon';
import SpotifyIcon from '../../../../components/icons/creator/SpotifyIcon/SpotifyIcon';
import TwitchIcon from '../../../../components/icons/creator/TwitchIcon/TwitchIcon';
import VimeoIcon from '../../../../components/icons/creator/VimeoIcon/VimeoIcon';
import VSCOIcon from '../../../../components/icons/creator/VSCOIcon/VSCOIcon';
import WebsiteIcon from '../../../../components/icons/creator/WebsiteIcon/WebsiteIcon';
import CashAppIcon from '../../../../components/icons/Bills/CashAppIcon/CashAppIcon';
import PayPalIcon from '../../../../components/icons/Bills/PayPalIcon/PayPalIcon';
import VenmoIcon from '../../../../components/icons/Bills/VenmoIcon/VenmoIcon';
import WeChatPayIcon from '../../../../components/icons/Bills/WeChatPayIcon/WeChatPayIcon';
import FacebookIcon from '../../../../components/icons/Socials/FacebookIcon/FacebookIcon';
import InstagramIcon from '../../../../components/icons/Socials/InstagramIcon/InstagramIcon';
import LinkedinIcon from '../../../../components/icons/Socials/LinkedinIcon/LinkedinIcon';
import TikTokIcon from '../../../../components/icons/Socials/TikTokIcon/TikTokIcon';
import TwitterIcon from '../../../../components/icons/Socials/TwitterIcon/TwitterIcon';
import YoutubeIcon from '../../../../components/icons/Socials/YoutubeIcon/YoutubeIcon';
import { useMediaQuery } from 'react-responsive';


const validarURL = (url) => {
  var patron = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/[\w-./?%&=]*)?$/;
  return patron.test(url);
}

export default function ProfileInformation() {
  const { currentUser, loadUser, setCurrentUser } = useCurrentUserContext();
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const [countryCode, setCountryCode] = useState(currentUser.countryCode);
  const [phone, setPhone] = useState(currentUser.phone);

  const [hideInfo, setHideInfo] = useState(currentUser.hideContactInfo || {
    email: true,
    phone: true,
    address: true,
  });
  const [firstName, setFirstName] = useState(currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser.lastName);
  const [pronoun, setPronoun] = useState(currentUser.pronoun);
  const [friendshipLanguage, setFriendshipLanguage] = useState(currentUser.friendshipLanguage);
  const [communicationPreference, setCommunicationPreference] = useState(currentUser.communicationPreference);


  const [addressValid, setAddressValid] = useState(!!currentUser.address)
  const [formattedAddress, setFormattedAddress] = useState(currentUser.formattedAddress)
  const [address, setAddress] = useState(currentUser.address)
  const [locality, setLocality] = useState(currentUser.locality)
  const [city, setCity] = useState(currentUser.city)
  const [state, setState] = useState(currentUser.state)
  const [zip, setZip] = useState(currentUser.zip)
  const [country, setCountry] = useState(currentUser.country)

  const [linkSocials, setLinkSocials] = useState(currentUser.links?.socials?.length ? currentUser.links?.socials : [{ type: null, value: null }])
  const [linkCreators, setLinkCreators] = useState(currentUser.links?.creators?.length ? currentUser.links?.creators : [{ type: null, value: null }])
  const [linkBills, setLinkBills] = useState(currentUser.links?.bills?.length > 0 ? currentUser.links?.bills : [{ type: null, value: null }])

  const [loading, setLoading] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false)
  const [isEditingAddress, setIsEditingAddress] = useState(false)
  const [isEditingBill, setIsEditingBill] = useState(false)
  const [isEditingCreator, setIsEditingCreator] = useState(false)
  const [isEditingSocial, setIsEditingSocial] = useState(false)

  const [handleDisableBill, setHandleDisableBill] = useState<boolean>()
  const [handleDisableCreator, setHandleDisableCreator] = useState<boolean>()
  const [handleDisableSocial, setHandleDisableSocial] = useState<boolean>()

  const pronouns = [
    { value: "her", label: "her" },
    { value: "his", label: "his" },
    { value: "their", label: "their" },
  ];

  const save = async () => {
    // check that all fields are filled out and valid
    setLoading(true);

    // send actual event here
    try {
      const apiName = "api";
      const path = "/api/users/currentUser";
      let init = {};

      if(isEditingProfile) {
        init = {
          body: {
            firstName,
            lastName,
            pronoun,
            communicationPreference,
            friendshipLanguage,
            _version: currentUser._version,
          },
        };
      }
      if(isEditingAddress) {
        init = {
          body: {
            address,
            locality,
            city,
            state,
            zip,
            country,
            formattedAddress,
            _version: currentUser._version,
          }
        }
      }

      if(isEditingBill || isEditingCreator || isEditingSocial) {
        init = {
          body: {
            links: {
              socials: linkSocials.filter(link => link.value),
              creators: linkCreators.filter(link => link.value),
              bills: linkBills.filter(link => link.value),
            },
            _version: currentUser._version,
          }
        }
      }
      const res = await API.put(apiName, path, init);
      await res.data;

      if(isEditingProfile) setIsEditingProfile(false)
      if(isEditingAddress) setIsEditingAddress(false)
      if(isEditingBill) setIsEditingBill(false)
      if(isEditingCreator) setIsEditingCreator(false)
      if(isEditingSocial) setIsEditingSocial(false)
      // onEventSubmit && onEventSubmit();
    } catch(error) {
    } finally {
      await loadUser();
      setLoading(false);
    }
  };

  const saveHideInformation = async (type, value) => {
    setLoading(true)
    let hide = {
      email: true,
      phone: true,
      address: true,
    };

    if(type === "email") {
      hide = {
        ...hideInfo,
        email: value,
      }
    }
    if(type === "phone") {
      hide = {
        ...hideInfo,
        phone: value,
      }
    }
    if(type === "address") {
      hide = {
        ...hideInfo,
        address: value,
      }
    }
    try {
      const apiName = "api";
      const path = "/api/users/currentUser/hideInfo";
      const init = {
        body: {
          hideContactInfo: JSON.stringify(hide),
          _version: currentUser._version,
        },
      };

      const res = await API.put(apiName, path, init);
      setCurrentUser({ ...currentUser, hideContactInfo: hide })
    } catch(error) {
    } finally {
      await loadUser();
      setLoading(false)
    }
  }

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    console.log("clear address")
    setAddress("")
    setLocality("")
    setZip("")
    setCity("")
    setState("")
    setCountry("")
    setFormattedAddress("")

    if(autocomplete !== null) {
      const place = autocomplete.getPlace();
      setAddressValid(place.address_components ? false : true)
      setAddress(place.name)
      setLocality(place.address_components?.find((ac) => ac.types[0] === 'locality')?.long_name)
      setZip(place.address_components?.find((ac) => ac.types[0] === 'postal_code')?.long_name || "")
      setCity(place.address_components?.find((ac) => ac.types[0] === 'administrative_area_level_2')?.long_name || "")
      setState(place.address_components?.find((ac) => ac.types[0] === 'administrative_area_level_1')?.long_name)
      setCountry(place.address_components?.find((ac) => ac.types[0] === 'country')?.long_name)
      setFormattedAddress(place.formatted_address)

    } else {
      console.log('Autocomplete is not loaded yet!');
      setAddress("")
      setLocality("")
      setZip("")
      setCity("")
      setState("")
      setCountry("")
      setFormattedAddress("")
    }
  };



  useEffect(() => {
    setHandleDisableCreator(!!linkCreators.find(link => {
      if(link.type === "spotify" || link.type === "goodreads") {
        if(validarURL(link.value)) {
          return !link.value.includes(link.type)
        }
        return true;
      } else if(link.type === "blog" || link.type === "newsletter" || link.type === "website") {
        return !validarURL(link.value)
      } else {
        return validarURL(link.value)
      }
    }))
  }, [linkCreators])

  useEffect(() => {
    setHandleDisableBill(!!linkBills.find(link => validarURL(link.value)))
  }, [linkBills])

  useEffect(() => {
    setHandleDisableSocial(!!linkSocials.find(link => validarURL(link.value)))
  }, [linkSocials])


  return (
    <div className={styles.profileInformation}>
      <div className={styles.content}>
        {/* username */}
        <div className={styles.row}>
          <div className={styles.inputWrap}>
            <div className={styles.label}>Username</div>
            <Tooltip
              title={<>To update this information, please reach out to us at <a style={{ color: "#bcbaed" }} href="mailto:celebrations@timetoceli.com">celebrations@timetoceli.com</a></>}
            >
              <div style={{
                "height": "48px",
                "position": "relative",
                "width": "100%",
                "top": "48px",
                "zIndex": "1",
                "margin": "-48px 0 0 0",
              }}></div>
            </Tooltip>
            <Input
              value={currentUser.username}
              className={styles.input}
              disabled
              size="large"
              placeholder="Enter your username"
            />
          </div>
        </div>

        {/* email */}
        <div className={styles.row}>
          <div className={styles.inputWrap}>
            <div className={styles.label}>Email Address</div>
            <Tooltip
              title={<>To update this information, please reach out to us at <a style={{ color: "#bcbaed" }} href="mailto:celebrations@timetoceli.com">celebrations@timetoceli.com</a></>}
            >
              <div style={{
                "height": "48px",
                "position": "relative",
                "width": "90%",
                "top": "48px",
                "zIndex": "1",
                "margin": "-48px 0 0 0",
              }}></div>
            </Tooltip>
            <div className={styles.inputCheck}>
              <Input
                value={currentUser.email}
                className={styles.input}
                disabled
                size="large"
                placeholder="Enter your email address"
              />
              <Switch
                loading={loading}
                checked={hideInfo.email}
                onChange={(v) => {
                  setHideInfo({
                    ...hideInfo,
                    email: v
                  })
                  saveHideInformation("email", v)
                }} />
            </div>
          </div>
        </div>

        {/* phone */}
        <div className={styles.row}>
          <div className={styles.inputWrap}>
            <div className={styles.label}>Phone Number</div>
            <Tooltip
              title={<>To update this information, please reach out to us at <a style={{ color: "#bcbaed" }} href="mailto:celebrations@timetoceli.com">celebrations@timetoceli.com</a></>}
            >
              <div style={{
                "height": "48px",
                "position": "relative",
                "width": "90%",
                "top": "48px",
                "zIndex": "1",
                "margin": "-48px 0 0 0",
              }}></div>
            </Tooltip>
            <div className={styles.inputCheck}>
              <PhoneInput
                disabled
                country={parseInt(currentUser.countryCode)}
                enableSearch={true}
                value={phone}
                specialLabel={""}
                placeholder="Phone Number"
                onChange={(phone, country: CountryData) => {
                  setPhone(phone);
                  setCountryCode(country.dialCode);
                }}
              />
              <Switch
                loading={loading}
                checked={hideInfo.phone}
                onChange={(v) => {
                  setHideInfo({
                    ...hideInfo,
                    phone: v
                  })
                  saveHideInformation("phone", v)
                }} />
            </div>
          </div>
        </div>

        {/* divider profile */}
        <div className={styles.rowText}>
          <h1 className={styles.dividerText}>Profile</h1>
          {!isEditingProfile && <Button className={styles.iconButton} onClick={() => {
            setIsEditingProfile(true)
            setIsEditingAddress(false)
            setIsEditingBill(false)
            setIsEditingCreator(false)
            setIsEditingSocial(false)
          }}> <EditIcon /> </Button>}
          {isEditingProfile && <Button type="primary" loading={loading} className={styles.saveButton} onClick={() => save()}> Save </Button>}
        </div>

        {/* first name - last name */}
        {isDesktopOrLaptop ?
          <div className={styles.row}>
            <div className={styles.inputWrap}>
              <div className={styles.label}>First Name</div>

              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={styles.input}
                size="large"
                status={firstName.length === 0 && "error"}
                placeholder="Enter your first name"
                disabled={!isEditingProfile}
              />
            </div>
            <div className={styles.inputWrap}>
              <div className={styles.label}>Last Name</div>

              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={styles.input}
                status={lastName.length === 0 && "error"}
                size="large"
                placeholder="Enter your last name"
                disabled={!isEditingProfile}
              />
            </div>
          </div> :
          <>
            <div className={styles.row}>
              <div className={styles.inputWrap}>
                <div className={styles.label}>First Name</div>

                <Input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={styles.input}
                  size="large"
                  status={firstName.length === 0 && "error"}
                  placeholder="Enter your first name"
                  disabled={!isEditingProfile}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputWrap}>
                <div className={styles.label}>Last Name</div>

                <Input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={styles.input}
                  status={lastName.length === 0 && "error"}
                  size="large"
                  placeholder="Enter your last name"
                  disabled={!isEditingProfile}
                />
              </div>
            </div>
          </>
        }

        {/* proni */}
        <div className={styles.row}>
          <div className={styles.inputWrap}>
            <div className={styles.label}>Pick your proni</div>

            <Select
              style={{ width: "100%" }}
              size="large"
              value={pronoun}
              options={pronouns}
              onChange={(value) => setPronoun(value)}
              placeholder="Pronoun"
              disabled={!isEditingProfile}
            />
          </div>
        </div>

        {/* communication preference - friendship language */}
        {isDesktopOrLaptop ?
          <div className={styles.row}>
            <div className={styles.inputWrap}>
              <div className={styles.label}>Communication Preference</div>
              <Select
                style={{ width: "100%" }}
                size="large"
                value={communicationPreference}
                options={communicationPreferences}
                onChange={(value) => setCommunicationPreference(value)}
                placeholder="Select preference"
                disabled={!isEditingProfile}
              />
            </div>
            <div className={styles.inputWrap}>
              <div className={styles.label}>Friendship Language</div>
              <Select
                style={{ width: "100%" }}
                size="large"
                value={friendshipLanguage}
                options={friendshipLanguages}
                onChange={(value) => setFriendshipLanguage(value)}
                placeholder="Select Language"
                disabled={!isEditingProfile}
              />
            </div>
          </div> :
          <>
            <div className={styles.row}>
              <div className={styles.inputWrap}>
                <div className={styles.label}>Communication Preference</div>
                <Select
                  style={{ width: "100%" }}
                  size="large"
                  value={communicationPreference}
                  options={communicationPreferences}
                  onChange={(value) => setCommunicationPreference(value)}
                  placeholder="Select preference"
                  disabled={!isEditingProfile}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputWrap}>
                <div className={styles.label}>Friendship Language</div>
                <Select
                  style={{ width: "100%" }}
                  size="large"
                  value={friendshipLanguage}
                  options={friendshipLanguages}
                  onChange={(value) => setFriendshipLanguage(value)}
                  placeholder="Select Language"
                  disabled={!isEditingProfile}
                />
              </div>
            </div>
          </>
        }


        {/* divider Address */}
        <div className={styles.rowText}>
          <h1 className={styles.dividerText}>Address</h1>
          {!isEditingAddress && <Button className={styles.iconButton} onClick={() => {
            setIsEditingProfile(false)
            setIsEditingAddress(true)
            setIsEditingBill(false)
            setIsEditingCreator(false)
            setIsEditingSocial(false)
          }}> <EditIcon /> </Button>}
          {isEditingAddress && <Button type="primary" loading={loading} className={styles.saveButton} onClick={() => save()} disabled={addressValid}> Save </Button>}
        </div>


        {/* Address */}
        <div className={styles.row}>
          <div className={styles.inputWrap}>
            <div className={styles.inputCheck}>
              <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} >
                <input
                  type="text"
                  value={formattedAddress}
                  className={styles.inputAddress}
                  disabled={!isEditingAddress}
                  onChange={(e) => setFormattedAddress(e.target.value)}
                />
              </Autocomplete>
              <Switch
                loading={loading}
                checked={hideInfo.address}
                onChange={(v) => {
                  setHideInfo({
                    ...hideInfo,
                    address: v
                  })
                  saveHideInformation("address", v)
                }} />
            </div>
          </div>
        </div>

        {/* divider Bill */}
        <div className={styles.rowText}>
          <h1 className={styles.dividerText}>Bill Splitting</h1>
          {!isEditingBill && <Button className={styles.iconButton} onClick={() => {
            setIsEditingProfile(false)
            setIsEditingAddress(false)
            setIsEditingBill(true)
            setIsEditingCreator(false)
            setIsEditingSocial(false)
          }}> <EditIcon /> </Button>}
          {isEditingBill && <Button type="primary" disabled={handleDisableBill} loading={loading} className={styles.saveButton} onClick={() => save()}> Save </Button>}
        </div>
        <>
          {linkBills.map((link, index) => {
            return (
              <div className={styles.row} key={index}>
                <div className={styles.inputWrap}>
                  <div className={styles.inputLink}>
                    <Space.Compact style={{ width: "100%" }}>
                      <Select
                        popupMatchSelectWidth={false}
                        size='large'
                        placeholder={<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" />}
                        value={linkBills[index].type}
                        options={bills.filter(option => !linkBills.find(linkCreator => linkCreator.type === option.value))}
                        labelRender={({ value }) => {
                          return billsIcon[value]
                        }}
                        disabled={!isEditingBill}
                        onChange={(option) => {
                          const copyLinkBills = [...linkBills]
                          copyLinkBills[index] = { type: option, value: copyLinkBills[index].value }
                          setLinkBills([...copyLinkBills])
                        }}
                      />
                      <Input
                        size="large"
                        addonBefore={isDesktopOrLaptop && (isEditingBill && billsLinks[linkBills[index].type])}
                        disabled={!isEditingBill}
                        value={linkBills[index].value}
                        styles={{
                          input: {
                            paddingLeft: isDesktopOrLaptop ? (isEditingBill ? 0 : "11px") : "11px"
                          }
                        }}
                        onChange={(option) => {
                          const copyLinkBills = [...linkBills]
                          copyLinkBills[index] = { type: copyLinkBills[index].type, value: option.target.value }
                          setLinkBills([...copyLinkBills])
                        }}
                        status={validarURL(linkBills[index].value) && 'error'}
                      />
                    </Space.Compact>
                    {isEditingBill && <Button type="text" icon={<MinusCircleOutlined />} onClick={() => {
                      const copyLinkBills = [...linkBills]
                      copyLinkBills.splice(index, 1)
                      setLinkBills([...copyLinkBills])
                    }} />}
                  </div>
                </div>
              </div>
            )
          })}
        </>

        {(isEditingBill && bills.length !== linkBills.length) && <div className={styles.rowIcon}>
          <Button className={styles.iconButton} onClick={() => setLinkBills([...linkBills, { type: null, value: null }])}> <PlusIcon size="1.3em" /> </Button>
        </div>}

        {/* divider Creator */}
        <div className={styles.rowText}>
          <h1 className={styles.dividerText}>Creator</h1>
          {!isEditingCreator && <Button className={styles.iconButton} onClick={() => {
            setIsEditingProfile(false)
            setIsEditingAddress(false)
            setIsEditingBill(false)
            setIsEditingCreator(true)
            setIsEditingSocial(false)
          }}> <EditIcon /> </Button>}
          {isEditingCreator && <Button type="primary" disabled={handleDisableCreator} loading={loading} className={styles.saveButton} onClick={() => save()}> Save </Button>}
        </div>
        <>
          {linkCreators.map((link, index) => {
            return (
              <div className={styles.row} key={index}>
                <div className={styles.inputWrap}>
                  <div className={styles.inputLink}>
                    <Space.Compact style={{ width: "100%" }}>
                      <Select
                        popupMatchSelectWidth={false}
                        size='large'
                        placeholder={<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" />}
                        value={linkCreators[index].type}
                        options={creators.filter(option => !linkCreators.find(linkCreator => linkCreator.type === option.value))}
                        labelRender={({ value }) => {
                          return creatorsIcon[value]
                        }}
                        disabled={!isEditingCreator}
                        onChange={(option) => {
                          const copyLinkCreators = [...linkCreators]
                          copyLinkCreators[index] = { type: option, value: copyLinkCreators[index].value }
                          setLinkCreators([...copyLinkCreators])
                        }}
                      />
                      <Input
                        size="large"
                        styles={{
                          input: {
                            paddingLeft: isDesktopOrLaptop ? (isEditingCreator ? creatorsLinks[linkCreators[index].type] === null ? "11px" : 0 : "11px") : "11px"
                          }
                        }}
                        addonBefore={isDesktopOrLaptop && (isEditingCreator && creatorsLinks[linkCreators[index].type])}
                        disabled={!isEditingCreator}
                        value={linkCreators[index].value}
                        onChange={(option) => {
                          const copyLinkCreators = [...linkCreators]
                          copyLinkCreators[index] = { type: copyLinkCreators[index].type, value: option.target.value }
                          setLinkCreators([...copyLinkCreators])
                        }}
                        status={
                          (
                            linkCreators[index].type === "spotify" ||
                            linkCreators[index].type === "goodreads"
                          ) ?
                            (validarURL(linkCreators[index].value) ?
                              (linkCreators[index].value.includes(linkCreators[index].type) ? null : "error")
                              : "error") :
                            ((linkCreators[index].type === "blog" ||
                              linkCreators[index].type === "newsletter" ||
                              linkCreators[index].type === "website") ?
                              (validarURL(linkCreators[index].value) ? null : "error") :
                              (!validarURL(linkCreators[index].value) ? null : "error"))
                        }
                      />
                    </Space.Compact>
                    {isEditingCreator && <Button type="text" icon={<MinusCircleOutlined />} onClick={() => {
                      const copyLinkCreators = [...linkCreators]
                      copyLinkCreators.splice(index, 1)
                      setLinkCreators([...copyLinkCreators])
                    }} />}
                  </div>
                </div>
              </div>
            )
          })}
        </>

        {(isEditingCreator && linkCreators.length < 6) && <div className={styles.rowIcon}>
          <Button className={styles.iconButton} onClick={() => setLinkCreators([...linkCreators, { type: null, value: null }])}> <PlusIcon size="1.3em" /> </Button>
        </div>}

        {/* divider Social */}
        <div className={styles.rowText}>
          <h1 className={styles.dividerText}>Social</h1>
          {!isEditingSocial && <Button className={styles.iconButton} onClick={() => {
            setIsEditingProfile(false)
            setIsEditingAddress(false)
            setIsEditingBill(false)
            setIsEditingCreator(false)
            setIsEditingSocial(true)
          }}> <EditIcon /> </Button>}
          {isEditingSocial && <Button type="primary" disabled={handleDisableSocial} loading={loading} className={styles.saveButton} onClick={() => save()}> Save </Button>}
        </div>
        <>
          {linkSocials.map((link, index) => {
            return (
              <div className={styles.row} key={index}>
                <div className={styles.inputWrap}>
                  <div className={styles.inputLink}>
                    <Space.Compact style={{ width: "100%" }}>
                      <Select
                        popupMatchSelectWidth={false}
                        size='large'
                        placeholder={<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" />}
                        value={linkSocials[index].type}
                        options={socials.filter(option => !linkSocials.find(linkSocial => linkSocial.type === option.value))}
                        labelRender={({ value }) => {
                          return socialsIcon[value]
                        }}
                        disabled={!isEditingSocial}
                        onChange={(option) => {
                          const copyLinkSocials = [...linkSocials]
                          copyLinkSocials[index] = { type: option, value: copyLinkSocials[index].value }
                          setLinkSocials([...copyLinkSocials])
                        }}
                      />
                      <Input
                        size="large"
                        styles={{
                          input: {
                            paddingLeft: isDesktopOrLaptop ? (isEditingSocial ? 0 : "11px") : "11px"
                          }
                        }}
                        addonBefore={isDesktopOrLaptop && (isEditingSocial && socialsLinks[linkSocials[index].type])}
                        disabled={!isEditingSocial}
                        value={linkSocials[index].value}
                        onChange={(option) => {
                          const copyLinkSocials = [...linkSocials]
                          copyLinkSocials[index] = { type: copyLinkSocials[index].type, value: option.target.value }
                          setLinkSocials([...copyLinkSocials])
                        }}
                        status={validarURL(linkSocials[index].value) && 'error'}
                      />
                    </Space.Compact>
                    {isEditingSocial && <Button type="text" icon={<MinusCircleOutlined />} onClick={() => {
                      const copyLinkSocials = [...linkSocials]
                      copyLinkSocials.splice(index, 1)
                      setLinkSocials([...copyLinkSocials])
                    }} />}
                  </div>
                </div>
              </div>
            )
          })}
        </>

        {(isEditingSocial && linkSocials.length < 6) && <div className={styles.rowIcon}>
          <Button className={styles.iconButton} onClick={() => setLinkSocials([...linkSocials, { type: null, value: null }])}> <PlusIcon size="1.3em" /> </Button>
        </div>}

      </div>
    </div>
  );
}


export const communicationPreferences = [
  { value: "email", label: <><EmailIcon /> Email</>, icon: <EmailIcon />, title: "Email", },
  { value: "phoneCall", label: <><PhoneCallIcon />Phone Call</>, icon: <PhoneCallIcon />, title: "Phone Call", },
  { value: "signal", label: <><SignalIcon /> Signal</>, icon: <SignalIcon />, title: "Signal", },
  { value: "socialMedia", label: <><CellphoneIcon /> Social Media</>, icon: <CellphoneIcon />, title: "Social Media", },
  { value: "telegram", label: <><TelegramIcon /> Telegram</>, icon: <TelegramIcon />, title: "Telegram", },
  { value: "text", label: <><TextIcon /> Text</>, icon: <TextIcon />, title: "Text", },
  { value: "videoCall", label: <><VideoCallIcon /> Video Call</>, icon: <VideoCallIcon />, title: "Video Call", },
  { value: "weChat", label: <><WeChatIcon /> WeChat</>, icon: <WeChatIcon />, title: "WeChat", },
  { value: "whatsApp", label: <><WhatsAppIcon /> WhatsApp</>, icon: <WhatsAppIcon />, title: "WhatsApp", },
];

export const communicationPreferencesIcons = {
  "email": <EmailIcon />,
  "phoneCall": <PhoneCallIcon />,
  "signal": <SignalIcon />,
  "socialMedia": <CellphoneIcon />,
  "telegram": <TelegramIcon />,
  "text": <TextIcon />,
  "videoCall": <VideoCallIcon />,
  "weChat": <WeChatIcon />,
  "whatsApp": <WhatsAppIcon />,
};

export const friendshipLanguages = [
  { value: "acts_of_service", label: <><ActsOfServiceIcon /> Acts of Service</> },
  { value: "physical_touch", label: <><PhysicalTouchIcon /> Physical Touch</> },
  { value: "quality_time", label: <><QualityTimeIcon /> Quality Time</> },
  { value: "receiving_gifts", label: <><ReceivingGiftsIcon /> Receiving Gifts</> },
  { value: "words_of_affirmation", label: <><WordsOfAffirmationIcon /> Words of Affirmation</> },
];

export const friendshipLanguagesIcons = {
  "acts_of_service": <ActsOfServiceIcon />,
  "physical_touch": <PhysicalTouchIcon />,
  "quality_time": <QualityTimeIcon />,
  "receiving_gifts": <ReceivingGiftsIcon />,
  "words_of_affirmation": <WordsOfAffirmationIcon />,
};

export const socials = [
  { value: "facebook", label: <><FacebookIcon /> Facebook</> },
  { value: "instagram", label: <><InstagramIcon /> Instagram</> },
  { value: "linkedin", label: <><LinkedinIcon /> LinkedIn</> },
  { value: "snapchat", label: <><SnapchatIcon /> Snapchat</> },
  { value: "tiktok", label: <><TikTokIcon /> TikTok</> },
  { value: "twitter", label: <><TwitterIcon /> Twitter</> },
  { value: "wechat", label: <><WeChatIcon /> WeChat</> },
  { value: "youtube", label: <><YoutubeIcon /> YouTube</> },
];

export const socialsIcon = {
  "facebook": <FacebookIcon />,
  "instagram": <InstagramIcon />,
  "linkedin": <LinkedinIcon />,
  "snapchat": <SnapchatIcon />,
  "tiktok": <TikTokIcon />,
  "twitter": <TwitterIcon />,
  "wechat": <WeChatIcon />,
  "youtube": <YoutubeIcon />,
};

export const socialsLinks = {
  "facebook": "https://www.facebook.com/",
  "instagram": "https://www.instagram.com/",
  "linkedin": "https://www.linkedin.com/in/",
  "snapchat": "https://www.snapchat.com/add/",
  "tiktok": "https://www.tiktok.com/@",
  "twitter": "https://twitter.com/",
  "wechat": "https://www.wechat.com/u/",
  "youtube": "https://www.youtube.com/@",
};


export const creators = [
  { value: "apple_music", label: <><AppleMusicIcon /> Apple Music</> },
  { value: "behance", label: <><BehanceIcon /> Behance</> },
  { value: "blog", label: <><BlogIcon /> Blog</> },
  { value: "discord", label: <><DiscordIcon /> Discord</> },
  { value: "etsy", label: <><EtsyIcon /> Etsy</> },
  { value: "flickr", label: <><FlickrIcon /> Flickr</> },
  { value: "github", label: <><GitHubIcon /> GitHub</> },
  { value: "goodreads", label: <><GoodreadsIcon /> Goodreads</> },
  { value: "newsletter", label: <><NewsletterIcon /> Newsletter</> },
  { value: "pinterest", label: <><PinterestIcon /> Pinterest</> },
  { value: "soundcloud", label: <><SoundcloudIcon /> Soundcloud</> },
  { value: "spotify", label: <><SpotifyIcon /> Spotify</> },
  { value: "twitch", label: <><TwitchIcon /> Twitch</> },
  { value: "vimeo", label: <><VimeoIcon /> Vimeo</> },
  { value: "vsco", label: <><VSCOIcon /> VSCO</> },
  { value: "website", label: <><WebsiteIcon /> Website</> },
];

export const creatorsIcon = {
  "apple_music": <AppleMusicIcon />,
  "behance": <BehanceIcon />,
  "blog": <BlogIcon />,
  "discord": <DiscordIcon />,
  "etsy": <EtsyIcon />,
  "flickr": <FlickrIcon />,
  "github": <GitHubIcon />,
  "goodreads": <GoodreadsIcon />,
  "newsletter": <NewsletterIcon />,
  "pinterest": <PinterestIcon />,
  "soundcloud": <SoundcloudIcon />,
  "spotify": <SpotifyIcon />,
  "twitch": <TwitchIcon />,
  "vimeo": <VimeoIcon />,
  "vsco": <VSCOIcon />,
  "website": <WebsiteIcon />,
};

export const creatorsLinks = {
  "apple_music": "https://music.apple.com/",
  "behance": "https://www.behance.net/",
  "blog": null,
  "discord": "https://discord.com/invite/",
  "etsy": "https://www.etsy.com/shop/",
  "flickr": "https://www.flickr.com/photos/",
  "github": "https://www.github.com/",
  "goodreads": null,
  "newsletter": null,
  "pinterest": "https://www.pinterest.com/",
  "soundcloud": "https://www.soundcloud.com/",
  "spotify": null,
  "twitch": "https://www.twitch.tv/",
  "vimeo": "https://www.vimeo.com/",
  "vsco": "https://vsco.co/",
  "website": null,
};


export const bills = [
  { value: "cash_app", label: <><CashAppIcon /> CashApp</> },
  { value: "paypal", label: <><PayPalIcon /> PayPal</> },
  { value: "venmo", label: <><VenmoIcon /> Venmo</> },
  { value: "wechat_pay", label: <><WeChatPayIcon /> WeChat Pay</> },
];

export const billsIcon = {
  "cash_app": <CashAppIcon />,
  "paypal": <PayPalIcon />,
  "venmo": <VenmoIcon />,
  "wechat_pay": <WeChatPayIcon />,
};

export const billsLinks = {
  "cash_app": "https://www.cash.app/$",
  "paypal": "https://www.paypal.me/",
  "venmo": "https://www.venmo.com/",
  "wechat_pay": "https://pay.wechat.com/u/",
};



