import { Avatar, Button, List } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import UserList from "../../../../../components/UserList/UserList";
import { useCurrentUserContext } from '../../../../../store';
import commonConnections from '../../../../../utils/commonConnections';

export default function SearchResults({ results, onAction }) {
  const { currentUser } = useCurrentUserContext();
  return (
    <div>
      {results && results.length > 0 && (
        <UserList
          users={results?.map((result) => {
            const mutualConnections = commonConnections(
              result.connectorsB.filter(connector => connector.status === "ACTIVE"),
              currentUser.connectors?.items?.filter(connector => connector.status === "ACTIVE"),
            )
            return {
              user: {
                firstName: result.firstName,
                lastName: result.lastName,
                username: result.username,
                avatarUrl: result.avatarUrl,
              },
              mutualConnections: mutualConnections.length || 0,
              connection: result.connectors.length > 0 && result.connectors[0],
            };
          })}
          onAddConnection={onAction}
        />
      )}
    </div>
  );
}
