import React, { useEffect, useState } from "react";
import DailyEvents from "../DailyEvents/DailyEvents";
import { List } from "antd";
import ListItemWrap from "../../../../components/ListItemWrap/ListItemWrap";

import styles from "./DashboardUpcoming.module.css";

export default function DashboardUpcoming({ events }) {

  return (
    <div style={{ width: "100%" }}>
      {events?.length > 0 ? (
        events.map((event) =>
          <>
            {event.monthDay === "01-01" &&
              <div style={{
                fontFamily: "var(--primary-font-family)",
                fontSize: "24px",
                padding: "19px"
              }}>
                2025
              </div>
            }
            <DailyEvents date={event.monthDay} events={event.events} />
          </>
        )
      ) : (
        <List>
          <List.Item>
            <ListItemWrap>
              <div className={styles.noEventsText}>
                You have no one upcoming events to celi. Add your friends and
                family with your PAC link to see their upcoming events!
              </div>
            </ListItemWrap>
          </List.Item>
        </List>
      )}
    </div>
  );
}
