import React, { useEffect, useState } from "react";
import { useAuthUserContext } from "../../../../store";
import { Avatar, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ConnectionStatus } from "../../../../models";
import styles from "./ProfilePreview.module.css";
import { Storage } from "aws-amplify";
import AddConnectionButton from "../../../../components/buttons/AddConnectionButton/AddConnectionButton";
import PendingConnectionButton from "../../../../components/buttons/PendingConnectionButton/PendingConnectionButton";
import AcceptRequestButton from "../../../../components/buttons/AcceptRequestButton/AcceptRequestButton";
import DeclineConnectionButton from "../../../../components/buttons/DeclineConnectionButton/DeclineConnectionButton";

export default function ProfilePreview({ user, loadUser }) {
  const { authUser } = useAuthUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [image, setImage] = useState('')
  // const user = {
  //   firstName: "Alice",
  //   lastName: "Johnson",
  //   username: "alice_j",
  //   connection: {
  //     id: "1",
  //     _version: 1,
  //     status: "REQUESTED",
  //   },
  // };

  const directToSignIn = () => {
    sessionStorage.setItem("intendedLocation", location.pathname);
    navigate("signin");
  };

  useEffect(() => {
    if(user.avatarUrl) {
      Storage.get(user.avatarUrl).then((image) => setImage(image));
    }
  }, [user.avatarUrl]);


  return (
    <div className={styles.profilePreview}>
      {image ? <Avatar
        size={150}
        src={image || ""}
      /> : <div className={styles.avatar} style={{ backgroundColor: '#BCBAED' }}>
        {user.firstName?.substr(0, 1)?.toUpperCase()}{user.lastName?.substr(0, 1)?.toUpperCase()}
      </div>}
      {!authUser && (
        <div className={styles.text}>Sign in to view this profile</div>
      )}
      <div className={styles.name}>{user.firstName + " " + user.lastName}</div>
      <div className={styles.username}>@{user.username}</div>
      {authUser &&
        user.connector &&
        user.connector.status === ConnectionStatus.REQUESTED && (
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <DeclineConnectionButton
              connection={user.connector}
              onSuccess={() => loadUser()}
            />
            <AcceptRequestButton
              connection={user.connector}
              onSuccess={() => loadUser()}
            />
          </div>
        )}
      {authUser &&
        (!user.connector ||
          user.connector.status !== ConnectionStatus.REQUESTED) &&
        (!user.connection ||
          user.connection.status !== ConnectionStatus.REQUESTED) && (
          <AddConnectionButton user={user} onSuccess={() => loadUser()} />
        )}
      {authUser &&
        user.connection &&
        user.connection.status === ConnectionStatus.REQUESTED && (
          <PendingConnectionButton connection={user.connection} onDecline={() => loadUser()} />
        )}
      {!authUser && (
        <Button type="primary" onClick={directToSignIn}>
          Add to Celi
        </Button>
      )}
    </div>
  );
}
