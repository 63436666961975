import React from "react";

export default function LinkedinIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.39569 5.05278e-06C1.06417 0.00269229 -0.0053543 1.08027 2.01669e-05 2.4118C0.00673825 3.73257 1.07492 4.79806 2.39704 4.80075C3.7299 4.80343 4.80211 3.73257 4.80077 2.3997C4.80077 1.06684 3.72856 -0.00268218 2.39569 5.05278e-06Z" fill="white" />
      <path d="M7.11562 19.9961C7.10487 19.9088 7.08338 19.8214 7.08338 19.7354C7.08203 15.4506 7.08203 11.1658 7.08203 6.8824C7.08203 6.80984 7.08203 6.73594 7.08203 6.64592H11.0565V8.40606C11.0739 8.41143 11.0914 8.41815 11.1075 8.42352C11.1424 8.38456 11.1827 8.34828 11.2096 8.30529C11.9016 7.19546 12.9254 6.58814 14.1924 6.37585C15.1263 6.21865 16.0547 6.29389 16.9657 6.55724C18.32 6.94823 19.1517 7.86324 19.583 9.17595C19.8921 10.1165 19.9969 11.0893 20.0036 12.0701C20.0197 14.6418 20.0143 17.2148 20.0157 19.7865C20.0157 19.8577 19.9875 19.9276 19.9727 19.9988H15.9244C15.9056 19.9007 15.872 19.8013 15.872 19.7032C15.8693 17.4943 15.88 15.284 15.8612 13.0751C15.8572 12.5377 15.7873 11.9949 15.6865 11.4668C15.5549 10.7802 15.1625 10.2455 14.4679 10.0695C14.0487 9.96331 13.5838 9.96062 13.1498 10.009C12.4431 10.0883 11.9177 10.4766 11.6033 11.1336C11.3332 11.6966 11.2486 12.2972 11.2446 12.9072C11.2311 15.1685 11.2352 17.4298 11.2311 19.6911C11.2311 19.7946 11.2177 19.8967 11.2096 20.0001H7.11697L7.11562 19.9961Z" fill="#171717" fill-opacity="0.9" />
      <path d="M0.353526 19.996C0.345464 19.9087 0.332031 19.82 0.332031 19.7327C0.332031 15.4492 0.332031 11.1658 0.332031 6.88235C0.332031 6.8098 0.332031 6.7359 0.332031 6.64587H4.46902C4.47305 6.72918 4.48112 6.80711 4.48112 6.88638C4.48112 11.1698 4.48112 15.4533 4.48112 19.7367C4.48112 19.8241 4.45962 19.9114 4.44887 19.9974H0.356215L0.353526 19.996Z" fill="#171717" fill-opacity="0.9" />
      <path d="M2.3957 5.05278e-06C3.72857 -0.00268218 4.80078 1.06684 4.80078 2.3997C4.80078 3.73257 3.72991 4.80343 2.39705 4.80075C1.07493 4.79806 0.00674952 3.73392 3.14372e-05 2.4118C-0.00668665 1.08027 1.06418 0.00269229 2.3957 5.05278e-06Z" fill="#171717" fill-opacity="0.9" />
    </svg>
  );
}