import {
  Avatar,
  Badge,
  Button,
  Modal,
  Radio,
  RadioChangeEvent,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import ProfileDates from "../profile/(components)/ProfileDates/ProfileDates";
import ProfileConnections from "../profile/(components)/ProfileConnections/ProfileConnections";
import ProfileInformation from "./(components)/ProfileInformation/ProfileInformation";
import Header from "../../components/header/Header";
import { useAuthUserContext, useCurrentUserContext } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import UserAvatar from "../../components/UserAvatar/UserAvatar";
import { Content } from "antd/es/layout/layout";

import styles from "./Me.module.css";
import SwitchSelector from "react-switch-selector";
import { EditOutlined, EyeOutlined, PlusSquareFilled } from "@ant-design/icons";
import BackButton from "../../components/buttons/BackButton/BackButton";
import { useMediaQuery } from "react-responsive";
import UserSearchIcon from "../../components/icons/UserSearchIcon/UserSearchIcon";
import { ConnectionStatus } from "../../models";
import Uploader from "./(components)/Uploader/Uploader";

export default function Me() {
  const { currentUser } = useCurrentUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const [options, setOptions] = useState([
    {
      label: "Dates",
      value: "dates",
      selectedBackgroundColor: "#CBC9FF",
      selectedFontColor: "rgba(23, 23, 23, 0.90)",
    },
    {
      label: "Connections",
      value: "connections",
      selectedBackgroundColor: "#CBC9FF",
      selectedFontColor: "rgba(23, 23, 23, 0.90)",
    },
    {
      label: isDesktopOrLaptop ? "Information" : "Info",
      value: "information",
      selectedBackgroundColor: "#CBC9FF",
      selectedFontColor: "rgba(23, 23, 23, 0.90)",
    },
  ])


  const [tab, setTab] = useState("dates");

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  useEffect(() => {

    location.state?.currentTab && setTab(location.state?.currentTab)

  }, [location])


  useEffect(() => {
    if(currentUser.username === "celi" && options.length > 2) {
      setOptions(prevOptions => prevOptions.filter((item, i) => i !== 1))
    }
  }, [])

  return (
    <div className="">
      <Header avatar />
      <Content className={styles.content}>
        <div className={styles.contentWrap}>
          <div className={styles.backButtonWrap}>
            <BackButton />
          </div>
          <div className={styles.userInfo}>
            <div className={styles.userAvatar} style={{ position: "relative" }}>
              {currentUser.avatarUrl && (
                <div
                  style={{ position: "absolute", top: 0, cursor: "pointer" }}
                >
                  <UserAvatar
                    imageUrl={currentUser.avatarUrl || "celi-logo-transp 2.png"}
                  />
                </div>
              )}
              <div
                style={{ zIndex: 50/* , opacity: currentUser.avatarUrl ? 0 : 1 */ }}
              >
                <Uploader />
              </div>
            </div>
            <div className={styles.userFullName}>
              {currentUser.firstName + " " + currentUser.lastName}
            </div>
            <div className={styles.usernamePreviewWrap}>
              <div className={styles.userUsername}>@{currentUser.username}</div>
              <Button
                className={styles.usernamePreviewButton}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <g clip-path="url(#clip0_1145_10148)">
                      <path
                        d="M10.0007 3.75C5.83398 3.75 2.27565 6.34167 0.833984 10C2.27565 13.6583 5.83398 16.25 10.0007 16.25C14.1673 16.25 17.7257 13.6583 19.1673 10C17.7257 6.34167 14.1673 3.75 10.0007 3.75ZM10.0007 14.1667C7.70065 14.1667 5.83398 12.3 5.83398 10C5.83398 7.7 7.70065 5.83333 10.0007 5.83333C12.3007 5.83333 14.1673 7.7 14.1673 10C14.1673 12.3 12.3007 14.1667 10.0007 14.1667ZM10.0007 7.5C8.61732 7.5 7.50065 8.61667 7.50065 10C7.50065 11.3833 8.61732 12.5 10.0007 12.5C11.384 12.5 12.5007 11.3833 12.5007 10C12.5007 8.61667 11.384 7.5 10.0007 7.5Z"
                        fill="#CBC9FF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1145_10148">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                }
                onClick={() => {
                  navigate("/@/" + currentUser.username, {
                    state: {
                      currentTab: tab
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className={styles.switchSelectorWrap}>
            <SwitchSelector
              onChange={handleTabChange}
              options={options}
              initialSelectedIndex={0}
              forcedSelectedIndex={options.findIndex(
                (option) => option.value === tab
              )}
              backgroundColor={"rgba(237, 231, 251, 0.30)"}
              fontColor={"rgba(23, 23, 23, 0.90)"}
              optionBorderRadius={30}
              wrapperBorderRadius={30}
            />
          </div>
          <div className={styles.tab}>
            {tab === "dates" && (
              <>
                <ProfileDates isEditProfile={true} eventsProp={currentUser.events?.items || []} />
                <div className={styles.addButtonWrap}>
                  <Button
                    size="large"
                    className={styles.addButton}
                    icon={<PlusSquareFilled className={styles.addIcon} />}
                    type="primary"
                    onClick={() =>
                      navigate("/@/" + currentUser.username + "/events")
                    }
                  >
                    Add more dates
                  </Button>
                </div>
                <div className={styles.addButtonWrap}>
                  <span className={styles.tapToEdit}>Tap date to edit its details</span>
                </div>
              </>
            )}
            {tab === "connections" && (
              <>
                <ProfileConnections
                  connections={
                    currentUser.connections.items
                      ? currentUser.connections?.items
                        .filter(
                          (connection) =>
                            connection.status === ConnectionStatus.ACTIVE
                        )
                        .map((connection) => {
                          return {
                            user: connection.receivingUser,
                            mutualConnections: 0,
                            connection: connection,
                          };
                        })
                      : []
                  }
                />
                <div className={styles.addButtonWrap}>
                  <Button
                    size="large"
                    className={styles.addButton}
                    type="primary"
                    icon={<UserSearchIcon />}
                    onClick={() => navigate("/?tab=connect")}
                  >
                    Add more connections
                  </Button>
                </div>
              </>
            )}
            {tab === "information" && <ProfileInformation />}
          </div>
        </div>
      </Content>
    </div>
  );
}
