import React from "react";
import {
  Navigate,
  createHashRouter,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAuthUserContext, useCurrentUserContext } from "../store";
import AppLayout from "../AppLayout";
import SignIn from "../screens/signin/SignIn";
import Dashboard from "../screens/dashboard/Dashboard";
import Profile from "../screens/profile/Profile";
import Me from "../screens/me/Me";
import Events from "../screens/events/Events";
import ForgotPassword from "../screens/forgotPassword/ForgotPassword";
import Error from '../screens/error/Error';
import ErrorBoundary from '../components/Error/ErrorBoundary';

function RequireUnAuth({ children }: { children: JSX.Element }) {
  const { authUser } = useAuthUserContext();
  const { currentUser } = useCurrentUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if(location.pathname === "/signin" && authUser && currentUser) {
    const intendedLocation = sessionStorage.getItem("intendedLocation") || "/";

    // Clear the stored intended location
    sessionStorage.removeItem("intendedLocation");

    return <Navigate to={intendedLocation} replace />;
  }

  return children;
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const { authUser } = useAuthUserContext();
  const { currentUser } = useCurrentUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  if(!authUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    location.pathname !== "/" &&
      sessionStorage.setItem("intendedLocation", location.pathname);

    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

function RequireIsCurrentUser({ children }: { children: JSX.Element }) {
  const { currentUser } = useCurrentUserContext();
  const { username } = useParams();

  if(username !== currentUser.username) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export const AppRouter = createHashRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: (
          <RequireAuth>
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          </RequireAuth>
        ),
      },
      {
        path: "/signin",
        element: (
          <RequireUnAuth>
            <SignIn />
          </RequireUnAuth>
        ),
      },
      {
        path: "/forgot-password",
        element: (
          <RequireUnAuth>
            <ForgotPassword />
          </RequireUnAuth>
        ),
      },
      {
        path: "/@/:username/events",
        element: (
          <RequireAuth>
            <RequireIsCurrentUser>
              <ErrorBoundary>
                <Events />
              </ErrorBoundary>
            </RequireIsCurrentUser>
          </RequireAuth>
        ),
      },
      {
        path: "/@/:username/edit",
        element: (
          <RequireAuth>
            <RequireIsCurrentUser>
              <ErrorBoundary>
                <Me />
              </ErrorBoundary>
            </RequireIsCurrentUser>
          </RequireAuth>
        ),
      },
      {
        path: "/@/:username",
        element: <ErrorBoundary><Profile /></ErrorBoundary>,
      },
      { path: "*", element: <Navigate to="/" replace /> },
    ],
  },
]);
