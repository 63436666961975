import React from "react";

export default function UserAddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="currentColor"
    >
      <g clip-path="url(#clip0_1177_6061)">
        <path
          d="M31.787 19.1238C31.6325 19.6536 31.3877 20.1252 30.8543 20.361C30.6153 20.4658 30.3442 20.5532 30.0877 20.559C28.9713 20.5794 27.852 20.5677 26.7357 20.5677C26.7095 20.5677 26.6861 20.5561 26.6016 20.5357C26.6016 20.1369 26.6366 19.7264 26.5929 19.3246C26.5229 18.6434 26.1702 18.1136 25.6397 17.6827C23.6169 16.0321 21.3433 14.8792 18.8104 14.2446C18.7434 14.2271 18.6763 14.2038 18.5918 14.1776C18.6209 14.1427 18.6355 14.1078 18.6618 14.0903C19.37 13.5604 19.9559 12.92 20.4369 12.1776C20.4893 12.0961 20.6409 12.0408 20.7487 12.035C22.929 11.9185 25.0393 12.265 27.0767 13.0423C28.6157 13.6303 30.0265 14.4455 31.2944 15.4935C31.4868 15.6507 31.6238 15.8778 31.7841 16.0728V19.1267L31.787 19.1238Z"
          fill="#333333"
        />
        <path
          d="M22.0206 6.10352e-05C22.4171 0.101953 22.8222 0.171822 23.204 0.308649C25.2298 1.03645 26.556 2.85596 26.6289 4.97823C26.7018 7.06556 25.4921 8.98114 23.5363 9.8545C22.9009 10.1369 22.2276 10.2708 21.5339 10.2824C21.4552 10.2824 21.3765 10.2708 21.3182 10.2679C21.939 6.58812 20.5778 3.93019 17.2754 2.20967C17.7592 1.49933 18.4092 0.949115 19.1904 0.547368C19.7792 0.241691 20.4117 0.107776 21.0559 6.10352e-05C21.3794 6.10352e-05 21.7 6.10352e-05 22.0206 6.10352e-05Z"
          fill="#333333"
        />
        <path
          d="M14.608 23.9971C11.8185 23.9971 9.02908 23.9971 6.23964 23.9971C5.10871 23.9971 4.39167 23.3625 4.33338 22.2359C4.28966 21.38 4.31589 20.5241 4.32172 19.6653C4.32172 19.3887 4.47037 19.1791 4.68024 19.0073C6.74098 17.313 9.07572 16.1747 11.7048 15.7031C16.4647 14.853 20.7377 15.968 24.5182 18.9753C24.7864 19.1878 24.9088 19.4615 24.9117 19.7875C24.9205 20.6347 24.9379 21.4848 24.9117 22.3319C24.8826 23.3072 24.1422 23.9884 23.1395 23.9971C22.0144 24.0059 20.8864 23.9971 19.7613 23.9971C18.0445 23.9971 16.3277 23.9971 14.6109 23.9971H14.608Z"
          fill="#333333"
        />
        <path
          d="M14.6172 3.42944C17.4562 3.42944 19.7735 5.74385 19.7676 8.57646C19.7618 11.412 17.4387 13.7235 14.6056 13.7118C11.7753 13.7031 9.47555 11.4032 9.46681 8.57937C9.46098 5.74676 11.7782 3.43235 14.6172 3.42944Z"
          fill="#333333"
        />
        <path
          d="M4.35614 11.15V13.5837H2.72678V11.1529H0.287109V9.51678H2.72386V7.08301H4.35322V9.51387H6.79289V11.1471H4.35614V11.15Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_1177_6061">
          <rect
            width="31.5"
            height="24"
            fill="white"
            transform="translate(0.287109 6.10352e-05)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
