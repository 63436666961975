import { Button, Collapse, Drawer, Empty, List } from "antd";
import React, { useState } from "react";
import ListItemWrap from "../../../../components/ListItemWrap/ListItemWrap";
import { getEventTypeInfo } from "../../../../utils/eventDisplayInformation";
import EventTag from "../../../../components/EventTag/EventTag";

import styles from "./ProfileDates.module.css";
import { useMediaQuery } from "react-responsive";
import { AWSDatetoDisplayDate } from "../../../../utils/AWSDatetoDisplayDate";
import Modal from 'antd/es/modal/Modal';
import EventCreator, { Mode } from '../../../events/(components)/EventCreator/EventCreator';
import { useCurrentUserContext } from '../../../../store';

interface ProfileDateProps {
  id: string;
  type: string;
  fields: any;
  date: string;
  monthDay: string;
}

interface ProfileDatesProps {
  isEditProfile?: boolean
  eventsProp: ProfileDateProps[];
}


const ListItemMobile = ({ event, displayInfo, index }) => {
  return (<Collapse
    style={{
      width: "100%",
      border: "1px solid #ede7fb",
      borderRadius: "8px",
      padding: "14px 0"
    }}
    ghost
    expandIcon={() => null}
    items={[
      {
        key: index,
        label: <div className={styles.collapseHeader}>
          <div>
            <EventTag
              text={AWSDatetoDisplayDate(event.date)}
              backgroundColor={displayInfo.color}
              fontColor={displayInfo.strongColor}
              borderColor={displayInfo.strongColor}
            />
          </div>
          <div className={styles.event}>
            <EventTag
              text={displayInfo.label}
              backgroundColor={displayInfo.color}
            />
          </div>
        </div>,
        children: <div className={styles.collapseContent}>
          {Object.values(event.fields).map((value: string) => (
            <span style={{ margin: "0 0 0 10px" }}>
              <EventTag
                text={value}
                backgroundColor={displayInfo.color}
              />
            </span>
          ))}
        </div>,
        style: {
          padding: 0,
        } as React.CSSProperties,
      }
    ]}
  />)
}

const ListItemDesktop = ({ event, displayInfo, index }) => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <ListItemWrap>
      <div>
        <EventTag
          text={AWSDatetoDisplayDate(event.date)}
          backgroundColor={displayInfo.color}
          fontColor={displayInfo.strongColor}
          borderColor={displayInfo.strongColor}
        />
      </div>
      <div className={styles.eventInfo}>
        <EventTag
          text={displayInfo.label}
          backgroundColor={displayInfo.color}
        />
        {isDesktopOrLaptop &&
          Object.values(event.fields).map((value: string) => (
            <EventTag
              text={value}
              backgroundColor={displayInfo.color}
            />
          ))}
      </div>

      {isTabletOrMobile &&
        Object.values(event.fields).length > 0 && (
          <div
            className={styles.eventFields}
            style={{ width: "100%" }}
          >
            {Object.values(event.fields).map((value: string) => (
              <EventTag
                text={value}
                backgroundColor={displayInfo.color}
              />
            ))}
          </div>
        )}
    </ListItemWrap>
  )
}

export default function ProfileDates({ eventsProp, isEditProfile = false }: ProfileDatesProps) {

  const { setCurrentUser, currentUser } = useCurrentUserContext();
  const [events, setEvents] = useState(eventsProp);
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [openModal, setOpenModal] = useState(false);
  const [eventSelected, setEventSelected] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const onSubmit = async (data) => {
    if(data?.option === "delete") {
      setCurrentUser({
        ...currentUser,
        events: {
          items: currentUser.events.items?.filter(event => event.id !== eventSelected.id)
        }
      })
      setEvents(events.filter(event => event.id !== eventSelected.id))
      setOpenModal(false)
    }
    if(data?.option === "edit") {
      setCurrentUser({
        ...currentUser,
        events: {
          items: [...currentUser.events.items?.filter(event => event.id !== eventSelected.id), data.data].filter(v => true).sort(
            (a, b) =>
              new Date(a.monthDay).getTime() - new Date(b.monthDay).getTime()
          )
        }
      })
      setEvents([...events.filter(event => event.id !== eventSelected.id), data.data].filter(v => true).sort(
        (a, b) =>
          new Date(a.monthDay).getTime() - new Date(b.monthDay).getTime()
      ));
      setIsEditing(false)
      setOpenModal(false)
    }
  }

  return (
    <div className={styles.main}>
      <List>
        {events
          .sort(
            (a, b) =>
              new Date("2000-" + a.monthDay).getTime() - new Date("2000-" + b.monthDay).getTime()
          )
          .map((event, index) => {
            const displayInfo = getEventTypeInfo(event.type);

            return (
              <List.Item onClick={() => {
                if(isEditProfile) {
                  setOpenModal(true)
                  setEventSelected(event)
                }
              }}>
                {(!isDesktopOrLaptop && !isEditProfile && Object.values(event.fields).length > 0) ?
                  <ListItemMobile event={event} displayInfo={displayInfo} index={index} />
                  :
                  <ListItemDesktop event={event} displayInfo={displayInfo} index={index} />
                }

                {/* {(isDesktopOrLaptop || isEditProfile) &&
                  <ListItemDesktop event={event} displayInfo={displayInfo} index={index} />
                } */}
              </List.Item>
            );
          })}
      </List>

      {isDesktopOrLaptop ? <Modal
        open={openModal}
        footer={<></>}
        onCancel={() => {
          setOpenModal(false)
          setIsEditing(false)
        }}>
        <EventCreator
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setOpenModal={setOpenModal}
          mode={Mode.EDIT}
          defaultEvent={eventSelected}
          eventType={eventSelected?.type}
          onEventSubmit={onSubmit}
        />
      </Modal> : <Drawer
        open={openModal}
        placement="bottom"
        height="initial"
        onClose={() => setOpenModal(false)}
        className={styles.drawerTitle}
        closeIcon={
          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_813_23335)"><path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black" /></g>
            <defs>
              <clipPath id="clip0_813_23335">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      >
        <EventCreator
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          mode={Mode.EDIT}
          setOpenModal={setOpenModal}
          defaultEvent={eventSelected}
          eventType={eventSelected?.type}
          onEventSubmit={onSubmit}
        />
      </Drawer>}
    </div>
  );
}
