// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const EventStatus = {
  "PUBLIC": "PUBLIC",
  "PRIVATE": "PRIVATE"
};

const ConnectionStatus = {
  "REQUESTED": "REQUESTED",
  "ACTIVE": "ACTIVE",
  "DECLINED": "DECLINED",
  "BLOCKED": "BLOCKED"
};

const EventType = {
  "BIRTHDAY": "BIRTHDAY",
  "ANNIVERSARY": "ANNIVERSARY",
  "WORKIVERSARY": "WORKIVERSARY",
  "SOBERVERSARY": "SOBERVERSARY",
  "REMEMBRANCE_DAY": "REMEMBRANCE_DAY",
  "ARRIVAL_DAY": "ARRIVAL_DAY",
  "CITIZENSHIPVERSARY": "CITIZENSHIPVERSARY",
  "CHILD_BIRTHDAY": "CHILD_BIRTHDAY",
  "PET_BIRTHDAY": "PET_BIRTHDAY",
  "HEALTHVERSARY": "HEALTHVERSARY",
  "OUTVERSARY": "OUTVERSARY",
  "REBIRTHDAY": "REBIRTHDAY",
  "CHILD_ADOPTIONVERSARY": "CHILD_ADOPTIONVERSARY",
  "PARENT_ADOPTIONVERSARY": "PARENT_ADOPTIONVERSARY",
  "FOUNDER_DAY": "FOUNDER_DAY"
};

const { Connection, User, Event } = initSchema(schema);

export {
  Connection,
  User,
  Event,
  EventStatus,
  ConnectionStatus,
  EventType
};