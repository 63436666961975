import React, { useEffect, useState } from "react";

import styles from "./ProfileView.module.css";
import BackButton from "../../../../components/buttons/BackButton/BackButton";
import ProfileUser from "../ProfileUser/ProfileUser";
import SwitchSelector from "react-switch-selector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ProfileDates from "../ProfileDates/ProfileDates";
import ProfileConnections from "../ProfileConnections/ProfileConnections";

import RemoveConnectionButton from "../../../../components/buttons/RemoveConnectionButton/RemoveConnectionButton";
import { useCurrentUserContext } from "../../../../store";
import sortConnections from '../../../../utils/sortConnections';
import commonConnections from '../../../../utils/commonConnections';
import ProfileInformation from '../ProfileInformation/ProfileInformation';

export default function ProfileView({ user, loadUser }) {
  const [tab, setTab] = useState("dates");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useCurrentUserContext();
  const [options, setOptions] = useState([
    {
      label: "Dates",
      value: "dates",
      selectedBackgroundColor: "#CBC9FF",
      selectedFontColor: "rgba(23, 23, 23, 0.90)",
    },
    {
      label: "Connections",
      value: "connections",
      selectedBackgroundColor: "#CBC9FF",
      selectedFontColor: "rgba(23, 23, 23, 0.90)",
    },
    {
      label: isDesktopOrLaptop ? "Information" : "Info",
      value: "information",
      selectedBackgroundColor: "#CBC9FF",
      selectedFontColor: "rgba(23, 23, 23, 0.90)",
    },
  ])
  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    location.state?.currentTab && setTab(location.state?.currentTab)


  }, [location])




  useEffect(() => {
    if(user.username === "celi" && options.length > 2) {
      setOptions(prevOptions => prevOptions.filter((item, i) => i !== 1))
    }
  }, [])

  return (
    <div className={styles.content}>
      <div className={styles.contentWrap}>
        <div className={styles.backButtonWrap}>
          <BackButton />
        </div>
        <div className={styles.profileUserWrap}>
          <ProfileUser user={user} tab={tab} />
        </div>
        <div style={{ height: "56px", width: "350px" }}>
          <SwitchSelector
            onChange={handleTabChange}
            options={options}
            initialSelectedIndex={0}
            forcedSelectedIndex={options.findIndex(
              (option) => option.value === tab
            )}
            backgroundColor={"rgba(237, 231, 251, 0.30)"}
            fontColor={"rgba(23, 23, 23, 0.90)"}
            optionBorderRadius={30}
            wrapperBorderRadius={30}
          />
        </div>
        <div className={styles.tab}>
          {tab === "dates" && <ProfileDates eventsProp={user.events.items || []} />}
          {tab === "connections" && (
            <ProfileConnections
              connections={
                user.connections.items
                  ? user.connections?.items.sort(sortConnections).map((connection) => {
                    const mutualsConnections = commonConnections(
                      connection.connector?.sendingUser?.connectors?.items?.filter(connector => connector.status === "ACTIVE"),
                      currentUser.connectors?.items?.filter(connector => connector.status === "ACTIVE"),
                    )


                    return {
                      user: connection.receivingUser,
                      mutualConnections: mutualsConnections.length || 1,
                      connection: connection,
                    };
                  })
                  : []
              }
              onAddConnection={() => loadUser()}
            />
          )}
        </div>
        {tab === "connections" && currentUser.username !== user.username && (
          <RemoveConnectionButton
            connection={user.connection}
            onSuccess={() => document.location.reload()}
          />
        )}

        {tab === "information" && <ProfileInformation user={user} />}
      </div>
    </div>
  );
}
