import React, { useEffect, useState } from "react";
import { useAuthUserContext, useCurrentUserContext } from "../../store";
import Header from "../../components/header/Header";
import ProfilePreview from "./(components)/ProfilePreview/ProfilePreview";

import styles from "./Profile.module.css";
import ProfileView from "./(components)/ProfileView/ProfileView";
import { getPublicUser, getUser } from "../../api/api";
import { useParams } from "react-router-dom";

export default function Profile() {
  const { authUser } = useAuthUserContext();
  const { currentUser } = useCurrentUserContext();
  const [user, setUser] = useState(null);
  const { username } = useParams();
  const [loading, setLoading] = useState(true);

  const loadUser = async () => {
    setLoading(true);
    try {
      let user = null;
      if(authUser) {
        user = await getUser(username, currentUser.id);
      } else {
        user = await getPublicUser(username)
      }
      setUser(user);
    } catch(e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if(authUser) {
    loadUser();
    // }
  }, [authUser, username]);

  return (
    <div className={styles.main}>
      <Header avatar={authUser} signIn={!authUser} />
      {!loading &&
        (authUser &&
          (currentUser.username === user.username ||
            (user.connection && user.connection.status === "ACTIVE")) ? (
          <>
            <ProfileView user={user} loadUser={loadUser} />
          </>
        ) : (
          <div className={styles.previewWrap}>
            <ProfilePreview user={user} loadUser={loadUser} />
          </div>
        ))}
    </div>
  );
}
