import React from "react";

export default function ReceivingGiftsIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 17 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.19241 12.948V13.201C3.19241 15.2134 3.19241 17.2254 3.19241 19.2379C3.19241 19.7556 3.43857 19.9995 3.95815 19.9995C5.52415 20 7.09015 19.9995 8.65615 19.9995C8.73654 19.9995 8.81647 19.9995 8.89822 19.9995V8.31903C8.80966 8.31494 8.73563 8.30813 8.6616 8.30813C6.90666 8.30768 5.15127 8.30722 3.39633 8.30813C2.85087 8.30813 2.6097 8.5502 2.60924 9.09885C2.60833 10.1048 2.60924 11.1113 2.60924 12.1173C2.60924 12.6005 2.72188 12.7672 3.19241 12.9484V12.948ZM10.0423 19.9995C10.1363 19.9995 10.2103 19.9995 10.2844 19.9995C11.8436 19.9995 13.4027 19.9995 14.9619 19.9986C15.5101 19.9986 15.7513 19.7584 15.7517 19.2143C15.7522 17.4357 15.7522 15.6567 15.7522 13.8781C15.7522 13.6134 15.7449 13.3481 15.7576 13.0838C15.7604 13.0211 15.8162 12.9266 15.8698 12.9062C16.1896 12.7845 16.3258 12.5547 16.3249 12.2227C16.3226 11.1631 16.3249 10.1035 16.3222 9.04389C16.3208 8.55157 16.0733 8.30813 15.5769 8.30813C13.8056 8.30722 12.0348 8.31085 10.2635 8.30495C10.0791 8.30449 10.0196 8.34991 10.0196 8.54384C10.0255 12.2604 10.0237 15.9769 10.0246 19.693C10.0246 19.7883 10.0355 19.8842 10.0423 20V19.9995ZM8.85962 7.66501C8.86143 7.63958 8.86325 7.61414 8.86507 7.58871C8.76969 7.55646 8.67658 7.51105 8.57848 7.49424C7.53887 7.31575 6.70183 6.79436 5.97333 6.04224C5.22167 5.26651 4.4473 4.5103 3.64658 3.78544C2.88902 3.09963 1.9961 2.97474 1.04688 3.34989C0.461897 3.58106 0.222546 4.09382 0 4.67653C0.10764 4.65564 0.158507 4.64974 0.207104 4.63566C1.23309 4.33363 2.17732 4.48396 3.03572 5.13616C3.81826 5.73068 4.60217 6.32292 5.39153 6.9079C6.09505 7.42929 6.85806 7.81353 7.75415 7.85804C8.13793 7.87711 8.51898 7.86758 8.86007 7.66501H8.85962Z" fill="white" />
      <path d="M3.19274 12.948C2.72221 12.7668 2.60958 12.6001 2.60958 12.1169C2.60958 11.1109 2.60912 10.1044 2.60958 9.09841C2.61003 8.55022 2.8512 8.30815 3.39667 8.3077C5.1516 8.30724 6.90699 8.3077 8.66193 8.3077C8.73596 8.3077 8.80999 8.31451 8.89855 8.3186V19.9991C8.8168 19.9991 8.73687 19.9991 8.65648 19.9991C7.09048 19.9991 5.52448 19.9991 3.95848 19.9991C3.4389 19.9991 3.19274 19.7552 3.19274 19.2374C3.19183 17.225 3.19274 15.213 3.19274 13.2005V12.9476V12.948Z" fill="#171717" fill-opacity="0.9" />
      <path d="M10.0422 19.9996C10.0354 19.8837 10.0245 19.7884 10.0245 19.6925C10.0236 15.976 10.0254 12.2595 10.0195 8.54341C10.0195 8.34903 10.079 8.30361 10.2634 8.30452C12.0347 8.31042 13.8055 8.30679 15.5768 8.3077C16.0732 8.3077 16.3208 8.55114 16.3221 9.04346C16.3249 10.1031 16.3221 11.1626 16.3249 12.2222C16.3258 12.5542 16.1895 12.7841 15.8698 12.9058C15.8157 12.9262 15.7603 13.0207 15.7576 13.0834C15.7453 13.3477 15.7526 13.6129 15.7521 13.8777C15.7521 15.6563 15.7521 17.4353 15.7517 19.2138C15.7517 19.7579 15.5101 19.9977 14.9619 19.9982C13.4027 19.9991 11.8435 19.9986 10.2843 19.9991C10.2103 19.9991 10.1363 19.9991 10.0422 19.9991V19.9996Z" fill="#171717" fill-opacity="0.9" />
      <path d="M9.47152 7.60099C9.33708 7.5374 9.20401 7.48653 9.0832 7.41477C8.07811 6.81662 7.13433 6.13627 6.33316 5.27787C5.82357 4.73195 5.39392 4.13063 5.17774 3.40167C4.84482 2.27986 5.21952 1.23707 6.17738 0.552172C7.75246 -0.574185 9.5351 0.117528 10.3158 1.76891C10.7482 2.68362 10.7696 3.63693 10.5665 4.60842C10.3563 5.61396 9.97338 6.5582 9.53919 7.48381C9.52375 7.51696 9.5024 7.54785 9.47152 7.60053V7.60099ZM9.33526 7.35346C9.36161 7.3462 9.38749 7.33847 9.41384 7.33121C9.41384 7.13773 9.41883 6.94425 9.41293 6.75122C9.38477 5.77838 9.38886 4.80281 9.31165 3.8336C9.25669 3.14643 8.9524 2.54056 8.38876 2.10091C8.07901 1.85929 7.75519 1.82159 7.40047 2.02643C6.84456 2.34753 6.7233 2.58779 6.86818 3.21592C6.91541 3.4203 6.96946 3.63421 7.07301 3.81315C7.51447 4.57708 7.9632 5.33692 8.43373 6.08268C8.71032 6.52141 9.03324 6.93062 9.33526 7.35346Z" fill="#171717" fill-opacity="0.9" />
      <path d="M9.98874 7.65778C10.596 5.95098 11.2264 4.30323 12.836 3.28043C13.8424 2.64095 15.1536 2.83761 15.8699 3.77003C16.1832 4.17788 16.2782 4.6416 16.1415 5.14119C15.859 6.1749 15.1686 6.84117 14.2076 7.2522C13.1307 7.71228 11.9957 7.77541 10.8462 7.71955C10.5683 7.70592 10.2908 7.68003 9.98828 7.65823L9.98874 7.65778ZM10.2685 7.44023C10.378 7.41707 10.4284 7.41025 10.4761 7.39526C10.9734 7.23903 11.4748 7.09369 11.9662 6.92156C12.6815 6.67086 13.3787 6.37882 13.9714 5.88786C14.2871 5.62625 14.5164 5.30696 14.6427 4.91138C14.694 4.75105 14.6481 4.64886 14.5223 4.56302C14.2257 4.36091 13.9015 4.30005 13.5781 4.46265C13.2284 4.63842 12.8832 4.83235 12.563 5.05626C11.874 5.53814 11.3572 6.1976 10.809 6.82255C10.6414 7.01376 10.4738 7.20588 10.2685 7.44023Z" fill="#171717" fill-opacity="0.9" />
      <path d="M8.86007 7.66501C8.51853 7.86758 8.13748 7.87711 7.75415 7.85804C6.85806 7.81398 6.09505 7.42929 5.39153 6.9079C4.60217 6.32292 3.81826 5.73022 3.03572 5.13616C2.17687 4.48396 1.23309 4.33363 0.207107 4.63566C0.15851 4.64974 0.10764 4.65564 0 4.67653C0.222546 4.09382 0.461899 3.58106 1.04688 3.34989C1.99611 2.97474 2.88902 3.09963 3.64658 3.78544C4.4473 4.5103 5.22167 5.26651 5.97333 6.04224C6.70183 6.79436 7.53933 7.3153 8.57848 7.49424C8.67658 7.51105 8.76969 7.55646 8.86507 7.58871C8.86325 7.61414 8.86143 7.63958 8.85962 7.66501H8.86007Z" fill="#171717" fill-opacity="0.9" />
    </svg>

  );
}
