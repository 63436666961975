import React from "react";

export default function AquariusIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.60007 2.28325C9.80007 2.01659 10.2001 2.01659 10.4001 2.28325L11.8001 4.14992C12.4001 4.94992 13.6001 4.94992 14.2001 4.14992L15.525 2.38331C15.7358 2.10234 16.1629 2.12048 16.349 2.41831L18.0761 5.18158C18.2224 5.41575 18.5309 5.48694 18.7651 5.34058C18.9992 5.19423 19.0704 4.88575 18.9241 4.65159L17.197 1.88831C16.6386 0.994826 15.3572 0.940398 14.725 1.78331L13.4001 3.54992C13.2001 3.81659 12.8001 3.81659 12.6001 3.54992L11.2001 1.68325C10.6001 0.883252 9.40007 0.883252 8.80007 1.68325L7.40007 3.54992C7.20007 3.81659 6.80007 3.81659 6.60007 3.54992L5.27511 1.78331C4.64293 0.940398 3.36154 0.994826 2.80311 1.88831L1.07607 4.65159C0.929713 4.88575 1.0009 5.19423 1.23507 5.34058C1.46924 5.48694 1.77771 5.41575 1.92407 5.18158L3.65111 2.41831C3.83725 2.12048 4.26438 2.10234 4.47511 2.38331L5.80007 4.14992C6.40007 4.94992 7.60007 4.94992 8.20007 4.14992L9.60007 2.28325Z" fill="#171717" fill-opacity="0.9" />
      <path d="M9.60007 7.78325C9.80007 7.51658 10.2001 7.51659 10.4001 7.78325L11.8001 9.64992C12.4001 10.4499 13.6001 10.4499 14.2001 9.64992L15.525 7.88331C15.7358 7.60234 16.1629 7.62048 16.349 7.91831L18.0761 10.6816C18.2224 10.9158 18.5309 10.9869 18.7651 10.8406C18.9992 10.6942 19.0704 10.3858 18.9241 10.1516L17.197 7.38831C16.6386 6.49483 15.3572 6.4404 14.725 7.28331L13.4001 9.04992C13.2001 9.31658 12.8001 9.31658 12.6001 9.04992L11.2001 7.18325C10.6001 6.38325 9.40007 6.38325 8.80007 7.18325L7.40007 9.04992C7.20007 9.31658 6.80007 9.31658 6.60007 9.04992L5.27511 7.28331C4.64293 6.4404 3.36154 6.49483 2.80311 7.38831L1.07607 10.1516C0.929713 10.3858 1.0009 10.6942 1.23507 10.8406C1.46924 10.9869 1.77771 10.9158 1.92407 10.6816L3.65111 7.91831C3.83725 7.62048 4.26438 7.60234 4.47511 7.88331L5.80007 9.64992C6.40007 10.4499 7.60007 10.4499 8.20007 9.64992L9.60007 7.78325Z" fill="#171717" fill-opacity="0.9" />
      <path d="M9.60007 2.28325C9.80007 2.01659 10.2001 2.01659 10.4001 2.28325L11.8001 4.14992C12.4001 4.94992 13.6001 4.94992 14.2001 4.14992L15.525 2.38331C15.7358 2.10234 16.1629 2.12048 16.349 2.41831L18.0761 5.18158C18.2224 5.41575 18.5309 5.48694 18.7651 5.34058C18.9992 5.19423 19.0704 4.88575 18.9241 4.65159L17.197 1.88831C16.6386 0.994826 15.3572 0.940398 14.725 1.78331L13.4001 3.54992C13.2001 3.81659 12.8001 3.81659 12.6001 3.54992L11.2001 1.68325C10.6001 0.883252 9.40007 0.883252 8.80007 1.68325L7.40007 3.54992C7.20007 3.81659 6.80007 3.81659 6.60007 3.54992L5.27511 1.78331C4.64293 0.940398 3.36154 0.994826 2.80311 1.88831L1.07607 4.65159C0.929713 4.88575 1.0009 5.19423 1.23507 5.34058C1.46924 5.48694 1.77771 5.41575 1.92407 5.18158L3.65111 2.41831C3.83725 2.12048 4.26438 2.10234 4.47511 2.38331L5.80007 4.14992C6.40007 4.94992 7.60007 4.94992 8.20007 4.14992L9.60007 2.28325Z" stroke="#171717" stroke-opacity="0.9" stroke-linecap="round" />
      <path d="M9.60007 7.78325C9.80007 7.51658 10.2001 7.51659 10.4001 7.78325L11.8001 9.64992C12.4001 10.4499 13.6001 10.4499 14.2001 9.64992L15.525 7.88331C15.7358 7.60234 16.1629 7.62048 16.349 7.91831L18.0761 10.6816C18.2224 10.9158 18.5309 10.9869 18.7651 10.8406C18.9992 10.6942 19.0704 10.3858 18.9241 10.1516L17.197 7.38831C16.6386 6.49483 15.3572 6.4404 14.725 7.28331L13.4001 9.04992C13.2001 9.31658 12.8001 9.31658 12.6001 9.04992L11.2001 7.18325C10.6001 6.38325 9.40007 6.38325 8.80007 7.18325L7.40007 9.04992C7.20007 9.31658 6.80007 9.31658 6.60007 9.04992L5.27511 7.28331C4.64293 6.4404 3.36154 6.49483 2.80311 7.38831L1.07607 10.1516C0.929713 10.3858 1.0009 10.6942 1.23507 10.8406C1.46924 10.9869 1.77771 10.9158 1.92407 10.6816L3.65111 7.91831C3.83725 7.62048 4.26438 7.60234 4.47511 7.88331L5.80007 9.64992C6.40007 10.4499 7.60007 10.4499 8.20007 9.64992L9.60007 7.78325Z" stroke="#171717" stroke-opacity="0.9" stroke-linecap="round" />
    </svg>

  );
}