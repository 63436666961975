import { Button, Drawer, Input, Progress, Select, Tag } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EventCreator from "./(components)/EventCreator/EventCreator";
import styles from "./Events.module.css";
import Header from "../../components/header/Header";
import { Content } from "antd/es/layout/layout";
import { useCurrentUserContext } from "../../store";
import BackButton from "../../components/buttons/BackButton/BackButton";
import { useMediaQuery } from "react-responsive";

export default function Events() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false)
  const { currentUser, loadUser } = useCurrentUserContext();
  const events = currentUser.events.items || [];

  const [currentEventType, setCurrentEventType] = useState<any>(null);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const eventTypes = [
    {
      value: "BIRTHDAY",
      label: "Birthday",
      fields: [],
      color: "#CBC9FF",
      strongColor: "#7C78FF",
    },
    {
      value: "ANNIVERSARY",
      label: "Anniversary",
      fields: [],
      color: "#F0E1E1",
      strongColor: "#D73120",
    },
    {
      value: "WORKIVERSARY",
      label: "Workiversary",
      fields: ["Company_Name"],
      color: "#E7EFFF",
      strongColor: "#004DFF",
    },
    {
      value: "REMEMBRANCE_DAY",
      label: "Remembrance Day",
      fields: [
        "Event_Subject_First_Name",
        "Event_Subject_Last_Name",
        "Event_Subject_Relationship",
      ],
      color: "#F5F5F4",

      strongColor: "#8C8B82",
    },
    {
      value: "SOBERVERSARY",
      label: "Soberversary",
      fields: [],
      color: "#FBF3DF",
      strongColor: "#D8A018",
    },
    {
      value: "OUTVERSARY",
      label: "Outversary",
      fields: [],
      color: "#F7F7C4",
      strongColor: "#004DFF",
    },
    {
      value: "REBIRTHDAY",
      label: "Rebirthday",
      fields: [],
      color: "#DAEDF7",
      strongColor: "#FF66AE",
    },
    {
      value: "HEALTHVERSARY",
      label: "Healthversary",
      fields: ["Description"],
      color: "#D4F8D3",
      strongColor: "#00BC4A",
    },
    {
      value: "CHILD_BIRTHDAY",
      label: "Child's Birthday",
      fields: [
        "Event_Subject_Child_First_Name",
        "Event_Subject_Child_Relationship",
      ],
      color: "#FFF0BB",
      strongColor: "#CF9B17",
    },
    {
      value: "PARENT_ADOPTIONVERSARY",
      label: "Parent Adoptionversary",
      fields: ["Event_Subject_Child_Relationship", "Event_Subject_Child_Name"],
      color: "#F7E9DF",
      strongColor: "#FF8F4C",
    },
    {
      value: "CHILD_ADOPTIONVERSARY",
      label: "Child Adoptionversary",
      fields: [],
      color: "#FFD1AF",
      strongColor: "#FF8F4C",
    },
    {
      value: "CITIZENSHIPVERSARY",
      label: "Citizenshipversary",
      fields: ["Country"],
      color: "#E5CDE0",
      strongColor: "#A62F8B",
    },
    {
      value: "ARRIVAL_DAY",
      label: "Arrival Day",
      fields: ["Country"],
      color: "#DDE5F6",
      strongColor: "#004DFF",
    },

    {
      value: "PET_BIRTHDAY",
      label: "Pet's Birthday",
      fields: ["Pet_Name", "Pet_Type"],
      color: "#F7E0C4",
      strongColor: "#FF8B00",
    },

    {
      value: "FOUNDER_DAY",
      label: "Founder's Day",
      fields: ["Company_Name"],
      color: "#CBD5E1",
      strongColor: "#23548F",
    },
  ];

  return (
    <div className={styles.main}>
      <Header avatar />
      <Content className={styles.content}>
        <div className={styles.contentWrap}>
          <div className={styles.backButtonWrap}>
            <BackButton />
          </div>
          <h4 className={styles.header}>
            {isDesktopOrLaptop ?
              <>
                Add your <span style={{ textDecoration: 'underline' }}>own</span>{' '}annual dates
              </> :
              <>
                Add my <span style={{ textDecoration: 'underline' }}>own</span>{' '}dates
              </>}
          </h4>
          <div className={styles.text}>
            {isDesktopOrLaptop
              ?
              <>
                To add a new date, click on the date name, enter your information, press save, and repeat.<br />
                You can add as many of your own dates as you’d like, including multiples of the same type.
              </> :
              <>
                To add a date, click on the date name,<br /> enter your information, press save, and repeat.
              </>
            }
          </div>
          <div className={styles.events}>
            {eventTypes.map(({ value, label, color, strongColor }, index) => {
              const buttonStyle = {
                color: currentEventType === value ? strongColor : "black",
                backgroundColor: color,
                border:
                  currentEventType === value ? `2px solid ${strongColor}` : "",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "4px",
                // width: isDesktopOrLaptop ? "auto" : "100%",
              };

              return (
                <>
                  {isDesktopOrLaptop && index !== 0 && index % 5 === 0 && (
                    <div className={styles.lineBreak} />
                  )}
                  <Button
                    className={styles.eventButton}
                    style={buttonStyle}
                    block={!isDesktopOrLaptop}
                    onClick={() => {
                      if(value === "BIRTHDAY") {
                        return
                      }
                      setCurrentEventType(null); // used to rerender the event creator so the fields are reset
                      setTimeout(() => setCurrentEventType(value), 10);
                      if(!isDesktopOrLaptop) {
                        setOpenDrawer(true);
                      }
                    }}
                  >
                    {label}{" "}
                    {events.filter((event: any) => event.type === value)
                      .length > 0 && (
                        <Tag
                          color={color}
                          style={{
                            color: strongColor,
                            border: `1px solid ${strongColor}`,
                            borderRadius: 12,
                            padding: "0 12px",
                            backgroundColor: strongColor + "30",
                            fontFamily: "var(--secondary-font-family)",
                            fontSize: "15px",
                            fontWeight: 800,
                            marginRight: 0,
                          }}
                        >
                          {
                            events.filter((event: any) => event.type === value)
                              .length
                          }
                        </Tag>
                      )}
                  </Button>
                </>
              );
            })}
          </div>
          {/* {events
            .filter((event: any) => event.type === currentEventType)
            .map((event) => {
              return (
                <div className={styles.eventCreatorWrap}>
                  <EventCreator
                    defaultEvent={event}
                    eventType={currentEventType}
                    onEventSubmit={async () => {
                      await loadUser();
                      setCurrentEventType(null);
                      // setEvents((prev: any) => [...prev, eventObj]);
                    }}
                  />
                </div>
              );
            })} */}

          {currentEventType && (
            <div className={styles.eventCreatorWrap}>
              {isDesktopOrLaptop ?
                <EventCreator
                  eventType={currentEventType}
                  onEventSubmit={async () => {
                    await loadUser();
                    setCurrentEventType(null);
                    // setEvents((prev: any) => [...prev, eventObj]);
                  }}
                />
                : <Drawer
                  open={openDrawer}
                  placement="bottom"
                  height="initial"
                  onClose={() => setOpenDrawer(false)}
                  afterOpenChange={() => {
                    document.getElementById("firstInput")?.focus()
                  }}
                  className={styles.drawerTitle}
                  closeIcon={
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_813_23335)"><path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black" /></g>
                      <defs>
                        <clipPath id="clip0_813_23335">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                >
                  <EventCreator
                    eventType={currentEventType}
                    onEventSubmit={async () => {
                      await loadUser();
                      setCurrentEventType(null);
                      // setEvents((prev: any) => [...prev, eventObj]);
                    }}
                  />
                </Drawer>


              }
            </div>
          )}
        </div>
      </Content>
    </div>
  );
}
