import React, { useState } from "react";

import styles from "./DeclineConnectionButton.module.css";
import { Button } from "antd";
import { declineConnection } from "../../../api/api";
import { useCurrentUserContext } from '../../../store';

export default function DeclineConnectionButton({ connection, onSuccess }) {
  const { currentUser, loadUser } = useCurrentUserContext();

  const [loading, setLoading] = useState(false);

  const handleDeclineConnection = async () => {
    setLoading(true);
    try {
      await declineConnection(connection);
      if(currentUser) {
        loadUser()
      }
      onSuccess && onSuccess();
    } catch(e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      className={styles.declineButton}
      onClick={() => handleDeclineConnection()}
      loading={loading}
    >
      Decline
    </Button>
  );
}
