import React from "react";

import styles from "./TabPanel.module.css";
import { Badge, Tag } from "antd";
import { useMediaQuery } from "react-responsive";

export default function TabPanel({ options, value, onChange }) {
  const selectedBackgroundColor = "var(--celi-purple)";
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div className={styles.tabs}>
      {options.map((option, index) => (
        <div
          className={styles.tab}
          key={index}
          style={{
            backgroundColor:
              value === option.value
                ? selectedBackgroundColor
                : "rgba(237, 231, 251, 0.30)",
          }}
          onClick={() => onChange(option.value)}
        >
          <div className={styles.icon}>{option.icon}</div>
          <div className={styles.label}>{option.label}</div>
          {isDesktopOrLaptop && option.count > 0 && (
            <Tag className={styles.tag} color="#7C78FF66">
              {option.count}
            </Tag>
          )}
        </div>
      ))}
    </div>
  );
}
