import { Avatar } from "antd";
import { Storage } from "aws-amplify";
import React, { useEffect } from "react";
import { useCurrentUserContext } from '../../store';
import styles from "./UserAvatar.module.css"

export default function UserAvatar({ imageUrl, user = null }) {
  const [image, setImage] = React.useState(null);
  const { currentUser: { firstName, lastName } } = useCurrentUserContext();

  useEffect(() => {
    if(imageUrl) {
      Storage.get(imageUrl).then((image) => setImage(image));
    }
  }, [imageUrl]);

  return (
    <div>
      {image ? <Avatar
        size={102}
        src={image || ""}
      /> : <div className={styles.avatar} style={{ backgroundColor: '#BCBAED' }}>
        {
          user ? <>{user.firstName?.substr(0, 1)?.toUpperCase()}{user.lastName?.substr(0, 1)?.toUpperCase()}</> : <>{firstName?.substr(0, 1)?.toUpperCase()}{lastName?.substr(0, 1)?.toUpperCase()}</>
        }
      </div>}
    </div>
  );
}
