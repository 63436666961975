import React, { useEffect, useState } from "react";

import DailyEvents from "../DailyEvents/DailyEvents";
import getTodayMonthDay from "../../../../utils/getTodayMonthDay";

export default function DashboardDaily({ events }) {
  const today = getTodayMonthDay();

  return (
    <div style={{ width: "100%" }}>
      <DailyEvents date={today} events={events} />
    </div>
  );
}
