import React, { useState } from "react";

import styles from "./RemoveConnectionButton.module.css";
import { Button, Modal } from "antd";
import { removeConnectionAPI } from "../../../api/api";

export default function RemoveConnectionButton({ connection, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const removeConnection = async () => {
    setLoading(true);
    try {
      const resp = await removeConnectionAPI(connection);
      onSuccess && onSuccess(resp);
    } catch(e) {
    } finally {
      setLoading(false);
    }
  };

  return (<>
    <Button
      className={styles.removeButton}
      onClick={/* removeConnection */ () => setOpenModal(true)}
      loading={loading}
    >
      Remove
    </Button>
    <Modal
      open={openModal}
      footer={<></>}
      centered
      onCancel={() => {
        setOpenModal(false)
        // setIsEditing(false)
      }}>
      <div className={styles.modalHeader}>Are you sure?</div>
      <p className={styles.modalText}>By removing this connection, you’ll no longer know when they have a date to celi.</p>

      <div className={styles.modalButtonsWrap}>
        <Button size="large" onClick={removeConnection}>Remove</Button>
        <Button
          className={styles.keepButton}
          type="primary"
          size="large"
          onClick={() => setOpenModal(false)}
        >
          Keep Connection
        </Button>
      </div>
    </Modal>
  </>
  );
}
