import { useContext, createContext } from "react";

export interface CurrentUserContextType {
  currentUser: any;
  currentUserImage: any;
  setCurrentUser: any;
  loadUser: any;
  loadUserImage: any;
}

export const CurrentUserContext = createContext<CurrentUserContextType>({
  currentUser: null,
  currentUserImage: null,
  setCurrentUser: () => {},
  loadUser: () => {},
  loadUserImage: () => {},
});

export const useCurrentUserContext = () => useContext(CurrentUserContext);

export interface AuthUserContextType {
  authUser: any;
  setAuthUser: any;
  signIn: any;
  signOut: any;
}

export const AuthUserContext = createContext<AuthUserContextType>({
  authUser: null,
  setAuthUser: () => {},
  signIn: () => {},
  signOut: () => {},
});

export const useAuthUserContext = () => useContext(AuthUserContext);

export interface PreapprovedConnectionLinkContextType {
  preapprovedConnectionLink: any;
  setPreapprovedConnectionLink: any;
}

export const PreapprovedConnectionLinkContext =
  createContext<PreapprovedConnectionLinkContextType>({
    preapprovedConnectionLink: null,
    setPreapprovedConnectionLink: () => {},
  });

export const usePreapprovedConnectionLinkContext = () =>
  useContext(PreapprovedConnectionLinkContext);
