import React, { useEffect, useState } from "react";

import styles from "./Dashboard.module.css";
import {
  CurrentUserContextType,
  useCurrentUserContext,
} from "../../store";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/header/Header";
import { Content } from "antd/es/layout/layout";
import {
  Badge,
  Button,
  Input,
  Tooltip,
  notification,
} from "antd";
import DashboardDaily from "./(components)/DashboardDaily/DashboardDaily";
import DashboardUpcoming from "./(components)/DashboardUpcoming/DashboardUpcoming";
import DashboardConnect from "./(components)/DashboardConnect/DashboardConnect";
import TabPanel from "../../components/TabPanel/TabPanel";
import { useMediaQuery } from "react-responsive";
import SwitchSelector from "react-switch-selector";
import UserSearchIcon from "../../components/icons/UserAddIcon/UserAddIcon";
import HelpIcon from "../../components/icons/HelpIcon/HelpIcon";
import { copiedPacLink, getDailyEventsAPI, getUpcomingEventsAPI } from "../../api/api";
const isMobileOrTablet = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
export default function Dashboard() {
  const { currentUser }: CurrentUserContextType = useCurrentUserContext();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [dailyEvents, setDailyEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [params, setSearchParams] = useSearchParams();

  const requests = currentUser.connectors.items
    ? currentUser.connectors?.items.filter(
      (connection) => connection.status === "REQUESTED"
    )
    : [];

  const getDailyEvents = async () => {
    try {
      const events = await getDailyEventsAPI();
      setDailyEvents(events);
    } catch(e) {}
  };

  const getUpcomingEvents = async () => {
    try {
      const events = await getUpcomingEventsAPI();
      setUpcomingEvents(formatEventsByMonthDay(events, getCurrentDate()));
    } catch(e) {}
  };

  useEffect(() => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 0);
  }, [])

  useEffect(() => {
    getDailyEvents();
    getUpcomingEvents();
  }, [currentUser]);

  const getCurrentDate = () => {
    const currentDate = new Date();

    // Get day and month with leading zeros if necessary
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1

    // Combine day and month in DD-MM format
    const formattedDate = `${month}-${day}`;

    return formattedDate;
  }

  const formatEventsByMonthDay = (events: Array<any>, monthDayToDelete: string) => {
    events.sort((a: any, b: any) => {
      const dateA = new Date(`2022-${a.monthDay}`) as any;
      const dateB = new Date(`2022-${b.monthDay}`) as any;
      return dateA - dateB;
    });

    const futureEvent = new Date("2024-" + monthDayToDelete + "T00:00:00Z");

    const futureEvents = events
      .map(event => new Date("2024-" + event.monthDay + "T00:00:00Z"))
      .filter(event => event > futureEvent);

    futureEvents.sort((a: any, b: any) => a - b);

    const futureDate = futureEvents[0].toISOString().split('T')[0].slice(5).replace("/", "-");
    const indexToCut = events.findIndex(event => event.monthDay === futureDate)

    let cutEvents = events.splice(0, indexToCut)
    if(!cutEvents.find(event => event.monthDay === "01-01") && cutEvents.length > 0) {
      cutEvents = [{ monthDay: "01-01" }, ...cutEvents]
    }
    events = events.concat(cutEvents);

    const indexToDelete = events.findIndex(
      (event: any) => event.monthDay === monthDayToDelete
    );

    if(indexToDelete !== -1) {
      delete events[indexToDelete];
      const updatedJsonData = events.filter(n => true);
      return updatedJsonData;
    } else {
      return events.filter(n => true);
    }
  }

  const [tab, setTab] = useState(params.get("tab") || "dates");

  const pacl = `https://onboarding.timetoceli.com?ref=${currentUser.approvedConnectionLinkId}`;

  const onCopy = () => {
    if(!isMobileOrTablet()) {
      navigator.clipboard.writeText(pacl);
      notification.success({
        message: "PAC Link Copied!",
        description: "When someone creates a profile using this link, Celi auto-connects you. Like magic, their dates display in your dash, and you receive reminders whenever they have a date to celi.",
        placement: "top",
        duration: 0,
      });
      copiedPacLink(currentUser.id)
    } else {
      const shareData = {
        title: "Celi",
        text: "I just started using Celi to better remember birthdays and other important dates. Yours are important to me, will you add them to my Celi?",
        url: pacl,
      };


      navigator.share(shareData).then(response => null).catch(err => {
        console.log({ err });
      })
    }

  };

  const handleTabChange = (newTab) => {
    params.delete("tab");
    setSearchParams(params);
    setTab(newTab);
  };

  const options = [
    {
      value: "dates",
      label: "Dates",
      selectedBackgroundColor: "#CBC9FF",
      selectedFontColor: "rgba(23, 23, 23, 0.90)",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="currentColor"
        >
          <g clip-path="url(#clip0_1025_8458)">
            <path
              d="M20.8672 3.00006H19.8672V1.00006H17.8672V3.00006H7.86719V1.00006H5.86719V3.00006H4.86719C3.76719 3.00006 2.86719 3.90006 2.86719 5.00006V21.0001C2.86719 22.1001 3.76719 23.0001 4.86719 23.0001H20.8672C21.9672 23.0001 22.8672 22.1001 22.8672 21.0001V5.00006C22.8672 3.90006 21.9672 3.00006 20.8672 3.00006ZM20.8672 21.0001H4.86719V8.00006H20.8672V21.0001Z"
              fill="#333333"
            />
          </g>
          <defs>
            <clipPath id="clip0_1025_8458">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.867188 6.10352e-05)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      value: "connect",
      label: "Connect",
      selectedBackgroundColor: "#CBC9FF",
      selectedFontColor: "rgba(23, 23, 23, 0.90)",
      icon: <UserSearchIcon />,
      count: requests?.length,
    },
  ];

  const PaclInput = () => {
    return (
      <div className={styles.paclWrapper}>
        <Tooltip placement="bottom" title={
          isDesktopOrLaptop ?
            <>
              <b>Share this link with anyone you’d like to invite to Celi.</b>
              <p>
                When they use this link to create and verify their new account,<br />
                we’ll automatically connect your profiles. This is the easiest way<br />
                to add friends to your Celi and never miss their moments again.
              </p>
            </> :
            <>
              <b>Share this link with anyone you’d like to invite to Celi.</b>
              <p>
                When they use this link to create and verify <br />their new account,
                we’ll automatically <br />connect your profiles. This is the easiest <br />way
                to add friends to your Celi and never <br />miss their moments again.
              </p>
            </>
        }
        >
          <div style={{
            position: "absolute",
            top: "-8px",
            left: "-8px",
            zIndex: 1
          }}>
            <HelpIcon />
          </div>
        </Tooltip>
        <div className={styles.paclInputWrap}>
          <Input
            size="large"
            className={styles.paclInput}
            disabled
            suffix={
              <Button className={styles.copyButton} size="small">
                {!isMobileOrTablet() ? "Copy" : "Share"}
              </Button>
            }
            value={pacl}
          />
          <div className={styles.paclClick} onClick={onCopy}></div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const newTab = params.get("tab");
    if(newTab) {
      setTab(newTab);
    }
  }, [params]);

  return (
    <div className={styles.main}>
      <Header avatar />
      <Content className={styles.content}>
        <div className={styles.contentWrap}>
          {isDesktopOrLaptop && (
            <div className={styles.top}>
              <div className={styles.topText}>
                <div className={styles.header}>
                  Welcome back, {currentUser.firstName} 🎈
                </div>
              </div>
              <PaclInput />


            </div>
          )}

          {isTabletOrMobile ? (
            <div className={styles.switchSelectorWrap}>
              <SwitchSelector
                onChange={handleTabChange}
                options={options.map((option) => {
                  return {
                    ...option,
                    label: (
                      <Badge
                        color={"#7C78FF66"}
                        dot={option.count > 0}
                        style={{ borderColor: "transparent" }}
                      >
                        {option.icon}
                      </Badge>
                    ),
                  };
                })}
                initialSelectedIndex={0}
                forcedSelectedIndex={options.findIndex(
                  (option) => option.value === tab
                )}
                backgroundColor={"rgba(237, 231, 251, 0.30)"}
                fontColor={"rgba(23, 23, 23, 0.90)"}
                optionBorderRadius={30}
                wrapperBorderRadius={30}
              />
            </div>
          ) : (
            <div className={styles.tabPanelWrap}>
              <TabPanel
                value={tab}
                options={options}
                onChange={handleTabChange}
              />
            </div>
          )}
          <div className={styles.tabDisplay}>
            {tab === "dates" && (
              <div className={styles.datesWrapper}>
                <p className={styles.headerDates} style={{
                  margin: "0 0 15px 0"
                }}>Today</p>
                <DashboardDaily events={dailyEvents} />
                <p className={styles.headerDates} style={{
                  margin: "50px 0 15px 0"
                }}>Upcoming</p>
                <DashboardUpcoming events={upcomingEvents} />
              </div>
            )}
            {tab === "connect" && <DashboardConnect />}
          </div>
          {isTabletOrMobile && (
            <div className={styles.mobileInvite}>
              <div className={styles.header}>Invite 🎈</div>
              <PaclInput />
            </div>
          )}
        </div>
      </Content>
    </div>
  );
}
