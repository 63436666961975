import React from "react";

export default function LibraIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 19 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 12.75C13 12.4739 13.2239 12.25 13.5 12.25H13.4687C14.7144 11.1506 15.5 9.54204 15.5 7.75C15.5 4.43629 12.8137 1.75 9.5 1.75C6.18629 1.75 3.5 4.43629 3.5 7.75C3.5 9.54204 4.28563 11.1506 5.53127 12.25H5.5C5.77614 12.25 6 12.4739 6 12.75C6 13.0261 5.77614 13.25 5.5 13.25H1.5C1.22386 13.25 1 13.0261 1 12.75C1 12.4739 1.22386 12.25 1.5 12.25H4.13789C3.11569 11.0332 2.5 9.46352 2.5 7.75C2.5 3.884 5.634 0.75 9.5 0.75C13.366 0.75 16.5 3.884 16.5 7.75C16.5 9.46352 15.8843 11.0332 14.8621 12.25H17.5C17.7761 12.25 18 12.4739 18 12.75C18 13.0261 17.7761 13.25 17.5 13.25H13.5C13.2239 13.25 13 13.0261 13 12.75Z" fill="#171717" fill-opacity="0.9" />
      <path d="M1.5 16.25C1.22386 16.25 1 16.4739 1 16.75C1 17.0261 1.22386 17.25 1.5 17.25H17.5C17.7761 17.25 18 17.0261 18 16.75C18 16.4739 17.7761 16.25 17.5 16.25H1.5Z" fill="#171717" fill-opacity="0.9" />
      <path d="M13 12.75C13 12.4739 13.2239 12.25 13.5 12.25H13.4687C14.7144 11.1506 15.5 9.54204 15.5 7.75C15.5 4.43629 12.8137 1.75 9.5 1.75C6.18629 1.75 3.5 4.43629 3.5 7.75C3.5 9.54204 4.28563 11.1506 5.53127 12.25H5.5C5.77614 12.25 6 12.4739 6 12.75C6 13.0261 5.77614 13.25 5.5 13.25H1.5C1.22386 13.25 1 13.0261 1 12.75C1 12.4739 1.22386 12.25 1.5 12.25H4.13789C3.11569 11.0332 2.5 9.46352 2.5 7.75C2.5 3.884 5.634 0.75 9.5 0.75C13.366 0.75 16.5 3.884 16.5 7.75C16.5 9.46352 15.8843 11.0332 14.8621 12.25H17.5C17.7761 12.25 18 12.4739 18 12.75C18 13.0261 17.7761 13.25 17.5 13.25H13.5C13.2239 13.25 13 13.0261 13 12.75Z" stroke="#171717" stroke-opacity="0.9" />
      <path d="M1.5 16.25C1.22386 16.25 1 16.4739 1 16.75C1 17.0261 1.22386 17.25 1.5 17.25H17.5C17.7761 17.25 18 17.0261 18 16.75C18 16.4739 17.7761 16.25 17.5 16.25H1.5Z" stroke="#171717" stroke-opacity="0.9" />
    </svg>
  );
}