import React from "react";

import styles from "./EventTag.module.css";

interface EventTagProps {
  text: string;
  backgroundColor: string;
  fontColor?: string;
  borderColor?: string;
  width?: string;
}

export default function EventTag({
  text,
  backgroundColor,
  fontColor,
  borderColor,
  width
}: EventTagProps) {
  return (
    <div
      className={styles.eventTag}
      style={{
        backgroundColor: backgroundColor,
        color: fontColor || "black",
        border: borderColor ? `1px solid ${borderColor}` : "none",
        width: width,
      }}
    >
      {text}
    </div>
  );
}
