export const eventTypes = [
  {
    value: "BIRTHDAY",
    label: "Birthday",
    fields: [],
    color: "#EDE7FB",
    strongColor: "#7C78FF",
  },
  {
    value: "ANNIVERSARY",
    label: "Anniversary",
    fields: [],
    color: "#F0E1E1",
    strongColor: "#D73120",
  },
  {
    value: "WORKIVERSARY",
    label: "Workiversary",
    fields: ["Company_Name"],
    color: "#E7EFFF",
    strongColor: "#004DFF",
  },
  {
    value: "REMEMBRANCE_DAY",
    label: "Remembrance Day",
    fields: [
      "Event_Subject_First_Name",
      "Event_Subject_Last_Name",
      "Event_Subject_Relationship",
    ],
    color: "#F5F5F4",

    strongColor: "#8C8B82",
  },
  {
    value: "SOBERVERSARY",
    label: "Soberversary",
    fields: [],
    color: "#FBF3DF",
    strongColor: "#D8A018",
  },
  {
    value: "OUTVERSARY",
    label: "Outversary",
    fields: [],
    color: "#F7F7C4",
    strongColor: "#004DFF",
  },
  {
    value: "REBIRTHDAY",
    label: "Rebirthday",
    fields: [],
    color: "#DAEDF7",
    strongColor: "#FF66AE",
  },
  {
    value: "HEALTHVERSARY",
    label: "Healthversary",
    fields: ["Description"],
    color: "#D4F8D3",
    strongColor: "#00BC4A",
  },
  {
    value: "CHILD_BIRTHDAY",
    label: "Child's Birthday",
    fields: [
      "Event_Subject_Child_First_Name",
      "Event_Subject_Child_Relationship",
    ],
    color: "#FFF0BB",
    strongColor: "#CF9B17",
  },
  {
    value: "CHILD_ADOPTIONVERSARY",
    label: "Child Adoptionversary",
    fields: [],
    color: "#F7E9DF",
    strongColor: "#FF8F4C",
  },
  {
    value: "PARENT_ADOPTIONVERSARY",
    label: "Parent Adoptionversary",
    fields: ["Event_Subject_Child_Name"],
    color: "#F7E9DF",
    strongColor: "#FF8F4C",
  },
  {
    value: "CITIZENSHIPVERSARY",
    label: "Citizenshipversary",
    fields: ["Country"],
    color: "#E5CDE0",
    strongColor: "#A62F8B",
  },
  {
    value: "ARRIVAL_DAY",
    label: "Arrival Day",
    fields: ["Country"],
    color: "#DDE5F6",
    strongColor: "#004DFF",
  },

  {
    value: "PET_BIRTHDAY",
    label: "Pet's Birthday",
    fields: ["Pet_Name", "Pet_Type"],
    color: "#F7E0C4",
    strongColor: "#FF8B00",
  },

  {
    value: "FOUNDER_DAY",
    label: "Founder's Day",
    fields: ["Company_Name"],
    color: "#CBD5E1",
    strongColor: "#23548F",
  },
];

export const getEventTypeInfo = (eventTypeValue) => {
  const eventType = eventTypes.find((type) => type.value === eventTypeValue);

  if(eventType) {
    return {
      label: eventType.label,
      color: eventType.color,
      strongColor: eventType.strongColor,
    };
  }

  // Return default values or handle the case where the eventTypeValue is not found
  return {
    label: "Unknown",
    color: "#000000",
    strongColor: "#000000",
  };
};
