import { Button, Image, Input } from "antd";
import React, { useState } from "react";
import { useAuthUserContext } from "../../store";
import { redirect, useNavigate } from "react-router-dom";
import SignInForm from "./(components)/SignInForm/SignInForm";
import { Header as AntHeader, Content } from "antd/es/layout/layout";
import Link from "antd/es/typography/Link";
import Header from "../../components/header/Header";

import styles from "./SignIn.module.css";
import LandingCopy from "../../components/LandingCopy/LandingCopy";
import { Auth } from "aws-amplify";
import { useMediaQuery } from "react-responsive";

export default function SignIn() {
  const { signIn } = useAuthUserContext();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <div className={styles.main}>
      <Header signUp />

      <Content className={styles.content}>
        <div className={styles.contentWrap}>
          {isDesktopOrLaptop && (
            <img src='celi_demo.gif' alt='demo image' style={{
              height: "80%",
              aspectRatio: "0.79 / 1"
            }} />
          )}
          <div className={styles.formContainer}>
            <SignInForm />
          </div>
        </div>
      </Content>
    </div>
  );
}



{/* <div className={styles.contentWrap}>
          {isDesktopOrLaptop && (
            <div className={styles.leftColumn}>
              <LandingCopy />
            </div>
          )}
          <div className={styles.rightColumn}>
            <div className={styles.formContainer}>
              <SignInForm />
            </div>
          </div>
        </div> */}