import React, { useEffect, useState } from "react";
import "./App.css";
import "./globals.css";
import { API, Auth, Storage } from "aws-amplify";
import { AuthUserContext, CurrentUserContext } from "./store";
import { RouterProvider } from "react-router-dom";
import { AppRouter } from "./routes/AppRouter";
import { ConfigProvider, Image } from "antd";

export interface SignInParameters {
  username: string;
  password: string;
}
const redirectMap = {
  "https://app-dev.timetoceli.com": "https://dev-onboarding.timetoceli.com",
  "https://app-test.timetoceli.com": "https://test-onboarding.timetoceli.com",
  "https://app.timetoceli.com": "https://onboarding.timetoceli.com",
  "http://localhost:3001": "http://localhost:3000",
}
const App = () => {
  const [authUser, setAuthUser] = useState(null);

  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserImage, setCurrentUserImage] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadUser = async () => {
    if(!currentUser) {
      setLoading(true);
    }

    try {
      const apiName = "api";
      const path = "/api/users/currentUser";
      const init = {};

      const res = await API.get(apiName, path, init);
      const user = await res.data;
      if(res.data.emailVerified === false || res.data.phoneVerified === false) {
        document.location.replace(`${redirectMap[window.location.origin]}/#/verify`)
      }
      const birthday = res.data.events.items.find(event => event.type === 'BIRTHDAY')
      if(!res.data.firstName || !res.data.lastName || !res.data.pronoun || !birthday) {
        document.location.replace(`${redirectMap[window.location.origin]}/#/identity`)
      }
      setCurrentUser({
        ...user,
        links: JSON.parse(user.links),
        hideContactInfo: JSON.parse(user.hideContactInfo),
        connections: {
          items: user?.connections.items.sort((connectionA, connectionB) => {
            const userA = connectionA.receivingUser
            const userB = connectionB.receivingUser
            const nameA = `${userA.firstName} ${userA.lastName}`
            const nameB = `${userB.firstName} ${userB.lastName}`
            if(nameA < nameB) return -1
            if(nameA > nameB) return 1
            return 0;
          })
        },
        connectors: {
          items: user?.connectors.items.sort((connectorA, connectorB) => {
            const userA = connectorA.sendingUser
            const userB = connectorB.sendingUser
            const nameA = `${userA.firstName} ${userA.lastName}`
            const nameB = `${userB.firstName} ${userB.lastName}`
            if(nameA < nameB) return -1
            if(nameA > nameB) return 1
            return 0;
          })
        }

      });

      loadUserImage(user);
    } catch(error) {
    } finally {
      setLoading(false);
    }
  };

  const loadUserImage = async (user = currentUser) => {
    try {
      if(!user.avatarUrl) {
        setCurrentUserImage("celi-logo-transp 2.png");
      } else {
        const image = await Storage.get(user.avatarUrl);
        setCurrentUserImage(image);
      }
    } catch(error) {
    } finally {
    }
  };

  const getAuthentication = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch(e) {
      return null;
    }
  };

  async function signIn({ username, password }: SignInParameters) {
    try {
      const user = await Auth.signIn(username, password);
      setAuthUser(user);
      await loadUser();
      return true;
    } catch(error) {
      return { error: error };
    } finally {
      // window.location.reload();
    }
  }

  async function signOut() {
    setLoading(true);
    try {
      await Auth.signOut();
      setAuthUser(null);
      setCurrentUser(null);
    } catch(error) {
    } finally {
      window.location.href = "/";
      setLoading(false);
    }
  }

  useEffect(() => {
    getAuthentication().then((resp) => {
      resp && setAuthUser(resp);
      resp && loadUser();
      !resp && setLoading(false);
    });
  }, []);

  if(loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div>
          <Image
            preview={false}
            src={"celi-logo-transp 2.png"}
            style={{ height: "200px", width: "200px" }}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="app">
        <ConfigProvider
          theme={{
            // token: {
            //   fontSizeHeading3: 36,
            // },
            components: {
              //   Table: {
              //     headerBg: 'black',
              //     headerColor: 'white',
              //     headerSplitColor: 'none',
              //   },
              Button: {
                colorPrimary: "#CBC9FF",
                primaryColor: "#0F0000",
                primaryShadow: "none",
                defaultShadow: "none",
                colorTextDisabled: "rgba(23, 23, 23, 0.25)",
                colorBgContainerDisabled: "rgba(23, 23, 23, 0.10)",
                colorBorder: "#d9d9d9",
                paddingInlineLG: 24,
                fontWeight: 500,
                fontFamily: "Poppins",
                borderRadiusLG: 12,
              },
              Progress: {
                defaultColor: "#7C78FF",
                remainingColor: "#1717171A",
                colorSuccess: "#7C78FF",
              },
              Select: {
                borderRadiusLG: 12,
              },
              Input: {
                borderRadiusLG: 12,
                colorBorder: "#1717171A",
                activeBorderColor: "#7C78FF",
                hoverBorderColor: "none"
              },
              Switch: {
                handleSize: 22,
                trackHeight: 26,
                trackMinWidth: 50
              }

              //   Modal: {},
            },
            token: {
              colorPrimary: "#CBC9FF",
            },
          }}
        >
          <AuthUserContext.Provider
            value={{ authUser, setAuthUser, signIn, signOut }}
          >
            <CurrentUserContext.Provider
              value={{
                currentUser,
                currentUserImage,
                setCurrentUser,
                loadUser,
                loadUserImage,
              }}
            >
              <RouterProvider router={AppRouter} />
            </CurrentUserContext.Provider>

            {/* {authUser ? <AuthApp /> : <UnauthApp />} */}
          </AuthUserContext.Provider>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default App;
