import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import ConnectionsRequests from "./ConnectionRequests/ConnectionsRequests";
import SearchResults from "./SearchResults/SearchResults";

import styles from "./DashboardConnect.module.css";
import { Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getUserSearchResults } from "../../../../api/api";
import { useCurrentUserContext } from '../../../../store';

export default function DashboardConnect() {
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>(null);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const { currentUser, loadUser } = useCurrentUserContext();

  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      setTimeoutId(setTimeout(() => func(...args), delay));
    };
  };

  const fetchSearchResults = async () => {
    setLoading(true);
    const results = await getUserSearchResults(search);
    setSearchResults(results);
    setLoading(false);
  };

  useEffect(() => {
    // Debounce the fetchSearchResults function to delay the search
    const debouncedFetch = debounce(fetchSearchResults, 1000); // Adjust the delay as needed

    // Fetch search results only if the search query is not empty
    if(search.trim() !== "") {
      debouncedFetch();
    } else {
      // Clear search results if the search query is empty
      setSearchResults([]);
    }
  }, [search]);

  return (
    <div className={styles.connect}>
      <Input
        prefix={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <g clip-path="url(#clip0_949_13034)">
              <path
                d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
                fill="#333333"
              />
              <path
                d="M10.35 14.01C7.62 13.91 2 15.27 2 18V20H11.54C9.07 17.24 10.31 14.11 10.35 14.01Z"
                fill="#333333"
              />
              <path
                d="M19.43 18.02C19.79 17.43 20 16.74 20 16C20 13.79 18.21 12 16 12C13.79 12 12 13.79 12 16C12 18.21 13.79 20 16 20C16.74 20 17.43 19.78 18.02 19.43L20.59 22L22 20.59L19.43 18.02ZM16 18C14.9 18 14 17.1 14 16C14 14.9 14.9 14 16 14C17.1 14 18 14.9 18 16C18 17.1 17.1 18 16 18Z"
                fill="#333333"
              />
            </g>
            <defs>
              <clipPath id="clip0_949_13034">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
        size="large"
        suffix={loading && <LoadingOutlined style={{ fontSize: 24 }} spin />}
        placeholder="Search by name, number, or username"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        autoFocus={true}
      />
      <div>
        {!search ? (
          <ConnectionsRequests />
        ) : (
          <SearchResults
            results={searchResults ? searchResults : null}
            onAction={async () => {
              await loadUser();
              await fetchSearchResults()
            }}
          />
        )}
      </div>
    </div>
  );
}
