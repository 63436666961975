import { API } from "aws-amplify";
import getTodayMonthDay from "../utils/getTodayMonthDay";

export const createConnection = async (user: any) => {
  try {
    const apiName = "api";
    const path = "/api/connections/currentUser/create";
    const init = {
      body: {
        username: user.username,
      },
    };
    const connection = await API.post(apiName, path, init);
  } catch(e) {
  } finally {
  }
};

export const declineConnection = async (connection: any) => {
  try {
    const apiName = "api";
    const path = "/api/connections/" + connection.id + "/decline";
    const init = {
      body: {
        _version: connection._version,
      },
    };

    const res = await API.post(apiName, path, init);
  } catch(error) {
  } finally {
  }
};

export const acceptConnection = async (connection: any) => {
  try {
    const apiName = "api";
    const path = "/api/connections/" + connection.id + "/accept";
    const init = {
      body: {
        _version: connection._version,
      },
    };

    const res = await API.post(apiName, path, init);
    return res
  } catch(error) {
  } finally {
  }
};

export const copiedPacLink = async (id) => {
  try {
    const apiName = "api";
    const path = "/api/user/copyPacLink";
    const init = {
      body: {
        id: id,
      },
    };

    const res = await API.post(apiName, path, init);
    return res
  } catch(error) {
  } finally {
  }
};

export const getUser = async (username: string, id: string) => {
  try {
    const apiName = "api";
    const path = "/api2/users/username/" + username + "?id=" + id;
    const init = {};

    const res = await API.get(apiName, path, init);
    return res.data;
  } catch(error) {
    console.log(error)
    throw Error(error)
  }
  return null;
};

export const getPublicUser = async (username: string) => {
  try {
    const apiName = "api";
    const path = "/api2/users/public/username/" + username;
    const init = {};

    const res = await API.get(apiName, path, init);
    return res.data;
  } catch(error) {
    console.log(error)
  } finally {
  }
  return null;
};

export const removeConnectionAPI = async (connection: any) => {
  try {
    const apiName = "api";
    const path = "/api/connections/" + connection.id + "/remove";
    const init = {
      body: {
        _version: connection._version,
      },
    };

    const res = await API.post(apiName, path, init);
    return res.data;
  } catch(error) {
  } finally {
  }
  return null;
};

export const getUserSearchResults = async (query: string) => {
  // Add your logic to fetch search results based on the search query
  // For example, you might use a GraphQL or REST API to fetch the results
  try {
    // Simulating an API call with setTimeout (replace with your actual API call)
    const apiName = "api";
    const path = "/api/users?search=" + query;
    const init = {};

    const res = await API.get(apiName, path, init);
    return res.data;
  } catch(error) {
    console.error("Error fetching search results:", error);
  } finally {
  }
};

export const getDailyEventsAPI = async () => {
  const monthDay = getTodayMonthDay();

  try {
    const apiName = "api";
    const path = "/api/users/currentUser/daily-events?monthDay=" + monthDay;
    const init = {};

    const res = await API.get(apiName, path, init);
    return res.data.data;
  } catch(error) {
  } finally {
  }
};

export const getUpcomingEventsAPI = async () => {
  const monthDay = getTodayMonthDay();
  try {
    const apiName = "api";
    const path = "/api/users/currentUser/upcoming-events?monthDay=" + monthDay;
    const init = {};

    const res = await API.get(apiName, path, init);
    return res.data.data;
  } catch(error) {
  } finally {
  }
};
