import React from "react";

export default function WebsiteIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10.5849H4.45334C4.50394 11.9344 4.68793 13.2545 5.05119 14.5612C3.89 14.7993 2.77157 15.0775 1.73002 15.6679C1.599 15.46 1.46405 15.2618 1.34518 15.0547C0.609627 13.7747 0.158095 12.4036 0.0329528 10.9293C0.0298144 10.8925 0.0113766 10.8568 0 10.8203C0 10.7418 0 10.6634 0 10.5849Z" fill="#171717" fill-opacity="0.9" />
      <path d="M20.0873 10.8203C20.05 11.0706 20.0127 11.3209 19.9751 11.5712C19.7907 12.8065 19.3725 13.9587 18.7527 15.0406C18.7025 15.1281 18.6566 15.1638 18.5538 15.1301C17.4463 14.7648 16.3059 14.5491 15.1546 14.3831C15.1424 14.3816 15.1318 14.3721 15.0938 14.3525C15.4158 13.127 15.5869 11.8705 15.6343 10.5845H20.0873V10.8199V10.8203Z" fill="#171717" fill-opacity="0.9" />
      <path d="M0 9.17265C0.0655133 8.76153 0.118473 8.34805 0.198894 7.93967C0.402887 6.90833 0.774783 5.93818 1.30674 5.00452C2.44753 5.48979 3.64011 5.78205 4.82798 5.99192C4.68283 7.1335 4.53925 8.26135 4.39331 9.40803H0C0 9.32957 0 9.25111 0 9.17265Z" fill="#171717" fill-opacity="0.9" />
      <path d="M20.0846 9.40805H15.6893C15.5473 8.27785 15.4076 7.16648 15.2656 6.03392C16.4425 5.82208 17.6578 5.58631 18.8383 5.02808C19.0772 5.58945 19.3286 6.12533 19.5354 6.67768C19.8202 7.43834 19.9838 8.23077 20.052 9.04165C20.0559 9.08598 20.0736 9.12913 20.085 9.17307V9.40845L20.0846 9.40805Z" fill="#171717" fill-opacity="0.9" />
      <path d="M9.43806 14.0967C8.64994 14.156 7.87555 14.2117 7.10155 14.2733C6.84813 14.2933 6.59588 14.3345 6.34207 14.3517C6.2946 14.3549 6.20986 14.2995 6.19731 14.2556C5.85758 13.0768 5.68222 11.872 5.64064 10.6469C5.64025 10.6347 5.64692 10.6226 5.65359 10.5978H9.43845V14.0967H9.43806Z" fill="#171717" fill-opacity="0.9" />
      <path d="M10.641 10.5932H14.4482C14.4086 11.8254 14.2372 13.027 13.9041 14.2192C12.8198 14.1713 11.734 14.1234 10.6406 14.0752V10.5928L10.641 10.5932Z" fill="#171717" fill-opacity="0.9" />
      <path d="M10.9453 0.010219C13.4223 0.129084 16.3296 1.53036 18.1227 4.02183C17.7948 4.32978 15.6199 4.81505 14.9067 4.87821C14.7345 4.44904 14.5776 4.0132 14.3873 3.59305C13.9691 2.67155 13.4434 1.81988 12.7134 1.10551C12.2061 0.609254 11.6252 0.238143 10.9457 0.00982666L10.9453 0.010219Z" fill="#171717" fill-opacity="0.9" />
      <path d="M9.13955 0C8.8877 0.11455 8.62721 0.213409 8.38556 0.346004C7.47151 0.848142 6.82305 1.61704 6.28207 2.48715C5.85329 3.1772 5.52376 3.91471 5.2621 4.68322C5.24366 4.73775 5.22326 4.79189 5.20012 4.85622C4.09777 4.67381 3.02445 4.42195 1.96094 3.99514C3.80276 1.62842 6.17497 0.284022 9.13955 0Z" fill="#171717" fill-opacity="0.9" />
      <path d="M9.44591 6.44072V9.39274H5.63672C5.66026 8.31236 5.80227 7.25159 6.04902 6.20416C7.1804 6.28301 8.30393 6.36108 9.44591 6.44072Z" fill="#171717" fill-opacity="0.9" />
      <path d="M14.0242 6.23043C14.2176 6.7259 14.4906 8.81448 14.4321 9.39665H10.6445V6.44502C11.7712 6.37323 12.8959 6.30183 14.0242 6.23004V6.23043Z" fill="#171717" fill-opacity="0.9" />
      <path d="M10.9535 20C12.5074 19.4249 13.4128 18.2017 14.1507 16.8169C14.3492 16.4438 14.5026 16.046 14.6674 15.6557C14.7101 15.5549 14.7553 15.5164 14.8678 15.5325C15.8894 15.6789 16.9027 15.8621 17.8932 16.1578C17.9109 16.1629 17.9274 16.1724 17.9732 16.1928C16.1487 18.4406 13.8216 19.7207 10.9531 20.0004L10.9535 20Z" fill="#171717" fill-opacity="0.9" />
      <path d="M2.46484 16.607C3.17098 16.2335 3.91673 16.0245 4.67386 15.8471C4.85785 15.804 5.04536 15.7742 5.22856 15.7275C5.36979 15.6918 5.44315 15.72 5.50121 15.8711C5.79582 16.6357 6.18145 17.3539 6.66436 18.0165C7.24575 18.8141 7.93187 19.4916 8.86593 19.8733C8.95498 19.9097 9.04717 19.9384 9.15034 19.9753C8.60819 20.0969 6.54628 19.5253 5.47296 18.9667C4.3255 18.3696 3.31887 17.5952 2.46524 16.607H2.46484Z" fill="#171717" fill-opacity="0.9" />
      <path d="M10.6406 5.25402V1.17297C11.1828 1.32832 11.6068 1.6449 11.9827 2.03289C12.6421 2.71391 13.0866 3.53028 13.4514 4.3949C13.5397 4.60399 13.6138 4.81857 13.7096 5.07082C12.6762 5.13241 11.6692 5.19243 10.6406 5.25402Z" fill="#171717" fill-opacity="0.9" />
      <path d="M6.38711 5.04608C6.79863 3.91038 7.29096 2.88532 8.09713 2.0411C8.47098 1.64959 8.89741 1.3334 9.44191 1.17334V5.2489C8.42979 5.18181 7.42434 5.11512 6.38672 5.04608H6.38711Z" fill="#171717" fill-opacity="0.9" />
      <path d="M10.6445 15.2626C11.6017 15.2995 12.5389 15.3352 13.531 15.3733C12.8669 16.8102 12.1992 18.1703 10.6445 18.8506V15.2626Z" fill="#171717" fill-opacity="0.9" />
      <path d="M9.44408 15.2807V18.8157C9.04944 18.7172 8.72305 18.503 8.43118 18.2417C7.60069 17.498 7.07109 16.5513 6.61328 15.4969C7.56774 15.4239 8.49473 15.3533 9.44448 15.2807H9.44408Z" fill="#171717" fill-opacity="0.9" />
    </svg>
  );
}
