import React from "react";

export default function GoodreadsIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5578 7.9625C14.7969 9.71094 14.3375 11.6656 12.875 12.6828C11.8297 13.4094 10.4 13.3438 9.55625 12.95C7.81719 12.1391 7.23594 10.2031 7.3625 8.39375C7.56406 5.53906 9.27969 4.27344 10.8922 4.29219C13.0906 4.28281 14.2578 5.78281 14.5578 7.9625ZM21.5 3.125V18.875C21.5 20.3234 20.3234 21.5 18.875 21.5H3.125C1.67656 21.5 0.5 20.3234 0.5 18.875V3.125C0.5 1.67656 1.67656 0.5 3.125 0.5H18.875C20.3234 0.5 21.5 1.67656 21.5 3.125ZM15.9688 13.6813C15.9688 13.6813 15.9641 12.0875 15.9641 3.49531H14.6047V5.38437C14.5672 5.39844 14.5484 5.36094 14.5297 5.32812C14.0797 4.35781 12.8469 3.15781 10.9672 3.17188C8.53438 3.19062 6.87969 4.63438 6.25156 6.81875C6.05 7.51719 5.97969 8.22969 5.99375 8.95625C6.07344 12.6078 8.10781 14.4781 11.2625 14.3563C12.6172 14.3047 13.8172 13.5594 14.4969 12.2375C14.5203 12.1906 14.5484 12.1484 14.5766 12.1016C14.5859 12.1062 14.5953 12.1063 14.6047 12.1109C14.6187 12.2891 14.6141 13.55 14.6094 13.7281C14.6 14.4219 14.5156 15.1109 14.2719 15.7672C13.9062 16.7516 13.2266 17.3938 12.1859 17.6188C11.3516 17.8016 10.5172 17.7969 9.69219 17.5625C8.68438 17.2766 7.98125 16.6719 7.76562 15.6031C7.75156 15.5281 7.70469 15.5422 7.65781 15.5422H6.40156C6.43906 16.0391 6.55156 16.4938 6.8 16.9109C7.93437 18.8094 10.6766 19.1844 12.8094 18.6641C15.1484 18.0875 15.9641 16.0906 15.9688 13.6813Z" fill="#171717" fill-opacity="0.9" />
    </svg>

  );
}
