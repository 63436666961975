import { Button } from "antd";
import React, { useState } from "react";

import styles from "./PendingConnectionButton.module.css";
import { declineConnection } from "../../../api/api";
import { useNavigate } from 'react-router-dom';

export default function PendingConnectionButton({ connection, onDecline, mode, user }: any) {
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const handleDeclineConnection = async () => {
    setSaving(true);
    try {
      await declineConnection(connection);
      onDecline()
    } catch(e) {
    } finally {
      setSaving(false);
    }
  };

  return (
    <Button
      className={styles.pendingButton}
      type="primary"
      onClick={() => mode ? navigate("/@/" + user?.username) : handleDeclineConnection()}
    >
      Pending
    </Button>
  );
}
