import { Avatar, Collapse, List } from "antd";
import React, { useEffect, useState } from "react";

import styles from "./DailyEvents.module.css";
import ListItemUser from "../../../../components/ListItemUser/ListItemUser";
import ListItemWrap from "../../../../components/ListItemWrap/ListItemWrap";
import { getEventTypeInfo } from "../../../../utils/eventDisplayInformation";
import EventTag from "../../../../components/EventTag/EventTag";
import { useMediaQuery } from "react-responsive";
import formatMonthDay from "../../../../utils/monthDaytoDisplayDate";
import { Link, useNavigate } from "react-router-dom";
import { Storage } from 'aws-amplify';
import getTodayMonthDay from '../../../../utils/getTodayMonthDay';

const eventTypes = {
  "WORKIVERSARY": {
    text: "175px",
    tag: "128px"
  },
  "FOUNDER_DAY": {
    text: "203px",
    tag: "100px"
  },
  "CHILD_BIRTHDAY": {
    text: "203px",
    tag: "100px"
  },
  "ANNIVERSARY": {
    text: "182px",
    tag: "121px"
  },
  "REMEMBRANCE_DAY": {
    text: "164px",
    tag: "139px"
  },
  "PET_BIRTHDAY": {
    text: "203px",
    tag: "100px"
  },
  "HEALTHVERSARY": {
    text: "170px",
    tag: "133px"
  },
  "PARENT_ADOPTIONVERSARY": {
    text: "152px",
    tag: "151px"
  },
  "REBIRTHDAY": {
    text: "190px",
    tag: "113px"
  },
  "ARRIVAL_DAY": {
    text: "223px",
    tag: "80px"
  },
  "OUTVERSARY": {
    text: "190px",
    tag: "113px"
  },
  "SOBERVERSARY": {
    text: "175px",
    tag: "128px"
  },
  "CITIZENSHIPVERSARY": {
    text: "138px",
    tag: "165px"
  },
  "CHILD_ADOPTIONVERSARY": {
    text: "152px",
    tag: "151px"
  },
  "BIRTHDAY": {
    text: "208px",
    tag: "95px"
  },
};


const ListItemDesktop = ({ event, displayInfo }) => {
  return (<ListItemWrap>
    <ListItemUser user={event.user} mode="events" />
    <div className={styles.event}>
      <EventTag
        text={displayInfo.label}
        backgroundColor={displayInfo.color}
      />
      {Object.values(event.event.fields).map(
        (value: string) => (
          <EventTag
            text={value}
            backgroundColor={displayInfo.color}
          />
        )
      )}
    </div>
  </ListItemWrap>)
}

const ListItemMobile = ({ event, displayInfo, index }) => {
  const { firstName, lastName, username } = event.user;
  const navigate = useNavigate();
  const [image, setImage] = React.useState(null);

  useEffect(() => {
    if(event.user.avatarUrl) {
      Storage.get(event.user.avatarUrl).then((image) => setImage(image));
    }
  }, [event.user.avatarUrl]);


  return (
    <Collapse
      style={{
        width: "100%",
        border: "1px solid #ede7fb",
        borderRadius: "8px",
        padding: "14px 0"
      }}
      ghost
      collapsible={Object.values(event.event.fields)?.length === 0 ? 'disabled' : 'header'}
      expandIcon={() => null}
      items={[
        {
          key: index,
          label:
            <div className={styles.collapseHeader}>
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start"
              }} onClick={() => navigate(`/@/${event.user.username}`)}>
                <div
                  style={{
                    flexGrow: 1
                  }}
                >
                  {image ? <Avatar
                    size="large"
                    src={image || "celi-logo-transp 2.png"}
                  /> : <div className={styles.avatar} style={{ backgroundColor: '#BCBAED' }}>
                    {firstName?.substr(0, 1)?.toUpperCase()}{lastName?.substr(0, 1)?.toUpperCase()}
                  </div>
                  }
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingLeft: "12px",
                    marginRight: "12px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}>
                  <div style={{ width: eventTypes[event.event.type].text }} className={styles.name}>
                    {/* Maria Alejandra Sepulveda */}
                    {(firstName || "") + " " + (lastName || "")}
                  </div>
                  <div className={styles.username} style={{ width: eventTypes[event.event.type].text }}>@{username}</div>
                </div>

              </div>
              <div>
                <EventTag
                  text={displayInfo.label}
                  backgroundColor={displayInfo.color}
                  width={eventTypes[event.event.type].tag}
                />
              </div>
            </div>,
          children: <div className={styles.collapseContent}>
            {Object.values(event.event.fields).map(
              (value: string) => (
                <span style={{ margin: "0 0 0 10px" }}>
                  <EventTag
                    text={value}
                    backgroundColor={displayInfo.color}
                  />
                </span>
              )
            )}
          </div>,
          style: {
            padding: 0,
          } as React.CSSProperties,
        }
      ]}
    />
  )
}


export default function DailyEvents({ date, events }) {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const [today, setToday] = useState(formatMonthDay(getTodayMonthDay()).formattedShort)
  return (events !== undefined) && (
    <div className={styles.main}>
      <div className={styles.date}>{today !== formatMonthDay(date).formattedShort && formatMonthDay(date).formattedShort}</div>
      <div>
        <List bordered={false} style={{ gap: 20 }}>
          {events?.length > 0 ? (
            events.map((event, index) => {
              const displayInfo = getEventTypeInfo(event.event.type);
              return (
                <List.Item>
                  {isDesktopOrLaptop ?
                    <ListItemDesktop displayInfo={displayInfo} event={event} key={index} /> :
                    <ListItemMobile displayInfo={displayInfo} event={event} index={index} />}
                  {/* <ListItemMobile displayInfo={displayInfo} event={event} index={index} /> */}
                </List.Item>
              );
            })
          ) : (
            <List.Item>
              <ListItemWrap>
                <div className={styles.noEventsText}>
                  You have no one to celi today.
                </div>
              </ListItemWrap>
            </List.Item>
          )}
        </List>
      </div>
    </div>
  );
}