export default function commonConnections(array1, array2, attribute1 = "sendingId", attribute2 = "sendingId") {
  let commonElements = [];
  for(let i = 0; i < array1?.length; i++) {
    for(let j = 0; j < array2?.length; j++) {
      if(array1[i][attribute1] === array2[j][attribute2]) {
        commonElements.push(array1[i]);
        break;
      }
    }
  }
  return commonElements;
}