import React from "react";

export default function SignalIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3029_26720)">
        <path d="M2.42269 17.58C2.45989 17.3708 2.4785 17.2219 2.5126 17.0778C2.69398 16.2995 2.86917 15.5182 3.0707 14.7431C3.14356 14.4625 3.12186 14.2377 2.98078 13.9741C1.6336 11.4704 1.50803 8.89855 2.80715 6.36541C4.32177 3.41215 6.81925 1.88824 10.1368 1.93475C13.442 1.97971 15.8666 3.58733 17.3223 6.54679C19.5175 11.0085 16.9766 16.5088 12.1646 17.7847C10.0066 18.3567 7.94629 18.0901 5.9821 17.022C5.79297 16.9181 5.51392 16.8933 5.29843 16.9367C4.514 17.0933 3.74042 17.2979 2.96063 17.4793C2.8025 17.5165 2.64128 17.5397 2.42114 17.5816L2.42269 17.58Z" fill="#171717" fill-opacity="0.9" />
        <path d="M3.39837 19.1892C2.66974 19.338 1.99693 19.5132 1.31171 19.6031C0.775315 19.6729 0.33969 19.2745 0.400151 18.7458C0.480764 18.0374 0.665246 17.3397 0.814072 16.5972C1.14738 16.6793 1.40317 16.7413 1.69307 16.8111C1.55355 17.4467 1.42333 18.0436 1.2745 18.7226C1.93802 18.5784 2.53487 18.4482 3.18598 18.3071C3.25574 18.5986 3.3193 18.8637 3.39837 19.1892Z" fill="#171717" fill-opacity="0.9" />
        <path d="M7.55793 19.6853C6.94867 19.4559 6.35957 19.2714 5.80302 19.0125C5.40925 18.8296 5.04339 18.7164 4.62637 18.89C4.22795 19.0575 4.2264 18.7474 4.15819 18.5009C4.08843 18.2482 4.07137 18.073 4.40933 17.9955C4.99843 17.8606 5.54413 17.7614 6.10532 18.1474C6.44793 18.3815 6.88356 18.4854 7.28353 18.6296C7.81062 18.8203 7.81372 18.8125 7.6711 19.366C7.65249 19.4388 7.61994 19.5101 7.55638 19.6853H7.55793Z" fill="#171717" fill-opacity="0.9" />
        <path d="M1.13799 12.2254C1.4775 12.9944 1.88057 13.7106 2.09606 14.4811C2.20613 14.8779 2.00149 15.374 1.87747 15.8066C1.86352 15.8531 1.43255 15.8066 1.20621 15.7492C1.1473 15.7352 1.07753 15.5042 1.11319 15.4128C1.29457 14.9493 1.17365 14.5508 0.961264 14.1292C0.761279 13.7292 0.623305 13.2967 0.479131 12.8703C0.319453 12.3991 0.330305 12.396 0.806237 12.289C0.928708 12.2611 1.05428 12.2409 1.13799 12.2254Z" fill="#171717" fill-opacity="0.9" />
        <path d="M15.1726 1.39369C14.9726 1.72855 14.8377 1.95644 14.7137 2.16572C13.8796 1.81846 13.0859 1.48826 12.2596 1.1441C12.2689 1.16425 12.2472 1.13945 12.2503 1.11774C12.3325 0.291451 12.3464 0.263546 13.1216 0.541044C13.7959 0.782886 14.4486 1.08984 15.1726 1.39369Z" fill="#171717" fill-opacity="0.9" />
        <path d="M0 9.97908C0.0372064 9.59306 0.069762 9.221 0.111619 8.85048C0.133323 8.6598 0.187582 8.46912 0.444927 8.56523C0.658864 8.6443 1.03093 8.45827 1.01698 8.93575C0.995272 9.64112 0.996822 10.3496 1.01698 11.055C1.02938 11.503 0.680567 11.3371 0.474382 11.4131C0.240291 11.4999 0.137974 11.379 0.11472 11.1604C0.0744128 10.7635 0.0372064 10.3666 0 9.98063L0 9.97908Z" fill="#171717" fill-opacity="0.9" />
        <path d="M20.0211 9.9946C19.9824 10.3806 19.9467 10.7775 19.9033 11.1744C19.8816 11.3697 19.7886 11.503 19.5715 11.4146C19.3685 11.3325 19.0026 11.5216 19.0073 11.0767C19.0135 10.3574 19.0212 9.63804 19.0041 8.91872C18.9933 8.46914 19.3483 8.64432 19.5529 8.5668C19.7917 8.47534 19.8847 8.61331 19.908 8.8257C19.9483 9.21017 19.9839 9.59463 20.0227 9.99305L20.0211 9.9946Z" fill="#171717" fill-opacity="0.9" />
        <path d="M11.4452 0.164343C11.4514 0.254259 11.4591 0.293015 11.4545 0.330222C11.3723 1.02939 11.3738 1.00304 10.6561 0.95963C10.1197 0.927075 9.57708 0.941027 9.04224 0.995287C8.5415 1.0449 8.65002 0.683683 8.58336 0.426338C8.4981 0.0945811 8.75234 0.108534 8.94612 0.082179C9.77707 -0.0325408 10.6049 -0.0433927 11.4452 0.165893V0.164343Z" fill="#171717" fill-opacity="0.9" />
        <path d="M19.6622 7.58236C19.3645 7.65367 19.118 7.71103 18.8715 7.76994C18.5243 6.93745 18.1863 6.12511 17.8359 5.28796C18.0483 5.16084 18.2669 5.03062 18.5336 4.87094C19.0762 5.69723 19.4188 6.58244 19.6622 7.58236Z" fill="#171717" fill-opacity="0.9" />
        <path d="M18.8614 12.2332C18.8459 12.2394 18.8583 12.2301 18.8692 12.2316C19.8799 12.3897 19.7172 12.3649 19.4536 13.123C19.218 13.7974 18.9033 14.4423 18.6041 15.1446C18.2847 14.9585 18.0553 14.8252 17.832 14.6965C18.1808 13.861 18.5173 13.0579 18.8614 12.2347V12.2332Z" fill="#171717" fill-opacity="0.9" />
        <path d="M12.2398 18.8451C13.0832 18.4932 13.8769 18.1614 14.7094 17.8141C14.835 18.0234 14.9714 18.2498 15.1295 18.5118C14.3327 19.0249 13.4971 19.3722 12.6057 19.5861C12.539 19.6016 12.3964 19.5039 12.3685 19.4295C12.3003 19.2388 12.277 19.0311 12.2383 18.8451H12.2398Z" fill="#171717" fill-opacity="0.9" />
        <path d="M10.0008 20C9.61323 19.9628 9.23031 19.9287 8.84739 19.8868C8.66756 19.8667 8.50013 19.8031 8.5854 19.5706C8.66601 19.3551 8.48773 18.9799 8.95126 18.9877C9.65508 19.0001 10.3605 19.0032 11.0658 18.9877C11.5309 18.9768 11.3635 19.3443 11.4394 19.5628C11.5309 19.8233 11.3402 19.8713 11.1511 19.893C10.7682 19.9349 10.3853 19.9659 10.0008 20.0031V20Z" fill="#171717" fill-opacity="0.9" />
        <path d="M7.78733 1.13171C6.92848 1.48982 6.1363 1.82003 5.30845 2.16574C5.18288 1.9518 5.04801 1.72546 4.89453 1.46656C5.73633 0.925521 6.61688 0.56741 7.61215 0.342621C7.67726 0.638722 7.73462 0.896066 7.78733 1.13171Z" fill="#171717" fill-opacity="0.9" />
        <path d="M2.19031 5.27867C1.8384 6.12201 1.50665 6.91575 1.16249 7.73739C1.16869 7.73739 1.13148 7.75289 1.09582 7.74824C0.305188 7.63972 0.281934 7.63042 0.559432 6.87544C0.805925 6.20263 1.11133 5.55151 1.41208 4.84149C1.72213 5.01512 1.95312 5.14379 2.19031 5.27712V5.27867Z" fill="#171717" fill-opacity="0.9" />
        <path d="M1.95077 4.00902C2.66079 3.30055 3.32121 2.64323 4.02193 1.94406C4.21881 2.19055 4.38004 2.35643 4.49011 2.55022C4.53041 2.62153 4.48391 2.79671 4.41724 2.86492C3.91651 3.38271 3.40802 3.89275 2.88248 4.38573C2.80652 4.45705 2.60963 4.47255 2.50732 4.42604C2.33679 4.34853 2.19881 4.2028 1.94922 4.00747L1.95077 4.00902Z" fill="#171717" fill-opacity="0.9" />
        <path d="M16.0008 1.94406C16.7 2.64323 17.3666 3.30985 18.0224 3.96561C17.9402 4.08188 17.9247 4.11909 17.8968 4.14389C17.348 4.63223 17.3589 4.62137 16.8643 4.08033C16.5171 3.70051 16.1574 3.3238 15.7544 3.00754C15.4567 2.775 15.4086 2.59982 15.6722 2.33628C15.7699 2.23861 15.8489 2.12544 16.0008 1.94406Z" fill="#171717" fill-opacity="0.9" />
        <path d="M16.05 17.9738C15.94 17.9335 15.9245 17.932 15.9167 17.9242C15.34 17.3475 15.3524 17.3553 15.9446 16.822C16.326 16.4778 16.6965 16.112 17.0158 15.7104C17.2468 15.4205 17.4205 15.4128 17.6639 15.65C17.7615 15.7461 17.8747 15.8267 18.0576 15.9802C17.3554 16.6778 16.6872 17.3413 16.05 17.9738Z" fill="#171717" fill-opacity="0.9" />
      </g>
      <defs>
        <clipPath id="clip0_3029_26720">
          <rect width="20.0217" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}