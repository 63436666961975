import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Amplify, Auth, Storage } from "aws-amplify";
import awsExports from "./aws-exports";
import React from "react";
Amplify.configure(awsExports);
Storage.configure({ track: true });

const IS_LOCALHOST = document.location.hostname === "localhost";

const cookieStorage = {
  domain: IS_LOCALHOST ? document.location.hostname : ".timetoceli.com",
  secure: !IS_LOCALHOST,
  path: "/",
  expires: 365,
};
Auth.configure({ ...awsExports, cookieStorage });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);