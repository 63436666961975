import React from "react";

export default function PhysicalTouchIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 15 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7912 8.69881C11.3093 8.6073 11.8225 8.50961 12.3388 8.43232C12.4105 8.4218 12.5373 8.49044 12.5707 8.5566C13.6843 10.764 14.6235 13.0289 14.864 15.525C15.0056 16.991 14.6049 18.2326 13.4023 19.1563C12.9009 19.5416 12.3339 19.7728 11.7174 19.8829C10.4214 20.1147 9.15388 20.0022 7.91787 19.5465C7.88077 19.5329 7.848 19.5069 7.79297 19.4748C7.83811 19.4006 7.87273 19.3301 7.9191 19.2689C8.60914 18.3513 8.81813 17.3144 8.63449 16.2008C8.58255 15.8861 8.65181 15.6975 8.89109 15.4947C9.91317 14.6303 10.892 13.7133 11.9357 12.8761C13.1488 11.9029 12.8539 10.0603 11.4614 9.57743C11.3971 9.55517 11.3248 9.51498 11.2883 9.46119C11.1158 9.20954 10.9538 8.94984 10.7912 8.69819V8.69881Z" fill="#171717" fill-opacity="0.9" />
      <path d="M5.68171 9.67018C3.60851 9.67327 1.90011 7.97044 1.89454 5.89538C1.88898 3.83886 3.61655 2.11439 5.68233 2.11377C7.74688 2.11315 9.47383 3.8401 9.46332 5.89599C9.45281 7.98095 7.76419 9.66647 5.68171 9.67018Z" fill="#171717" fill-opacity="0.9" />
      <path d="M10.296 7.45849C10.3634 7.19323 10.4258 6.9459 10.4939 6.67508C10.5922 6.30471 10.5705 5.9869 10.5229 5.51142C10.356 3.8395 9.55217 2.56701 8.1183 1.69271C8.05029 1.65128 7.98413 1.60615 7.91797 1.56348C8.52577 0.485146 10.4809 -0.53198 12.4162 0.313254C14.2946 1.13376 15.185 3.29476 14.4202 5.18989C13.7592 6.82719 11.6983 7.97478 10.296 7.4591V7.45849Z" fill="#171717" fill-opacity="0.9" />
      <path d="M7.60139 16.6268C7.6657 17.3595 7.50679 17.9568 7.13766 18.5003C6.62013 19.2633 5.86146 19.6454 4.98717 19.8266C3.74436 20.0844 2.53988 19.9707 1.43496 19.3184C0.396189 18.7056 -0.0397238 17.7565 0.0307639 16.5613C0.0456034 16.3121 0.12475 16.2342 0.396189 16.2843C1.03738 16.403 1.65013 16.2676 2.20167 15.925C2.8342 15.5324 3.02216 14.7292 2.60418 14.1214C2.29194 13.667 2.19795 13.257 2.4731 12.7327C2.76309 12.1799 2.94859 11.5721 3.18602 10.9915C3.28248 10.7553 3.39625 10.5253 3.50692 10.2823C3.93356 10.4233 4.33732 10.5568 4.80909 10.7133C4.53271 10.8747 4.31197 11.0373 4.0665 11.1424C2.53679 11.7959 2.87686 13.7053 3.93665 14.4263C4.01209 14.4776 4.08567 14.5307 4.19387 14.6062C4.09247 14.702 4.00652 14.7836 3.91996 14.864C3.15572 15.5689 3.07905 16.7381 3.74806 17.5067C4.4041 18.261 5.56591 18.347 6.36601 17.6909C6.76791 17.3614 7.15436 17.0133 7.60078 16.6275L7.60139 16.6268Z" fill="#171717" fill-opacity="0.9" />
      <path d="M11.4074 10.8425C11.7308 11.2197 11.6869 11.6463 11.2949 11.9895C10.4577 12.7216 9.61491 13.4481 8.77462 14.1764C7.79397 15.0266 6.82075 15.8855 5.82712 16.7196C5.63482 16.8809 5.36833 17.012 5.12348 17.0392C4.80814 17.0745 4.55772 16.8735 4.43035 16.5675C4.30236 16.2602 4.34316 15.9461 4.58802 15.7408C5.36647 15.0878 6.04043 14.3094 6.94503 13.8067C8.07036 13.1816 9.1827 12.5336 10.3012 11.8973C10.772 11.6294 11.1407 11.2778 11.4074 10.8425Z" fill="#171717" fill-opacity="0.9" />
      <path d="M4.10156 12.5651C4.16154 12.4903 4.24686 12.2993 4.3934 12.2145C6.03008 11.266 7.67294 10.3281 9.32569 9.4074C9.46419 9.33011 9.71523 9.34619 9.86363 9.41976C10.1882 9.57991 10.3682 9.89401 10.3577 10.2508C10.3527 10.4245 10.2365 10.6694 10.0949 10.7522C8.46191 11.7075 6.81225 12.6344 5.17186 13.5767C4.93876 13.7109 4.74152 13.6744 4.54551 13.5266C4.26542 13.3158 4.12073 13.032 4.10156 12.5645V12.5651Z" fill="#171717" fill-opacity="0.9" />
      <path d="M1.09489 12.7852C1.03986 13.5062 1.33603 14.1066 1.68105 14.6971C1.72186 14.7676 1.63282 15.0124 1.56295 15.0316C1.23339 15.1237 0.890227 15.2053 0.550773 15.2127C0.283662 15.2183 0.121666 14.9957 0.0425215 14.7404C-0.160285 14.0887 0.390629 13.0357 1.09489 12.7852Z" fill="#171717" fill-opacity="0.9" />
    </svg>
  );
}
