import React from "react";

// interface helpIconProps {
//   color?: string;
// }

export default function HelpIcon(/* { color }: helpIconProps */) {
  return (
    <svg width="20" height="20" viewBox="0.1291 0.3239 19.3881 20.1116" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(0.21314501762390137, 0, 0, 0.21314501762390137, -16.77326011657715, -14.480125427246094)">
        <ellipse style={{
          fill: "rgb(188, 186, 237)",
          stroke: "rgb(188, 186, 237)"
        }} cx="124.781" cy="114.936" rx="45.481" ry="45.481" />
        <text style={{
          whiteSpace: "pre",
          fill: "rgb(255, 255, 255)",
          fontFamily: "Arial, sans-serif",
          fontSize: "80.8px"
        }} x="102.417" y="145.031">?</text>
      </g>
    </svg >
  );
}
