export default function getTodayMonthDay() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = new Date();

  const options = { timeZone: userTimezone, month: "2-digit", day: "2-digit" };
  const monthDay = now
    // @ts-ignore
    .toLocaleDateString("en-US", options)
    .replace(/\//g, "-");

  return monthDay;
}
