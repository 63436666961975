import React from "react";
import styles from "./ProfileInformation.module.css";
import { PhoneFilled } from '@ant-design/icons';
import CellphoneIcon from '../../../../components/icons/CellphoneIcon/CellphoneIcon';
import LocationIcon from '../../../../components/icons/LocationIcon/LocationIcon';
import CopyIcon from '../../../../components/icons/CopyIcon/CopyIcon';
import { Button, notification } from 'antd';
import { billsLinks, communicationPreferences, creatorsLinks, socialsLinks } from '../../../me/(components)/ProfileInformation/ProfileInformation';
import EmailIcon from '../../../../components/icons/EmailICon/EmailIcon';
import ActsOfServiceIcon from '../../../../components/icons/ActsOfServiceIcon/ActsOfServiceIcon';
import PhysicalTouchIcon from '../../../../components/icons/PhysicalTouchIcon/PhysicalTouchIcon';
import QualityTimeIcon from '../../../../components/icons/QualityTimeIcon/QualityTimeIcon';
import ReceivingGiftsIcon from '../../../../components/icons/ReceivingGiftsIcon/ReceivingGiftsIcon';
import WordsOfAffirmationIcon from '../../../../components/icons/WordsOfAffirmationIcon/WordsOfAffirmationIcon';
import AriesIcon from '../../../../components/icons/zodiac/AriesIcon/AriesIcon';
import TaurusIcon from '../../../../components/icons/zodiac/TaurusIcon/TaurusIcon';
import GeminiIcon from '../../../../components/icons/zodiac/GeminiIcon/GeminiIcon';
import CancerIcon from '../../../../components/icons/zodiac/CancerIcon/CancerIcon';
import LeoIcon from '../../../../components/icons/zodiac/LeoIcon/LeoIcon';
import VirgoIcon from '../../../../components/icons/zodiac/VirgoIcon/VirgoIcon';
import LibraIcon from '../../../../components/icons/zodiac/LibraIcon/LibraIcon';
import ScorpioIcon from '../../../../components/icons/zodiac/ScorpioIcon/ScorpioIcon';
import SagittariusIcon from '../../../../components/icons/zodiac/SagittariusIcon/SagittariusIcon';
import CapricornIcon from '../../../../components/icons/zodiac/CapricornIcon/CapricornIcon';
import AquariusIcon from '../../../../components/icons/zodiac/AquariusIcon/AquariusIcon';
import PiscesIcon from '../../../../components/icons/zodiac/PiscesIcon/PiscesIcon';
import SnapchatIcon from '../../../../components/icons/SnapchatIcon/SnapchatIcon';
import WeChatIcon from '../../../../components/icons/WeChatIcon/WeChatIcon';
import AppleMusicIcon from '../../../../components/icons/creator/AppleMusicIcon/AppleMusicIcon';
import BehanceIcon from '../../../../components/icons/creator/BehanceIcon/BehanceIcon';
import BlogIcon from '../../../../components/icons/creator/BlogIcon/BlogIcon';
import DiscordIcon from '../../../../components/icons/creator/DiscordIcon/DiscordIcon';
import EtsyIcon from '../../../../components/icons/creator/EtsyIcon/EtsyIcon';
import FlickrIcon from '../../../../components/icons/creator/FlickrIcon/FlickrIcon';
import GitHubIcon from '../../../../components/icons/creator/GitHubIcon/GitHubIcon';
import GoodreadsIcon from '../../../../components/icons/creator/GoodreadsIcon/GoodreadsIcon';
import NewsletterIcon from '../../../../components/icons/creator/NewsletterIcon/NewsletterIcon';
import PinterestIcon from '../../../../components/icons/creator/PinterestIcon/PinterestIcon';
import SoundcloudIcon from '../../../../components/icons/creator/SoundcloudIcon/SoundcloudIcon';
import SpotifyIcon from '../../../../components/icons/creator/SpotifyIcon/SpotifyIcon';
import TwitchIcon from '../../../../components/icons/creator/TwitchIcon/TwitchIcon';
import VimeoIcon from '../../../../components/icons/creator/VimeoIcon/VimeoIcon';
import VSCOIcon from '../../../../components/icons/creator/VSCOIcon/VSCOIcon';
import WebsiteIcon from '../../../../components/icons/creator/WebsiteIcon/WebsiteIcon';
import CashAppIcon from '../../../../components/icons/Bills/CashAppIcon/CashAppIcon';
import PayPalIcon from '../../../../components/icons/Bills/PayPalIcon/PayPalIcon';
import VenmoIcon from '../../../../components/icons/Bills/VenmoIcon/VenmoIcon';
import WeChatPayIcon from '../../../../components/icons/Bills/WeChatPayIcon/WeChatPayIcon';
import FacebookIcon from '../../../../components/icons/Socials/FacebookIcon/FacebookIcon';
import InstagramIcon from '../../../../components/icons/Socials/InstagramIcon/InstagramIcon';
import LinkedinIcon from '../../../../components/icons/Socials/LinkedinIcon/LinkedinIcon';
import TikTokIcon from '../../../../components/icons/Socials/TikTokIcon/TikTokIcon';
import YoutubeIcon from '../../../../components/icons/Socials/YoutubeIcon/YoutubeIcon';
import TwitterIcon from '../../../../components/icons/Socials/TwitterIcon/TwitterIcon';

type ZodiacSign = "capricorn" | "aquarius" | "pisces" | "aries" | "taurus" | "gemini" | "cancer" | "leo" | "virgo" | "libra" | "scorpio" | "sagittarius";

function getZodiacSign(dateString: string): ZodiacSign {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;

  if((month === 1 && day <= 20) || (month === 12 && day >= 22)) {
    return "capricorn";
  } else if((month === 1 && day >= 21) || (month === 2 && day <= 18)) {
    return "aquarius";
  } else if((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
    return "pisces";
  } else if((month === 3 && day >= 21) || (month === 4 && day <= 20)) {
    return "aries";
  } else if((month === 4 && day >= 21) || (month === 5 && day <= 20)) {
    return "taurus";
  } else if((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
    return "gemini";
  } else if((month === 6 && day >= 22) || (month === 7 && day <= 22)) {
    return "cancer";
  } else if((month === 7 && day >= 23) || (month === 8 && day <= 23)) {
    return "leo";
  } else if((month === 8 && day >= 24) || (month === 9 && day <= 23)) {
    return "virgo";
  } else if((month === 9 && day >= 24) || (month === 10 && day <= 23)) {
    return "libra";
  } else if((month === 10 && day >= 24) || (month === 11 && day <= 22)) {
    return "scorpio";
  } else if((month === 11 && day >= 23) || (month === 12 && day <= 21)) {
    return "sagittarius";
  }
}


export default function ProfileInformation({ user }) {
  const { email: emailHide, phone: phoneHide, address: addressHide } = JSON.parse(user?.hideContactInfo) || {
    email: true,
    phone: true,
    address: true,
  }
  const friendshipLanguageCard = friendshipLanguagesCard[user.friendshipLanguage]
  const userZodiac = getZodiacSign(user.events?.items?.find(event => event.type === "BIRTHDAY").date)
  const communicationPreference = communicationPreferences.find(v => v.value === user?.communicationPreference)
  const zodiacCard = zodiacCards[userZodiac]
  const links = JSON.parse(user?.links)
  const linkBills = links?.bills
  const linkSocials = links?.socials
  const linkCreators = links?.creators

  return (
    <div className={styles.wrapper}>
      <div className={styles.prefferences}>
        <div className={styles.contact}>
          <p style={{ fontSize: "24px", fontWeight: 500, margin: "10px 0" }}>Contact</p>
          <div className={styles.line}>
            {communicationPreference ? <>{communicationPreference?.icon}<p>{communicationPreference?.title}</p></> : <><CellphoneIcon /><p>-</p></>}
          </div>
          <>
            <div className={styles.line}><PhoneFilled rotate={90} /><p>{!phoneHide ? "-" : user.phone} </p></div>
            <div className={styles.line}><EmailIcon /><p>{!emailHide ? "-" : user.email}</p></div>
            <div className={styles.address_info}>
              <LocationIcon />
              <p style={{ margin: 0, flexGrow: 1 }}>

                {user.address ?
                  !addressHide ?
                    <>
                      {user.locality}, {user.state}<br />
                      {user.country}
                    </> :
                    <>
                      {user.address}<br />
                      {[...new Set([user.locality, user.city, user.state, user.zip])].filter(v => v).join(", ")}<br />
                      {user.country}
                    </> :
                  "-"}
              </p>
              <Button type="text" icon={<CopyIcon size="1.5em" />} onClick={() => {
                if(user.address && addressHide) {
                  navigator.clipboard.writeText(`${user.address}, ${user.zip}, ${user.locality}, ${user.city}, ${user.state}, ${user.country}`);
                  notification.success({
                    message: "Copied",
                    description: "Copied to clipboard",
                    placement: "top",
                  });
                }
              }} />
            </div>
          </>
        </div>
        <div className={styles.cards}>
          <div className={styles.communication}>
            {friendshipLanguageCard ?
              <>
                <p style={{
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}>{friendshipLanguageCard?.icon}{' '}{friendshipLanguageCard?.title}</p>
                <p>{friendshipLanguageCard?.description}</p>
              </> :
              <>
                <p style={{
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}>Friendship Language</p>
              </>
            }
          </div>
          <div className={styles.zodiac}>
            <p style={{
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}>{zodiacCard?.icon}{' '}{zodiacCard?.title}</p>
            <p>{zodiacCard?.description}</p>
          </div>
        </div>
      </div>
      <div className={styles.links} >
        <div className={styles.bill}>
          <p style={{ fontWeight: 500, margin: "10px 0" }}>Bill Splitting</p>
          <div className={styles.rowLink}>
            {linkBills?.map((link, i) => {
              return (<Button
                type="text"
                icon={selectIcon("bill", link.type)}
                onClick={() => window.open(`${billsLinks[link.type]}${link.value}`, "_blank")}
                key={i}
              />)
            })}
          </div>
        </div>
        <div className={styles.creator}>
          <p style={{ fontWeight: 500, margin: "10px 0" }}>Creator</p>
          <div className={styles.rowLink}>
            {linkCreators?.map((link, i) => {
              return (<Button
                type="text"
                icon={selectIcon("creator", link.type)}
                onClick={() => {
                  let url = ""
                  if(creatorsLinks[link.type] === null) {
                    url = link.value.startsWith("http") ? link.value : "https://" + link.value
                  } else {
                    url = (creatorsLinks[link.type] === null ? "" : creatorsLinks[link.type]) + link.value
                  }
                  window.open(`${url}`, "_blank")
                }}
                key={i}
              />)
            })}
          </div>
        </div>
        <div className={styles.social}>
          <p style={{ fontWeight: 500, margin: "10px 0" }}>Social</p>
          <div className={styles.rowLink}>
            {linkSocials?.map((link, i) => {
              return (<Button
                type="text"
                icon={selectIcon("social", link.type)}
                onClick={() => window.open(`${socialsLinks[link.type]}${link.value}`, "_blank")}
                key={i}
              />)
            })}
          </div>
        </div>
      </div>
    </div >
  );
}

const selectIcon = (category, type) => {
  if(category === "social") {
    switch(type) {
      case "facebook":
        return <FacebookIcon size="1.5em" />
      case "instagram":
        return <InstagramIcon size="1.5em" />
      case "linkedin":
        return <LinkedinIcon size="1.5em" />
      case "snapchat":
        return <SnapchatIcon size="1.5em" />
      case "tiktok":
        return <TikTokIcon size="1.5em" />
      case "twitter":
        return <TwitterIcon size="1.5em" />
      case "wechat":
        return <WeChatIcon size="1.5em" />
      case "youtube":
        return <YoutubeIcon size="1.5em" />
    }
  }
  if(category === "creator") {
    switch(type) {
      case "apple_music":
        return <AppleMusicIcon size="1.5em" />
      case "behance":
        return <BehanceIcon size="1.5em" />
      case "blog":
        return <BlogIcon size="1.5em" />
      case "discord":
        return <DiscordIcon size="1.5em" />
      case "etsy":
        return <EtsyIcon size="1.5em" />
      case "flickr":
        return <FlickrIcon size="1.5em" />
      case "github":
        return <GitHubIcon size="1.5em" />
      case "goodreads":
        return <GoodreadsIcon size="1.5em" />
      case "newsletter":
        return <NewsletterIcon size="1.5em" />
      case "pinterest":
        return <PinterestIcon size="1.5em" />
      case "soundcloud":
        return <SoundcloudIcon size="1.5em" />
      case "spotify":
        return <SpotifyIcon size="1.5em" />
      case "twitch":
        return <TwitchIcon size="1.5em" />
      case "vimeo":
        return <VimeoIcon size="1.5em" />
      case "vsco":
        return <VSCOIcon size="1.5em" />
      case "website":
        return <WebsiteIcon size="1.5em" />
    }
  }
  if(category === "bill") {
    switch(type) {
      case "cash_app":
        return <CashAppIcon size="1.5em" />
      case "paypal":
        return <PayPalIcon size="1.5em" />
      case "venmo":
        return <VenmoIcon size="1.5em" />
      case "wechat_pay":
        return <WeChatPayIcon size="1.5em" />
    }
  }
}


const friendshipLanguagesCard = {
  "acts_of_service": {
    icon: <ActsOfServiceIcon />,
    title: "Acts of service",
    description: "To celi these individuals, go above and beyond to make their life easier. Whether it's tackling a chore, running an errand, or lending a hand without being asked.",
  },
  "physical_touch": {
    icon: <PhysicalTouchIcon />,
    title: "Physical Touch",
    description: "To celi these individuals, offer affectionate and appropriate physical connection. Whether that’s a warm embrace, a cheerful high-five, or simply sitting close together.",
  },
  "quality_time": {
    icon: <QualityTimeIcon />,
    title: "Quality Time",
    description: "To celi these individuals, give them your full presence and attention. Engage in meaningful conversations, organize an outing, and create a memory together.",
  },
  "receiving_gifts": {
    icon: <ReceivingGiftsIcon />,
    title: "Receiving Gifts",
    description: "To celi these individuals, surprise them with a personalized token of appreciation, big or small. These gifts don't need to be expensive, it's the thought that counts.",
  },
  "words_of_affirmation": {
    icon: <WordsOfAffirmationIcon />,
    title: "Words of Affirmation",
    description: "To celi these individuals, shower them with genuine compliments, send thoughtful messages, and reinforce how much they mean to you verbally.",
  },
};

const zodiacCards = {
  "aries": {
    icon: <AriesIcon />,
    title: "Aries",
    description: "Inspiring leaders, Aries are courageous, passionate, and energetic, igniting enthusiasm in others with their dynamic presence.",
  },
  "taurus": {
    icon: <TaurusIcon />,
    title: "Taurus",
    description: "Dependable and down-to-earth, Taurus appreciate beauty, comfort, and loyalty, enriching lives with their patient, practical love.",
  },
  "gemini": {
    icon: <GeminiIcon />,
    title: "Gemini",
    description: "Witty and adaptable, Gemini bring joy, laughter, and intellectual curiosity to conversations, captivating others with their charm.",
  },
  "cancer": {
    icon: <CancerIcon />,
    title: "Cancer",
    description: "Deeply intuitive and nurturing, Cancers create loving, supportive environments, tuning into the emotional needs of loved ones.",
  },
  "leo": {
    icon: <LeoIcon />,
    title: "Leo",
    description: "Charismatic and confident, Leos have a warm, generous spirit and natural leadership that inspires and uplifts those around them.",
  },
  "virgo": {
    icon: <VirgoIcon />,
    title: "Virgo",
    description: "Intelligent perfectionists, Virgos are reliable, detail-oriented, and always striving for excellence in their meticulous pursuits.",
  },
  "libra": {
    icon: <LibraIcon />,
    title: "Libra",
    description: "Diplomatic peacemakers, Libras are fair, charming and graceful, bringing beauty, balance and harmony to relationships.",
  },
  "scorpio": {
    icon: <ScorpioIcon />,
    title: "Scorpio",
    description: "Magnetic and determined, Scorpios are deeply passionate, perceptive and enigmatic, drawing others in with their emotional intensity.",
  },
  "sagittarius": {
    icon: <SagittariusIcon />,
    title: "Sagittarius",
    description: "Adventurous freethinkers, Sagittarians inspire with their optimism, philosophical wisdom, and irrepressible zest for life.",
  },
  "capricorn": {
    icon: <CapricornIcon />,
    title: "Capricorn",
    description: "Ambitious and disciplined, Capricorns achieve great things through dedication, responsibility, and unwavering commitment.",
  },
  "aquarius": {
    icon: <AquariusIcon />,
    title: "Aquarius",
    description: "Progressive and independent, Aquarians are innovative humanitarians, inspiring positive change with their unique vision.",
  },
  "pisces": {
    icon: <PiscesIcon />,
    title: "Pisces",
    description: "Imaginative and compassionate, Pisces are deeply intuitive, creative empaths with an innate capacity to heal and enchant others.",
  },
}