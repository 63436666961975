import { Button, Input } from "antd";
import { API, Auth, Hub } from "aws-amplify";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthUserContext, useCurrentUserContext } from "../../../../store";
import { Typography } from "antd";

import styles from "./SignInForm.module.css";

export default function SignInForm() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { signIn } = useAuthUserContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const signInWithRedirect = async () => {
    setLoading(true);
    setError("");

    if(!username) {
      setError("Username is required");
      setLoading(false);
      return;
    }
    if(!password) {
      setError("Password is required");
      setLoading(false);
      return;
    }

    const response = await signIn({ username, password });

    if(response.error) {
      const error = response.error;

      if(error.code) {
        setError("Incorrect username or password");
      }
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  const handleKeyDown = (event, nextInputId) => {
    if(event.key === 'Enter') {
      event.preventDefault();
      document.getElementById(nextInputId).focus();
    }
  };

  return (
    <div className={styles.shadowWrap}>
      <div className={styles.main}>
        <div className={styles.header}>Sign in to Celi</div>
        <div className={styles.field}>
          <div className={styles.labelActionWrap}>
            <div className={styles.label}>Username</div>
          </div>
          <Input
            placeholder="Username"
            id='input-1'
            value={username}
            allowClear
            size="large"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            onKeyDown={(e) => handleKeyDown(e, 'input-2')}
            autoFocus={true}
          />
        </div>
        <div className={styles.field}>
          <div className={styles.labelActionWrap}>
            <div className={styles.label}>Password</div>
            <div
              className={styles.action}
              onClick={() => navigate("/forgot-password")}
            >
              I forgot my password
            </div>
          </div>

          <Input.Password
            id='input-2'
            placeholder="Password"
            value={password}
            size="large"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </div>
        <div className={styles.error}>{error}</div>
        <div className={styles.buttonWrapper}>
          <Button
            size="large"
            type="primary"
            onClick={async () => {
              signInWithRedirect();
            }}
            disabled={!(username && password)}
            loading={loading}
          >
            Sign In
          </Button>
        </div>
      </div>
    </div>
  );
}
