import { Button, Drawer, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import EventDatePicker from "../../../../components/EventDatePicker/EventDatePicker";
import { API } from "aws-amplify";

import styles from "./EventCreator.module.css";
import { useMediaQuery } from 'react-responsive';

export enum Mode {
  CREATE = "CREATE",
  EDIT = "EDIT"
};

interface dataValues {
  option: "delete" | "edit";
  data?: any;
}

interface EventCreatorProps {
  setOpenModal?: (a: boolean) => void;
  mode?: Mode,
  defaultEvent?: any;
  eventType: string;
  onEventSubmit: (data?: dataValues) => void;
  isEditing?: boolean;
  setIsEditing?: (a: boolean) => void;
}

export default function EventCreator({
  setOpenModal,
  mode = Mode.CREATE,
  defaultEvent,
  eventType,
  onEventSubmit,
  isEditing,
  setIsEditing,
}: EventCreatorProps) {
  const isLaptopOrDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const [openDelete, setOpenDelete] = useState(false)

  const eventTypes = [
    {
      value: "BIRTHDAY",
      label: "Birthday",
      dateLabel: "My birthday is",
      description: "",
      fields: [],
    },
    {
      value: "ANNIVERSARY",
      label: "Anniversary",
      dateLabel: "My anniversary is",
      description: "",
      fields: [],
    },
    {
      value: "WORKIVERSARY",
      label: "Workiversary",
      dateLabel: "My workiversary is",
      description: "",
      fields: [
        {
          type: "input",
          key: "Company_Name",
          label: "I work at",
          placeholder: "Company name",
        },
      ],
    },
    {
      value: "SOBERVERSARY",
      label: "Soberversary",
      dateLabel: "My soberversary is",
      description: mode === Mode.CREATE && (
        <>
          A soberversary marks the date an individual began their
          sobriety or abstinence from drugs, alcohol, or other substances.
        </>
      ),

      fields: [],
    },
    {
      value: "REMEMBRANCE_DAY",
      label: "Remembrance Day",
      dateLabel: "Their remembrance day is",
      description: mode === Mode.CREATE && (
        <>
          Remembrance day's memorialize those who have passed. You can
          choose to remember them on their day of passing, birthday, or both.
        </>
      ),
      fields: [
        {
          type: "select",
          selectValues: [
            "friend",
            "mom",
            "dad",
            "brother",
            "sister",
            "grandmother",
            "grandfather",
            "daughter",
            "son",
            "child",
            "aunt",
            "uncle",
            "cousin",
            "relative",
            "husband",
            "wife",
            "girlfriend",
            "boyfriend",
            "partner",
            "pet"
          ],
          key: "Event_Subject_Relationship",
          label: "I am remembering my",
          placeholder: "Relationship",
        },
        {
          type: "input",
          key: "Event_Subject_First_Name",
          label: "Their name is",
          placeholder: "First name",
        },
      ],
    },
    {
      value: "ARRIVAL_DAY",
      label: "Arrival Day",
      dateLabel: "My arrival day is",
      description: mode === Mode.CREATE && (
        <>
          Arrival days mark the date an individual first arrived to their new
          country of residence.
        </>
      ),
      fields: [
        {
          type: "select",
          selectValues: [
            "Afghanistan",
            "Albania",
            "Algeria",
            "Andorra",
            "Angola",
            "Antigua and Barbuda",
            "Argentina",
            "Armenia",
            "Australia",
            "Austria",
            "Azerbaijan",
            "Bahamas",
            "Bahrain",
            "Bangladesh",
            "Barbados",
            "Belarus",
            "Belgium",
            "Belize",
            "Benin",
            "Bhutan",
            "Bolivia",
            "Bosnia and Herzegovina",
            "Botswana",
            "Brazil",
            "Brunei",
            "Bulgaria",
            "Burkina Faso",
            "Burundi",
            "Cabo Verde",
            "Cambodia",
            "Cameroon",
            "Canada",
            "Central African Republic",
            "Chad",
            "Chile",
            "China",
            "Colombia",
            "Comoros",
            "Congo (Congo-Brazzaville)",
            "Costa Rica",
            "Croatia",
            "Cuba",
            "Cyprus",
            "Czechia (Czech Republic)",
            "Democratic Republic of the Congo (Congo-Kinshasa)",
            "Denmark",
            "Djibouti",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "Egypt",
            "El Salvador",
            "Equatorial Guinea",
            "Eritrea",
            "Estonia",
            "Eswatini (fmr. \"Swaziland\")",
            "Ethiopia",
            "Fiji",
            "Finland",
            "France",
            "Gabon",
            "Gambia",
            "Georgia",
            "Germany",
            "Ghana",
            "Greece",
            "Grenada",
            "Guatemala",
            "Guinea",
            "Guinea-Bissau",
            "Guyana",
            "Haiti",
            "Holy See",
            "Honduras",
            "Hungary",
            "Iceland",
            "India",
            "Indonesia",
            "Iran",
            "Iraq",
            "Ireland",
            "Israel",
            "Italy",
            "Ivory Coast",
            "Jamaica",
            "Japan",
            "Jordan",
            "Kazakhstan",
            "Kenya",
            "Kiribati",
            "Kosovo",
            "Kuwait",
            "Kyrgyzstan",
            "Laos",
            "Latvia",
            "Lebanon",
            "Lesotho",
            "Liberia",
            "Libya",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Madagascar",
            "Malawi",
            "Malaysia",
            "Maldives",
            "Mali",
            "Malta",
            "Marshall Islands",
            "Mauritania",
            "Mauritius",
            "Mexico",
            "Micronesia",
            "Moldova",
            "Monaco",
            "Mongolia",
            "Montenegro",
            "Morocco",
            "Mozambique",
            "Myanmar (formerly Burma)",
            "Namibia",
            "Nauru",
            "Nepal",
            "Netherlands",
            "New Zealand",
            "Nicaragua",
            "Niger",
            "Nigeria",
            "North Korea",
            "North Macedonia (formerly Macedonia)",
            "Norway",
            "Oman",
            "Pakistan",
            "Palau",
            "Palestine State",
            "Panama",
            "Papua New Guinea",
            "Paraguay",
            "Peru",
            "Philippines",
            "Poland",
            "Portugal",
            "Qatar",
            "Romania",
            "Russia",
            "Rwanda",
            "Saint Kitts and Nevis",
            "Saint Lucia",
            "Saint Vincent and the Grenadines",
            "Samoa",
            "San Marino",
            "Sao Tome and Principe",
            "Saudi Arabia",
            "Senegal",
            "Serbia",
            "Seychelles",
            "Sierra Leone",
            "Singapore",
            "Slovakia",
            "Slovenia",
            "Solomon Islands",
            "Somalia",
            "South Africa",
            "South Korea",
            "South Sudan",
            "Spain",
            "Sri Lanka",
            "Sudan",
            "Suriname",
            "Sweden",
            "Switzerland",
            "Syria",
            "Tajikistan",
            "Tanzania",
            "Thailand",
            "Timor-Leste",
            "Togo",
            "Tonga",
            "Trinidad and Tobago",
            "Tunisia",
            "Turkey",
            "Turkmenistan",
            "Tuvalu",
            "Uganda",
            "Ukraine",
            "United Arab Emirates",
            "United Kingdom",
            "United States of America",
            "Uruguay",
            "Uzbekistan",
            "Vanuatu",
            "Venezuela",
            "Vietnam",
            "Yemen",
            "Zambia",
            "Zimbabwe",
          ],
          key: "Country",
          label: "I arrived to",
          placeholder: "Country",
        },
      ],
    },
    {
      value: "CITIZENSHIPVERSARY",
      label: "Citizenshipversary",
      dateLabel: "My citizenshipversary is",
      description: mode === Mode.CREATE &&
        "A citizenshipversary marks the day an individual became a citizen of a new country.",
      fields: [
        {
          type: "select",
          selectValues: [
            "Afghanistan",
            "Albania",
            "Algeria",
            "Andorra",
            "Angola",
            "Antigua and Barbuda",
            "Argentina",
            "Armenia",
            "Australia",
            "Austria",
            "Azerbaijan",
            "Bahamas",
            "Bahrain",
            "Bangladesh",
            "Barbados",
            "Belarus",
            "Belgium",
            "Belize",
            "Benin",
            "Bhutan",
            "Bolivia",
            "Bosnia and Herzegovina",
            "Botswana",
            "Brazil",
            "Brunei",
            "Bulgaria",
            "Burkina Faso",
            "Burundi",
            "Cabo Verde",
            "Cambodia",
            "Cameroon",
            "Canada",
            "Central African Republic",
            "Chad",
            "Chile",
            "China",
            "Colombia",
            "Comoros",
            "Congo (Congo-Brazzaville)",
            "Costa Rica",
            "Croatia",
            "Cuba",
            "Cyprus",
            "Czechia (Czech Republic)",
            "Democratic Republic of the Congo (Congo-Kinshasa)",
            "Denmark",
            "Djibouti",
            "Dominica",
            "Dominican Republic",
            "Ecuador",
            "Egypt",
            "El Salvador",
            "Equatorial Guinea",
            "Eritrea",
            "Estonia",
            "Eswatini (fmr. \"Swaziland\")",
            "Ethiopia",
            "Fiji",
            "Finland",
            "France",
            "Gabon",
            "Gambia",
            "Georgia",
            "Germany",
            "Ghana",
            "Greece",
            "Grenada",
            "Guatemala",
            "Guinea",
            "Guinea-Bissau",
            "Guyana",
            "Haiti",
            "Holy See",
            "Honduras",
            "Hungary",
            "Iceland",
            "India",
            "Indonesia",
            "Iran",
            "Iraq",
            "Ireland",
            "Israel",
            "Italy",
            "Ivory Coast",
            "Jamaica",
            "Japan",
            "Jordan",
            "Kazakhstan",
            "Kenya",
            "Kiribati",
            "Kosovo",
            "Kuwait",
            "Kyrgyzstan",
            "Laos",
            "Latvia",
            "Lebanon",
            "Lesotho",
            "Liberia",
            "Libya",
            "Liechtenstein",
            "Lithuania",
            "Luxembourg",
            "Madagascar",
            "Malawi",
            "Malaysia",
            "Maldives",
            "Mali",
            "Malta",
            "Marshall Islands",
            "Mauritania",
            "Mauritius",
            "Mexico",
            "Micronesia",
            "Moldova",
            "Monaco",
            "Mongolia",
            "Montenegro",
            "Morocco",
            "Mozambique",
            "Myanmar (formerly Burma)",
            "Namibia",
            "Nauru",
            "Nepal",
            "Netherlands",
            "New Zealand",
            "Nicaragua",
            "Niger",
            "Nigeria",
            "North Korea",
            "North Macedonia (formerly Macedonia)",
            "Norway",
            "Oman",
            "Pakistan",
            "Palau",
            "Palestine State",
            "Panama",
            "Papua New Guinea",
            "Paraguay",
            "Peru",
            "Philippines",
            "Poland",
            "Portugal",
            "Qatar",
            "Romania",
            "Russia",
            "Rwanda",
            "Saint Kitts and Nevis",
            "Saint Lucia",
            "Saint Vincent and the Grenadines",
            "Samoa",
            "San Marino",
            "Sao Tome and Principe",
            "Saudi Arabia",
            "Senegal",
            "Serbia",
            "Seychelles",
            "Sierra Leone",
            "Singapore",
            "Slovakia",
            "Slovenia",
            "Solomon Islands",
            "Somalia",
            "South Africa",
            "South Korea",
            "South Sudan",
            "Spain",
            "Sri Lanka",
            "Sudan",
            "Suriname",
            "Sweden",
            "Switzerland",
            "Syria",
            "Tajikistan",
            "Tanzania",
            "Thailand",
            "Timor-Leste",
            "Togo",
            "Tonga",
            "Trinidad and Tobago",
            "Tunisia",
            "Turkey",
            "Turkmenistan",
            "Tuvalu",
            "Uganda",
            "Ukraine",
            "United Arab Emirates",
            "United Kingdom",
            "United States of America",
            "Uruguay",
            "Uzbekistan",
            "Vanuatu",
            "Venezuela",
            "Vietnam",
            "Yemen",
            "Zambia",
            "Zimbabwe",
          ],
          key: "Country",
          label: "I'm a citizen of",
          placeholder: "Country",
        },
      ],
    },
    {
      value: "CHILD_BIRTHDAY",
      label: "Child's Birthday",
      dateLabel: "My child's birthday is",
      description: "",
      fields: [
        {
          type: "select",
          selectValues: [
            "daughter",
            "son",
            "stepdaughter",
            "stepson",
            "foster daughter",
            "foster son",
            "granddaughter",
            "grandson",
          ],
          key: "Event_Subject_Child_Relationship",
          label: "Relationship",
          placeholder: "Select",
        },
        {
          type: "input",
          key: "Event_Subject_Child_First_Name",
          label: "My child's name is",
          placeholder: "First name",
        },
      ],
    },
    {
      value: "PET_BIRTHDAY",
      label: "Pet's Birthday",
      dateLabel: "My pet's birthday is",
      description: "",
      fields: [
        {
          type: "select",
          selectValues: [
            "dog",
            "cat",
            "fish",
            "bird",
            "rabbit",
            "guinea pig",
            "hamster",
            "turtle",
            "snake",
            "lizard",
            "frog",
            "rat",
            "mouse",
            "gerbil",
            "chicken",
            "horse",
            "pig",
            "goat",
            "gecko",
            "ferret",
            "llama",
            "alpaca",
          ],
          key: "Pet_Type",
          label: "My pet is a",
          placeholder: "Select",
        },
        {
          type: "input",
          key: "Pet_Name",
          label: "My pet's name is",
          placeholder: "Name",
        },
      ],
    },
    {
      value: "HEALTHVERSARY",
      label: "Healthversary",
      dateLabel: "My healthversary is",
      description: mode === Mode.CREATE &&
        "A healthversary marks the anniversary of a meaningful health milestone.",
      fields: [
        {
          type: "input",
          key: "Description",
          label: "The healthversary I'm celibrating is",
          placeholder: "ex: my kidney transplant",
        },
      ],
    },
    {
      value: "OUTVERSARY",
      label: "Outversary",
      dateLabel: "My outversary is",
      description: mode === Mode.CREATE && (isLaptopOrDesktop ? (
        <>
          An outversary is the anniversary of when someone openly declared or
          revealed their sexual orientation or other aspect of their
          authentic self to others.
        </>
      ) : (
        <>
          An outversary is when someone openly declared or revealed their sexual
          orientation, or other aspect of their authentic self to others.
        </>
      )),

      fields: [],
    },
    {
      value: "REBIRTHDAY",
      label: "Rebirthday",
      dateLabel: "My rebirthday is",
      description: mode === Mode.CREATE &&
        "A rebirthday is the day a person transitioned or came out as their true gender identity.",
      fields: [],
    },
    {
      value: "CHILD_ADOPTIONVERSARY",
      label: "Child Adoptionversary",
      dateLabel: "My adoptionversary is",
      description: mode === Mode.CREATE && (
        <>
          A child adoptionversary is the date a child was adopted
          by their forever family.
        </>
      ),

      fields: [],
    },
    {
      value: "PARENT_ADOPTIONVERSARY",
      label: "Parent Adoptionversary",
      dateLabel: "My parent adoptionversary is",
      description: mode === Mode.CREATE && (isLaptopOrDesktop ? (
        <>
          A parent adoptionversary is the date an adopting parent
          became a forever parent to their child.
        </>
      ) : (
        <>
          A parent adoptionversary is the date an adopting parent
          became a forever parent.
        </>
      ))
      ,
      fields: [
        {
          type: "select",
          selectValues: [
            "son",
            "daughter",
          ],
          key: "Child_Ralationship",
          label: "Relationship",
          placeholder: "Select",
        },
        {
          type: "input",
          key: "Event_Subject_Child_Name",
          label: "My child's name is",
          placeholder: "First name",
        },
      ],
    },
    {
      value: "FOUNDER_DAY",
      label: "Founders Day",
      dateLabel: "My founder's day is",
      description: mode === Mode.CREATE &&
        "A founder's day marks the date an entrepreneur started their company or organization.",
      fields: [
        {
          type: "input",
          key: "Company_Name",
          label: "My company or organization's name is",
          placeholder: "e.g. Google",
        },
      ],
    },
  ];

  const event = eventTypes.find((e: any) => e.value === eventType);

  const [loading, setLoading] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<Date>(new Date(defaultEvent?.date))
  const [month, setMonth] = useState(mode === Mode.EDIT ? dateSelected?.getMonth() + 1 : 0);
  const [day, setDay] = useState(mode === Mode.EDIT ? dateSelected?.getDate() : 0);
  const [year, setYear] = useState(mode === Mode.EDIT ? dateSelected?.getFullYear() : 0);

  const [fields, setFields] = useState(
    defaultEvent?.fields ||
    event?.fields.reduce((acc: any, field) => {
      acc[field.key] = "";
      return acc;
    }, {})
  );

  useEffect(() => {
    if(defaultEvent) {
      setDateSelected(new Date(defaultEvent?.date))
      setMonth(new Date(defaultEvent?.date)?.getUTCMonth() + 1);
      setDay(new Date(defaultEvent?.date)?.getUTCDate());
      setYear(new Date(defaultEvent?.date)?.getUTCFullYear());
      setFields(defaultEvent?.fields)
    }
  }, [defaultEvent, defaultEvent?.date])

  useEffect(() => {
    document.getElementById("firstInput")?.focus()
  }, [])

  // TODO: check this
  const submit = async () => {
    // check that all fields are filled out and valid
    setLoading(true);
    const date = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`
    // send actual event here
    try {
      const apiName = "api";

      let res;
      let data;
      if(defaultEvent) {
        const path = `/api/events/${defaultEvent?.id}`;

        const init = {
          body: {
            id: defaultEvent.id,
            fields: fields,
            date: date,
            _version: defaultEvent._version,
          },
        };
        res = await API.post(apiName, path, init);
        res.body.data.updateEvent.fields = JSON.parse(res.body.data.updateEvent.fields)
        data = res.body.data.updateEvent
      } else {
        const path = `/api/events/currentUser`;

        const init = {
          body: {
            eventType,
            date: date,
            fields: JSON.stringify(fields),
            status: "PUBLIC",
          },
        };
        res = await API.post(apiName, path, init);
      }

      onEventSubmit && onEventSubmit({ option: "edit", data });
    } catch(error) {
      setLoading(false);
    } finally {
      setLoading(false)
    }
  };

  const deleteEvent = async () => {
    setLoading(true);
    // send actual event here
    try {
      const apiName = "api";
      const path = `/api/events/${defaultEvent?.id}`;
      const init = {
        body: {
          _version: defaultEvent._version,
        },
      };

      const res = await API.del(apiName, path, init);
      const data = await res.data;

      setLoading(false)
      onEventSubmit && onEventSubmit({ option: "delete", data });
    } catch(error) {
      setLoading(false);
    } finally {
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>{event?.label}</div>
      {event?.description !== "" && <div className={styles.description}>{event?.description}</div>}
      <div className={mode === Mode.CREATE ? styles.content : styles.contentEdit}>
        <div className={styles.inputWrap}>
          {event?.fields.map((field: any, index: number) => {
            return (<div style={{ flex: 1 }}>
              <div className={styles.label}>{field.label}</div>

              {field.type === "input" && (
                <>
                  <Input
                    size="large"
                    id={index === 0 && "firstInput"}
                    placeholder={field.placeholder}
                    disabled={mode === Mode.EDIT ? !isEditing : false}
                    value={defaultEvent ? fields[field.key as keyof typeof fields] : undefined}
                    onChange={(event) => {
                      setFields({ ...fields, [field.key]: event.target.value });
                    }}
                    maxLength={event.value === 'HEALTHVERSARY' ? 35 : undefined}
                  />
                  {event.value === 'HEALTHVERSARY' && (
                    <div className={styles.helpDescription}>
                      <div>

                      </div>
                      <div>
                        <span>{fields[field.key as keyof typeof fields]?.length}</span> / 35
                      </div>
                    </div>
                  )}
                </>
              )}

              {field.type === "select" && (
                <Select
                  size="large"
                  id={index === 0 && "firstInput"}
                  style={{ width: "100%" }}
                  options={field.selectValues.map((value: string) => {
                    return { value };
                  })}
                  disabled={mode === Mode.EDIT ? !isEditing : false}
                  placeholder={field.placeholder}
                  value={defaultEvent ? fields[field.key as keyof typeof fields] : undefined}
                  onChange={(value) => {
                    setFields({ ...fields, [field.key]: value });
                  }}
                  showSearch={field.key === "Country"}
                />
              )}
            </div>);
          })}
          <EventDatePicker
            mode={mode}
            isEditing={isEditing}
            dateLabel={event?.dateLabel}
            month={month}
            day={day}
            year={year}
            setMonth={setMonth}
            setDay={setDay}
            setYear={setYear}
            firstInput={event?.fields.length === 0}
          />
        </div>
      </div>
      {defaultEvent ? (
        <div className={mode === Mode.CREATE ? styles.contentSave : styles.contentSaveEdit}>
          {eventType !== "BIRTHDAY" && <Button
            className={mode === Mode.CREATE ? styles.saveButton : styles.saveButtonEdit}
            loading={loading}
            size="large"
            onClick={() => {
              setOpenModal(false)
              setOpenDelete(true)

            }}
            disabled={Object.values(fields).includes("")}
          >
            Delete
          </Button>}
          {!isEditing ? <Button
            className={mode === Mode.CREATE ? styles.saveButton : styles.saveButtonEdit}
            loading={loading}
            type="primary"
            size="large"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button> : <Button
            className={mode === Mode.CREATE ? styles.saveButton : styles.saveButtonEdit}
            loading={loading}
            type="primary"
            size="large"
            onClick={submit}
            disabled={Object.values(fields).includes("")}
          >
            Save
          </Button>}
        </div>
      ) : (
        <div className={mode === Mode.CREATE ? styles.contentSave : styles.contentSaveEdit}>
          <Button
            className={mode === Mode.CREATE ? styles.saveButton : null}
            loading={loading}
            type="primary"
            size="large"
            onClick={submit}
            disabled={((Object.values(fields).includes('')) || (day === 0 || month === 0 || year === 0))}
          >
            Save
          </Button>
        </div>
      )}


      {isLaptopOrDesktop ?
        <Modal
          open={openDelete}
          onCancel={() => setOpenDelete(false)}
          title={<span style={{
            fontStyle: "var(--primary-font-family)",
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "36px"
          }}>Are you sure?</span>}
          footer={
            <div className={styles.contentSaveEdit}>
              <Button
                className={styles.saveButtonEdit}
                loading={loading}
                size="large"
                onClick={() => { deleteEvent().then(() => setOpenDelete(false)) }}
              >
                Delete date
              </Button>
              <Button
                className={styles.saveButtonEdit}
                loading={loading}
                type="primary"
                size="large"
                onClick={() => setOpenDelete(false)}
              >
                Cancel
              </Button>
            </div>
          }
        >
          <p style={{
            fontStyle: "var(--primary-font-family)",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "30px"
          }}>
            Deleting a Celi date is permanent. You will have to add a new date if you change your mind later.
          </p>
        </Modal> :
        <Drawer
          open={openDelete}
          placement="bottom"
          height="initial"
          onClose={() => setOpenDelete(false)}
          className={styles.drawerTitle}
          closeIcon={
            <></>
          }
        >
          <div style={{
            paddingBottom: "40px"
          }}>
            <div style={{
              padding: "0 20px",
              gap: "20px",
              display: "flex",
              flexDirection: "column",
              textAlign: "center"
            }}>
              <span style={{
                fontStyle: "var(--primary-font-family)",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "36px"
              }}>Are you sure?</span>
              <p style={{
                fontStyle: "var(--primary-font-family)",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px"
              }}>
                Deleting a Celi date is permanent. You will have to add a new date if you change your mind later.
              </p>
              <Button
                className={styles.saveButtonEdit}
                loading={loading}
                block
                size="large"
                onClick={() => { deleteEvent().then(() => setOpenDelete(false)) }}
              >
                Delete date
              </Button>
              <Button
                className={styles.saveButtonEdit}
                loading={loading}
                block
                type="primary"
                size="large"
                onClick={() => setOpenDelete(false)}
              >
                Cancel
              </Button>
            </div>

          </div>


        </Drawer>}
    </div>
  );
}
