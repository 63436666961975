import React from "react";

export default function WordsOfAffirmationIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66068 17.2895C2.01741 16.9391 2.28907 16.5545 2.46045 16.1013C2.6331 15.6443 2.69658 15.1707 2.67754 14.6845C2.66865 14.4675 2.59248 14.2897 2.45411 14.1209C1.56801 13.0342 0.985324 11.8028 0.688266 10.4318C-0.0175645 7.17049 1.30015 3.68957 3.98891 1.71553C5.71921 0.446056 7.65897 -0.140433 9.79424 0.0284079C12.4043 0.235333 14.5154 1.40833 16.0921 3.50043C17.3603 5.18249 17.9379 7.0994 17.7805 9.19531C17.5482 12.2916 16.0477 14.6312 13.3729 16.1965C11.9181 17.047 10.3249 17.3809 8.64536 17.2895C7.47871 17.226 6.36918 16.9315 5.32313 16.4098C5.05147 16.2739 4.80391 16.2904 4.54748 16.4529C3.71216 16.981 2.79687 17.2654 1.80795 17.3047C1.77748 17.3047 1.74574 17.3073 1.71527 17.306C1.70511 17.306 1.69623 17.3009 1.66068 17.2895ZM13.8439 4.64423V4.63915C13.8375 4.65184 13.8299 4.66454 13.8223 4.67724L13.8299 4.6747C13.7829 4.74071 13.7372 4.80672 13.6865 4.87908C13.6268 4.83465 13.576 4.82704 13.6369 4.72802C13.6839 4.65312 13.7791 4.51729 13.6052 4.42843C12.271 3.74418 10.952 3.6934 9.65586 4.52237C9.50479 4.61885 9.36515 4.73436 9.21662 4.84226C9.19758 4.8283 9.1798 4.81942 9.16457 4.80546C8.72787 4.43477 8.23912 4.15675 7.68055 4.02472C6.35267 3.70989 5.15175 4.02853 4.16156 4.92859C2.89335 6.08128 2.5087 7.54626 2.96317 9.19912C3.20945 10.0954 3.75279 10.8355 4.36848 11.5172C5.73698 13.0317 7.37588 14.2009 9.14172 15.2025C9.18869 15.2292 9.28009 15.2139 9.33214 15.1847C10.382 14.6058 11.3684 13.9317 12.3015 13.1802C13.2574 12.4109 14.1384 11.5692 14.829 10.5448C15.6973 9.25751 15.9245 7.88774 15.3393 6.43038C15.0397 5.68266 14.6094 5.02127 13.8515 4.64423L13.8464 4.64169L13.8413 4.64423H13.8439Z" fill="#171717" fill-opacity="0.9" />
      <path d="M13.8535 4.64427C14.6114 5.02131 15.0405 5.68397 15.3413 6.43042C15.9266 7.88778 15.6981 9.25755 14.831 10.5448C14.1404 11.5693 13.2594 12.4109 12.3035 13.1802C11.3704 13.9318 10.384 14.6059 9.33416 15.1848C9.28211 15.214 9.18944 15.2279 9.14374 15.2025C7.37789 14.2009 5.74027 13.0304 4.3705 11.5172C3.7548 10.8368 3.21147 10.0954 2.96519 9.19916C2.51071 7.5463 2.8941 6.08132 4.16357 4.92863C5.15377 4.02984 6.35469 3.7112 7.68257 4.02477C8.24114 4.15679 8.72989 4.43481 9.16659 4.8055C9.18182 4.81819 9.1996 4.82834 9.21864 4.8423C9.36717 4.73313 9.50681 4.61889 9.65787 4.52241C10.954 3.69344 12.273 3.74295 13.6072 4.42847C13.7811 4.51733 13.6847 4.65316 13.639 4.72806C13.578 4.82581 13.6301 4.83469 13.6885 4.87912C13.7392 4.80803 13.785 4.74202 13.8319 4.67474L13.8294 4.68236L13.8243 4.67728C13.8319 4.66458 13.8395 4.65188 13.8459 4.63919L13.8548 4.64427H13.8535ZM9.22244 7.08294C9.06757 6.85316 8.92285 6.64117 8.78194 6.42535C8.18528 5.50752 7.28141 5.11398 6.28741 5.31456C4.38827 5.69794 3.74718 7.79765 4.35273 9.10014C4.65105 9.74376 5.06617 10.2998 5.5473 10.8101C6.60732 11.9336 7.84252 12.835 9.14754 13.6461C9.18816 13.6715 9.2783 13.6588 9.32147 13.6296C9.97398 13.1828 10.6405 12.7512 11.2688 12.2726C12.1905 11.5705 13.0398 10.7898 13.6923 9.81994C14.1277 9.1725 14.4095 8.48064 14.2851 7.67452C14.129 6.66147 13.6339 5.89343 12.6742 5.47831C11.5519 4.9921 10.4158 5.32852 9.72389 6.33775C9.55759 6.57895 9.39763 6.82396 9.22244 7.08548V7.08294Z" fill="#171717" fill-opacity="0.9" />
      <path d="M13.8242 4.67722L13.828 4.68231L13.8318 4.67468L13.8242 4.67722Z" fill="white" />
      <path d="M13.8539 4.64427L13.845 4.63919L13.8438 4.64427L13.8488 4.64173L13.8539 4.64427Z" fill="white" />
    </svg>
  );
}
