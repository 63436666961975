import React from "react";

import styles from "./ProfileUser.module.css";
import UserAvatar from "../../../../components/UserAvatar/UserAvatar";
import UserSocials from "../../../../components/UserSocials/UserSocials";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useCurrentUserContext } from "../../../../store";
import { Button } from "antd";

interface ProfileUserProps {
  user: {
    firstName: string;
    lastName: string;
    username: string;
    instagram: string;
    linkedIn: string;
    venmo: string;
    avatarUrl: string;
  };
  tab?: string;
}

export default function ProfileUser({ user, tab }: ProfileUserProps) {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUserContext();

  return (
    <div className={styles.profileUser}>
      <div className={styles.userAvatarWrap}>
        <UserAvatar imageUrl={user.avatarUrl} user={user} />
      </div>
      <div className={styles.userTextInfo}>
        <div className={styles.userFullName}>
          {user.firstName + " " + user.lastName}
        </div>
        <div className={styles.userUsernameWrap}>
          <div className={styles.userUsername}>@{user.username}</div>
          {user.username === currentUser.username && <Button
            className={styles.usernamePreviewButton}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <path
                  d="M0 12.6671V16H3.33287L13.1626 6.17025L9.82975 2.83738L0 12.6671ZM15.74 3.59283C16.0867 3.24622 16.0867 2.68629 15.74 2.33967L13.6603 0.259964C13.3137 -0.0866546 12.7538 -0.0866546 12.4072 0.259964L10.7807 1.8864L14.1136 5.21927L15.74 3.59283Z"
                  fill="#C4C4C4"
                />
              </svg>
            }
            onClick={() => {
              navigate(`/@/${currentUser.username}/edit`, {
                state: {
                  currentTab: tab
                }
              });
            }}
          />}
        </div>
        <div className={styles.userSocialsWrap}>
          {/* <UserSocials
            instagram={user.instagram}
            linkedIn={user.linkedIn}
            venmo={user.venmo}
          /> */}
        </div>
      </div>
    </div>
  );
}
