export function AWSDatetoDisplayDate(inputDate: string) {
  // Parse the input date assuming it's in UTC
  // Splitting the input string to get year, month, and day
  const parts = inputDate.split("-");
  const year = parseInt(parts[0], 10);
  const monthIndex = parseInt(parts[1], 10) - 1; // Month is zero-indexed
  const day = parseInt(parts[2], 10);

  // Create a new Date object in UTC
  const dateObject = new Date(Date.UTC(year, monthIndex, day));

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the month and day from the UTC date
  const month = monthNames[dateObject.getUTCMonth()];
  const dayOfMonth = dateObject.getUTCDate();

  return `${month} ${dayOfMonth}`;
}
