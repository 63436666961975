import React from "react";

export default function CancerIcon({ size = "1em" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 19.5C14.314 19.5 17.603 17.2525 19.1149 14.0097C19.6685 13.3234 20 12.4504 20 11.5C20 9.29086 18.2091 7.5 16 7.5C13.7909 7.5 12 9.29086 12 11.5C12 13.7091 13.7909 15.5 16 15.5C16.3853 15.5 16.7579 15.4455 17.1105 15.3438C15.5522 17.2691 13.1699 18.5 10.5 18.5C8.4935 18.5 6.64935 17.8047 5.19535 16.642C4.98504 16.4042 4.72298 16.1284 4.60911 16.0863C4.51329 16.0508 4.4131 16.0314 4.32528 16.0314C4.04914 16.0314 3.82528 16.2552 3.82528 16.5314C3.82528 16.6928 3.90175 16.8363 4.02042 16.9277L4.00378 16.9318C5.70271 18.5246 7.98746 19.5 10.5 19.5ZM18.2692 13.4624C18.7245 12.9364 19 12.2504 19 11.5C19 9.84315 17.6569 8.5 16 8.5C14.3431 8.5 13 9.84315 13 11.5C13 13.1569 14.3431 14.5 16 14.5C16.902 14.5 17.711 14.1019 18.261 13.4719L18.2647 13.4635L18.2692 13.4624Z" fill="#171717" fill-opacity="0.9" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.5C14.2252 0.5 17.4495 2.64403 19.0064 5.76539C19.0064 5.67365 19.0204 5.82656 19.0064 5.76539C19.0064 6.04154 19.0617 6.5 18.5 6.5C18.2239 6.5 18 6 18 6C18 5.99762 17.9994 5.99528 17.9983 5.99318C16.5663 3.31902 13.7456 1.5 10.5 1.5C7.23055 1.5 4.39221 3.34593 2.97036 6.05245C3.56535 5.70141 4.25916 5.5 5 5.5C7.20914 5.5 9 7.29086 9 9.5C9 11.7091 7.20914 13.5 5 13.5C3.00607 13.5 1.35289 12.0411 1.04972 10.1324L1.00112 10.1443C1.0004 10.0963 1.00005 10.0482 1.00005 10C1.00005 9.89871 1.00163 9.79778 1.00478 9.69725C1.00161 9.6319 1 9.56614 1 9.5C1 9.18699 1.03595 8.88237 1.10396 8.59005C1.78509 4.012 5.73243 0.5 10.5 0.5ZM2.00571 9.68668C2.1021 11.2566 3.40583 12.5 5 12.5C6.65686 12.5 8 11.1569 8 9.5C8 7.84314 6.65686 6.5 5 6.5C3.59129 6.5 2.40936 7.47094 2.08693 8.78012C2.04417 9.07761 2.01682 9.38007 2.00571 9.68668Z" fill="#171717" fill-opacity="0.9" />
      <path d="M19.0064 5.76539C17.4495 2.64403 14.2252 0.5 10.5 0.5C5.73243 0.5 1.78509 4.012 1.10396 8.59005C1.03595 8.88237 1 9.18699 1 9.5C1 9.56614 1.00161 9.6319 1.00478 9.69725C1.00163 9.79778 1.00005 9.89871 1.00005 10C1.00005 10.0482 1.0004 10.0963 1.00112 10.1443L1.04972 10.1324C1.35289 12.0411 3.00607 13.5 5 13.5C7.20914 13.5 9 11.7091 9 9.5C9 7.29086 7.20914 5.5 5 5.5C4.25916 5.5 3.56535 5.70141 2.97036 6.05245C4.39221 3.34593 7.23055 1.5 10.5 1.5C13.7456 1.5 16.5663 3.31902 17.9983 5.99318C17.9994 5.99528 18 5.99762 18 6V6C18 6 18.2239 6.5 18.5 6.5C19.0617 6.5 19.0064 6.04154 19.0064 5.76539ZM19.0064 5.76539C19.0064 5.67365 19.0204 5.82656 19.0064 5.76539ZM19 11.5C19 12.2504 18.7245 12.9364 18.2692 13.4624L18.2647 13.4635L18.261 13.4719C17.711 14.1019 16.902 14.5 16 14.5C14.3431 14.5 13 13.1569 13 11.5C13 9.84315 14.3431 8.5 16 8.5C17.6569 8.5 19 9.84315 19 11.5ZM19.1149 14.0097C17.603 17.2525 14.314 19.5 10.5 19.5C7.98746 19.5 5.70271 18.5246 4.00378 16.9318L4.02042 16.9277C3.90175 16.8363 3.82528 16.6928 3.82528 16.5314C3.82528 16.2552 4.04914 16.0314 4.32528 16.0314C4.4131 16.0314 4.51329 16.0508 4.60911 16.0863C4.72298 16.1284 4.98504 16.4042 5.19535 16.642C6.64935 17.8047 8.4935 18.5 10.5 18.5C13.1699 18.5 15.5522 17.2691 17.1105 15.3438C16.7579 15.4455 16.3853 15.5 16 15.5C13.7909 15.5 12 13.7091 12 11.5C12 9.29086 13.7909 7.5 16 7.5C18.2091 7.5 20 9.29086 20 11.5C20 12.4504 19.6685 13.3234 19.1149 14.0097ZM5 12.5C3.40583 12.5 2.1021 11.2566 2.00571 9.68668C2.01682 9.38007 2.04417 9.07761 2.08693 8.78012C2.40936 7.47094 3.59129 6.5 5 6.5C6.65686 6.5 8 7.84314 8 9.5C8 11.1569 6.65686 12.5 5 12.5Z" stroke="#171717" stroke-opacity="0.9" />
    </svg>
  );
}