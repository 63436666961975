
import React from "react";
import styles from "./Error.module.css";
import Header from "../../components/header/Header";
import { useAuthUserContext, useCurrentUserContext } from "../../store";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";


import { useMediaQuery } from "react-responsive";
import { Button } from 'antd';

export default function Error() {
  // const { authUser } = useAuthUserContext();
  // const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });


  return (
    <div className="">
      {/* <Header avatar signIn={!authUser} /> */}
      <Content className={styles.main}>
        {isDesktopOrLaptop ?
          <>
            <div style={{ width: "30%" }}>
              <img src="./error_page_plug.png" alt="error_plug" style={{ height: "100vh" }} />
            </div>
            <div style={{ width: "40%" }} className={styles.errorWrap}>
              <div className={styles.content}>
                <h1 className={styles.header}>party foul</h1>
                <p className={styles.description}>Our servers have been caught celi-brating on the job.<br />
                  We blew out their candles, and are working on a fix. </p>
                <Button type="primary" size="large" onClick={() => window.open(window.location.origin, "_self")}>Go to dashboard</Button>
              </div>
            </div>
            <div style={{ width: "30%" }}></div>
          </> :
          <>
            <div className={styles.errorWrap}>
              <div className={styles.content}>
                <h1 className={styles.header}>party foul</h1>
                <p className={styles.description}>Our servers were caught celi-brating on the job.<br />
                  We blew out their candles, <br />
                  and are working on a fix.
                </p>
                <Button type="primary" size="large" onClick={() => window.open(window.location.origin, "_self")}>Go to dashboard</Button>
              </div>
            </div>
            <div>
              <img src="./error_page_plug_mobile.png" alt="error_plug" style={{ width: "100%" }} />
            </div>
          </>}
      </Content>
    </div>
  );
}
