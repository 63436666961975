// BackButton.js

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./BackButton.module.css";
import { useMediaQuery } from 'react-responsive';

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

  return (
    <Button
      className={styles.button}
      icon={<ArrowLeftOutlined style={{ fontSize: isDesktopOrLaptop ? "30px" : "24px" }} />}
      onClick={goBack}
    />
  );
};

export default BackButton;
