import { Button } from "antd";
import React, { useState } from "react";
import { acceptConnection } from "../../../api/api";

import styles from "./AcceptRequestButton.module.css";
import { useCurrentUserContext } from '../../../store';

export default function AcceptRequestButton({ connection, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const { currentUser, setCurrentUser, loadUser } = useCurrentUserContext();

  const handleAcceptConnection = async () => {
    setLoading(true);
    try {
      const response = await acceptConnection(connection);
      let updateConnection = null;
      if(response.body) {
        updateConnection = response.body.data.updateConnection
      }
      if(response.data) {
        updateConnection = response.data.updateConnection
      }
      // setCurrentUser({
      //   ...currentUser,
      //   connectors: {
      //     items: currentUser?.connectors?.items?.map((connector) => {
      //       if(updateConnection.id = connector.id) {
      //         connector.status = "ACTIVE";
      //       }
      //       return connector
      //     })
      //   },
      //   connections: {
      //     items: [...currentUser?.connections?.items, currentUser?.connectors?.items.find((connector) => {
      //       if(connector.id === updateConnection.id) {
      //         connector.status = "ACTIVE";
      //         return true;
      //       }
      //     })]
      //   }
      // })
      loadUser()
      onSuccess && onSuccess();
    } catch(e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      className={styles.acceptButton}
      onClick={() => handleAcceptConnection()}
      loading={loading}
    >
      Accept
    </Button>
  );
}
