export default function formatMonthDay(input) {
  const [month, day] = input.split("-");
  const date = new Date(`2000-${month}-${day}`);

  const monthStringFull = date.toLocaleDateString("en-US", { month: "long", timeZone: "UTC" });
  const monthStringShort = date.toLocaleDateString("en-US", { month: "short", timeZone: "UTC" });

  const formattedFull = `${monthStringFull} ${day.replace(/^0+/, "")}`;
  const formattedShort = `${monthStringShort} ${day.replace(/^0+/, "")}`;

  return { formattedFull, formattedShort };
}
