import { Avatar, Button, List } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ListItemUser from "../ListItemUser/ListItemUser";

import styles from "./UserList.module.css";
import ListItemWrap from "../ListItemWrap/ListItemWrap";
import { useMediaQuery } from "react-responsive";
import { ConnectionStatus } from "../../models";
import { useCurrentUserContext } from "../../store";
import AddConnectionButton from "../buttons/AddConnectionButton/AddConnectionButton";
import PendingConnectionButton from "../buttons/PendingConnectionButton/PendingConnectionButton";

interface UserListUserProps {
  user: {
    firstName: string;
    lastName: string;
    username: string;
  };
  mutualConnections?: number;
  connection?: {
    id: string;
    _version: number;
    status: string;
    receivingId: string;
  };
}

interface UserListProps {
  users: UserListUserProps[];
  onAddConnection?: () => void;
  mode?: string
}

export default function UserList({ users, onAddConnection, mode }: UserListProps) {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const { currentUser, loadUser } = useCurrentUserContext();

  const navigate = useNavigate();

  return (
    <div>
      <List>
        {users.map((user) => {
          if(user.user?.username === currentUser?.username) return null;
          const connectionStatus = currentUser.connections.items.find(item => item.receivingId === user.connection.receivingId)?.status;
          return (
            <List.Item key={user.user?.username}>
              <ListItemWrap>
                <div className={styles.userWrap}>
                  <ListItemUser user={user.user} mutualConnections={user.mutualConnections} connectionStatus={connectionStatus} />
                </div>
                <div className={styles.buttonColumn}>
                  {!connectionStatus ||
                    connectionStatus === ConnectionStatus.DECLINED ? (
                    <AddConnectionButton
                      user={user.user}
                      mode={mode}
                      onSuccess={() => {
                        onAddConnection();
                      }}
                    />
                  ) : (
                    (connectionStatus == ConnectionStatus.ACTIVE &&
                      isDesktopOrLaptop && (
                        <Button
                          onClick={() => navigate("/@/" + user.user?.username)}
                          className={styles.viewButton}
                          type="primary"
                          ghost
                        >
                          View
                        </Button>
                      )) ||
                    (connectionStatus == ConnectionStatus.REQUESTED &&
                      (
                        <PendingConnectionButton
                          mode={mode}
                          user={user.user}
                          connection={user.connection}
                          onDecline={() => {
                            loadUser()
                            onAddConnection()
                          }}
                        />
                      ))
                  )}
                </div>
              </ListItemWrap>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
}
