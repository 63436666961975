import React from "react";
import UserList from "../../../../components/UserList/UserList";

import styles from "./ProfileConnections.module.css";
import { Button } from "antd";
import UserSearchIcon from "../../../../components/icons/UserSearchIcon/UserSearchIcon";
import { useNavigate } from "react-router-dom";
import { ConnectionStatus } from "../../../../models";

interface ProfileConnectionProps {
  user: {
    firstName: string;
    lastName: string;
    username: string;
  };
  mutualConnections: number;
  connection?: {
    id: string;
    _version: number;
    status: string;
    receivingId: string;
  };
}

interface ProfileConnectionsProps {
  connections: ProfileConnectionProps[];
  onAddConnection?: () => void
}

export default function ProfileConnections({
  connections,
  onAddConnection,
}: ProfileConnectionsProps) {

  return (
    <div className={styles.main}>
      <UserList
        mode='view'
        users={connections.map((connection) => {
          return {
            user: connection.user,
            mutualConnections: connection?.mutualConnections || 0,
            connection: connection.connection,
          };
        })}
      />
    </div>
  );
}
