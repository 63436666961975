import { Button, Image, Input } from "antd";
import React, { useState } from "react";
import { useAuthUserContext } from "../../store";
import { redirect, useNavigate } from "react-router-dom";
import { Header as AntHeader, Content } from "antd/es/layout/layout";
import Link from "antd/es/typography/Link";
import Header from "../../components/header/Header";

import styles from "./ForgotPassword.module.css";
import LandingCopy from "../../components/LandingCopy/LandingCopy";
import ForgotPasswordForm from "./(components)/ForgotPasswordForm/ForgotPasswordForm";
import { useMediaQuery } from "react-responsive";

export default function ForgotPassword() {
  const { signIn } = useAuthUserContext();
  const navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <div className={styles.main}>
      <Header signUp />

      <Content className={styles.content}>
        <div className={styles.contentWrap}>
          {isDesktopOrLaptop && (
            <img src='celi_demo.gif' alt='demo image' style={{
              height: "80%",
              aspectRatio: "0.79 / 1"
            }} />
          )}
          <div className={styles.formContainer}>
            <ForgotPasswordForm />
          </div>
        </div>
      </Content>
    </div>
  );
}
