import React, { useEffect } from "react";

import styles from "./ListItemUser.module.css";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import { useMediaQuery } from "react-responsive";
import { Storage } from "aws-amplify";
import { ConnectionStatus } from '../../models';

export default function ListItemUser({ user, mutualConnections = 0, connectionStatus = null, mode = null }) {
  const { firstName, lastName, username } = user;
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

  const [image, setImage] = React.useState(null);

  useEffect(() => {
    if(user.avatarUrl) {
      Storage.get(user.avatarUrl).then((image) => setImage(image));
    }
  }, [user.avatarUrl]);

  return (
    <div
      className={styles.user}
      onClick={() => navigate(`/@/${user.username}`)}
    >
      {image ? <Avatar
        size="large"
        src={image || "celi-logo-transp 2.png"}
      /> : <div className={styles.avatar} style={{ backgroundColor: '#BCBAED' }}>
        {firstName?.substr(0, 1)?.toUpperCase()}{lastName?.substr(0, 1)?.toUpperCase()}
      </div>
      }
      <div className={styles.text}>
        {
          isTabletOrMobile ?
            <>
              <div className={styles.name}>
                {(firstName || "") + " " + (lastName || "")}
                {((connectionStatus === null || connectionStatus !== ConnectionStatus.ACTIVE) && mode === null) ? <div className={styles.mutualColumn}>
                  <div className={styles.mutual}>
                    {mutualConnections} mutual
                    {(mutualConnections > 1 || mutualConnections === 0) && "s"}
                  </div>
                </div> : <div className={styles.username} style={{
                  fontSize: '13px',
                  marginTop: '0'
                }}>@{username}</div>}
              </div>

            </> :
            <>
              <div className={styles.name}>
                {(firstName || "") + " " + (lastName || "")}
                {((connectionStatus === null || connectionStatus !== ConnectionStatus.ACTIVE) && mode === null) && <div className={styles.mutualColumn}>
                  <div className={styles.mutual}>
                    {mutualConnections} mutual
                    {(mutualConnections > 1 || mutualConnections === 0) && "s"}
                  </div>
                </div>}
              </div>
              <div className={styles.username}>@{username}</div>
            </>
        }

      </div>

    </div>
  );
}
