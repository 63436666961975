import { Avatar } from "antd";
import React, { useEffect } from "react";
import { useCurrentUserContext } from "../../../store";

import styles from "./HeaderAvatar.module.css";

export default function HeaderAvatar() {
  const { currentUserImage, currentUser: { avatarUrl, firstName, lastName } } = useCurrentUserContext();

  return (
    <>{
      avatarUrl ? <Avatar
        size="large"
        src={currentUserImage || ""}
        alt={"profile picture"}
      /> : <div className={styles.avatar} style={{ backgroundColor: '#BCBAED' }}>
        {firstName?.substr(0, 1)?.toUpperCase()}{lastName?.substr(0, 1)?.toUpperCase()}
      </div>
    }
    </>
  );
}
